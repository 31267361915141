import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { cookieComplianceReducer } from '@uncinc/react-cookie-compliance';
import debug from './Debug';
import user from './user';
import project from './project';
import ui from './UI';
import managing from './managing';
import survey from './survey';
import errors from './errors';
import messages from './messages';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  debug,
  user,
  project,
  ui,
  managing,
  errors,
  messages,
  survey,
  cookieCompliance: cookieComplianceReducer,
});

export default rootReducer;
