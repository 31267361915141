import React from 'react';
import c from 'classnames';
import { useField } from 'formik';

import './style.scss';

const FieldEmail = ({
  className,
  name,
  label,
  placeholder,
  description,
}) => {
  const [field, meta] = useField(name);

  const classNames = c('FieldEmail', className);

  return (
    <label className={classNames} htmlFor={name}>
      {label && (
        <div className="FieldEmail__label">{label}</div>
      )}
      <input
        id={name}
        name={name}
        type="email"
        placeholder={placeholder}
        {...field}
      />
      {description && (
        <p className="FieldEmail__description">{description}</p>
      )}
      {meta.touched && meta.error && (
        <p className="FieldEmail__error">{meta.error}</p>
      )}
    </label>
  );
};

export default FieldEmail;
