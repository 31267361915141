import Immutable, { fromJS, List } from 'immutable';

import {
  PROJECT_IMAGE_DELETED,
  ROUND_IMAGE_DELETED,
  QUESTION_IMAGE_DELETED,
  MANAGING_ACCOUNTS_LOADED,
  MANAGING_ACCOUNT_LOADED,
  MANAGING_ACCOUNT_ADDED,
  MANAGING_CLIENTS_LOADED,
  MANAGING_CLIENT_LOADED,
  MANAGING_PROJECTS_LOADED,
  MANAGING_PROJECT_LOADED,
  MANAGING_STATISTICS_LOADED,
  MANAGING_STIMULI_ACTIVE,
  MANAGING_ROUND_ACTIVE,
  MANAGING_PROJECT_ANSWERS_LOADED,
  MANAGING_PROJECT_CLUSTER_LOADED,
  MANAGING_PROJECT_QUESTIONS_LOADED,
  MANAGING_PROJECT_ROUNDS_LOADED,
  PROJECT_LABELS_LOADED,
  MANAGING_ANSWER_UPDATED,
  MANAGING_CLUSTER_UPDATED,
  MANAGING_ROLE_ADDED,
  MANAGING_ROLES_LOADED,
  MANAGING_ROLE_REMOVED,
  MANAGING_CLEAR_ACCOUNT,
  MANAGING_PROJECT_EXPORT_LOADED,
  CLEAR_PROJECT_EXPORT,
  MANAGING_CLEAR_ROUND_STIMULI,
  MANAGING_PROJECT_SEARCH_FILTERS_LOADED,
  MANAGING_PROJECT_SEARCH_LOADED,
  MANAGING_CLEAR_CURRENT_CLUSTER,
} from '../../actions/managing';


const initialState = Immutable.fromJS({
  accounts: [],
  account: {},
  clients: [],
  client: {},
  projects: [],
  project: {},
  labels: List(),
  roles: List(),
  export: null,
});

export default function managing(state = initialState, action = {}) {
  switch (action.type) {
    case MANAGING_CLEAR_ROUND_STIMULI: {
      const { round } = action.data;

      return state.setIn(['project', 'rounds', round, 'stimuli'], Immutable.List());
    }

    case MANAGING_CLEAR_CURRENT_CLUSTER: {
      const { round, stimuli } = action.data;
      return state.setIn(['project', 'rounds', round, 'stimuli', stimuli, 'currentCluster'], null);
    }

    case PROJECT_IMAGE_DELETED: {
      return state.setIn(['project', 'media'], []);
    }

    case ROUND_IMAGE_DELETED: {
      const round = action.data;

      return state.setIn(['project', 'rounds', round, 'media'], []);
    }

    case QUESTION_IMAGE_DELETED: {
      const { round } = action.data;
      const stimuli = action.data.question;

      return state.setIn(['project', 'rounds', round, 'stimuli', stimuli, 'media'], []);
    }

    case MANAGING_ACCOUNTS_LOADED: {
      return state.setIn(['accounts'], action.data);
    }

    case MANAGING_ACCOUNT_LOADED: {
      return state.setIn(['account'], action.data);
    }

    case MANAGING_ACCOUNT_ADDED: {
      return state.setIn(['account'], action.data);
    }

    case MANAGING_CLIENTS_LOADED: {
      return state.setIn(['clients'], action.data);
    }

    case MANAGING_CLIENT_LOADED: {
      return state.setIn(['client'], action.data);
    }

    case MANAGING_PROJECTS_LOADED: {
      return state.setIn(['projects'], action.data);
    }

    case MANAGING_PROJECT_LOADED: {
      return state.setIn(['project'], action.data);
    }

    case MANAGING_STIMULI_ACTIVE: {
      return state.setIn(['stimuliIndexActive'], action.data);
    }

    case MANAGING_ROUND_ACTIVE: {
      return state.setIn(['roundIndexActive'], action.data);
    }

    case MANAGING_PROJECT_SEARCH_FILTERS_LOADED: {
      return state.setIn(['project', 'filters'], action.data.get('filters', fromJS({labels: new Immutable.List(), flagged: new Immutable.List()})));
    }
    
    case MANAGING_PROJECT_SEARCH_LOADED: {
      const round = state.getIn(['roundIndexActive']);
      const stimuli = state.getIn(['stimuliIndexActive']);
      const answers = fromJS(action.data.get('results').get('data'));

      return state.setIn(['project', 'rounds', round, 'stimuli', stimuli, 'answers'], answers);
    }
    case MANAGING_PROJECT_ANSWERS_LOADED: {
      const round = state.getIn(['roundIndexActive']);
      const stimuli = state.getIn(['stimuliIndexActive']);
      const answers = fromJS(action.data).sortBy((v) => v.get('id'), (a, b) => Math.sign(a - b));

      return state.setIn(['project', 'rounds', round, 'stimuli', stimuli, 'answers'], answers);
    }

    case MANAGING_PROJECT_CLUSTER_LOADED: {
      const round = state.getIn(['roundIndexActive']);
      const stimuli = state.getIn(['stimuliIndexActive']);

      return state.setIn(['project', 'rounds', round, 'stimuli', stimuli, 'currentCluster'], action.data);
    }

    case MANAGING_PROJECT_QUESTIONS_LOADED: {
      const round = state.getIn(['roundIndexActive']);

      return state.setIn(['project', 'rounds', round, 'stimuli'], action.data);
    }

    case MANAGING_PROJECT_ROUNDS_LOADED: {
      return state.setIn(['project', 'rounds'], action.data);
    }

    case PROJECT_LABELS_LOADED: {
      return state.setIn(['project', 'labels'], Immutable.fromJS(action.data));
    }

    case MANAGING_STATISTICS_LOADED: {
      return state.setIn(['project', 'statistics'], Immutable.fromJS(action.data));
    }

    case MANAGING_ANSWER_UPDATED: {
      const round = state.getIn(['roundIndexActive']);
      const stimuli = state.getIn(['stimuliIndexActive']);

      const answers = state.getIn(['project', 'rounds', round, 'stimuli', stimuli, 'answers'], Immutable.List());
      const answerIndex = answers.findIndex((answer) => answer.get('id') === action.data.get('id'));

      return state.setIn(['project', 'rounds', round, 'stimuli', stimuli, 'answers', answerIndex], action.data);
    }

    case MANAGING_CLUSTER_UPDATED: {
      const round = state.getIn(['roundIndexActive']);
      const stimuli = state.getIn(['stimuliIndexActive']);

      const answers = state.getIn(['project', 'rounds', round, 'stimuli', stimuli, 'answers'], Immutable.List());
      const answerIndex = answers.findIndex((answer) => answer.get('cluster_id') === action.data.get('id'));

      return state
        .setIn(['project', 'rounds', round, 'stimuli', stimuli, 'answers', answerIndex, 'answer'], action.data.getIn(['answers', 0, 'answer']))
        .setIn(['project', 'rounds', round, 'stimuli', stimuli, 'answers', answerIndex, 'cluster', 'label_id'], action.data.get('label_id'));
    }

    case MANAGING_ROLE_ADDED: {
      return state;
    }

    case MANAGING_ROLE_REMOVED: {
      return state;
    }

    case MANAGING_ROLES_LOADED: {
      return state.setIn(['roles'], action.data);
    }


    case MANAGING_CLEAR_ACCOUNT: {
      return state.setIn(['account'], {});
    }

    case MANAGING_PROJECT_EXPORT_LOADED: {
      return state.set('export', action.data);
    }

    case CLEAR_PROJECT_EXPORT: {
      return state.set('export', null);
    }

    default:
      return state;
  }
}
