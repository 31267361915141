import React from 'react';

import './style.scss';

const ErrorMessage = (props) => {

  const {
    message,
  } = props;

  return (
    <div className="ErrorMessage">
      <div className="ErrorMessage__arrow" />
      <div className="icon icon-cross icon-variant-4 ErrorMessage__cross" />
      {message}
    </div>
  );
};

export default ErrorMessage;
