import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Immutable from 'immutable';
import { Button } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import SelectStyled from 'react-select';

import {
  updateRound,
  updateQuestion,
  addRoundToProject,
} from '../../../../../actions/managing';

import {
  ErrorMessage,
  FieldEditableList,
  MarkdownEditor,
} from '../../../../../components';

import { SURVEY_QUESTION_TYPES, TEXT_INPUT_MAX_LENGTH } from '../../../../../config/constants';
import { setGlobalMessage } from '../../../../../actions/messages';

import './style.scss';

const SurveyFlowEdit = ({ round, setFirstRoundActive, redirectToPreviewRound, deleteProjectRound }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const id = round.get('id');
  const projectSlug = useSelector((state) => state.getIn(['managing', 'project', 'slug']), null);
  const description = round.get('description');
  const roundTypeId = round.getIn(['round_type', 'id']);
  const stimuli = round.getIn(['stimuli', 0], Immutable.List());

  const stimuliId = stimuli.get('id', null);
  const stimuliQuestion = stimuli.get('question', '');

  const stimuliType = stimuli.get('type', SURVEY_QUESTION_TYPES.OPEN);
  const stimuliOptions = stimuli.get('options', Immutable.List());

  const selectableQuestionTypes = [
    {
      label: t('admin.project-overview.round_edit.survey.question_types.open'),
      value: SURVEY_QUESTION_TYPES.OPEN,
    },
    {
      label: t('admin.project-overview.round_edit.survey.question_types.open_numeric'),
      value: SURVEY_QUESTION_TYPES.NUMERIC,
    },
    {
      label: t('admin.project-overview.round_edit.survey.question_types.single_choice'),
      value: SURVEY_QUESTION_TYPES.SINGLE_CHOICE,
    },
  ];

  const defaultSelectedType = selectableQuestionTypes.find((item) => item.value === stimuliType);

  const successCallback = () => {
    dispatch(setGlobalMessage('Survey Flow Block is succesvol opgeslagen'));
  };

  const buildRoundBodyAndCreate = (roundValues) => {
    const body = {
      question: roundValues.question,
      title: roundValues.question,
      description: roundValues.description,
      round_type_id: roundValues.round_type_id,
      question_type_id: roundValues.question_type_id,
      question_options: [],
    };

    if (roundValues.question_type_id === SURVEY_QUESTION_TYPES.SINGLE_CHOICE) {
      body.question_options = [...roundValues.question_options];
    }

    dispatch(addRoundToProject(projectSlug, body, () => {
      setFirstRoundActive();
      successCallback();
    }));
  };

  const buildRoundBodyAndUpdate = (roundValues) => {
    const body = {
      question: roundValues.question,
      title: roundValues.question,
      description: roundValues.description,
      round_type_id: roundValues.round_type_id,
    };

    dispatch(updateRound(body, projectSlug, id, successCallback));
  };

  const buildStimulusBodyAndUpdate = (roundValues) => {
    const body = {
      type: roundValues.question_type_id,
      options: [],
    };

    if (roundValues.question_type_id === SURVEY_QUESTION_TYPES.SINGLE_CHOICE) {
      body.options = [...roundValues.question_options];
    }

    dispatch(updateQuestion(body, projectSlug, id, stimuliId, successCallback));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      round_type_id: roundTypeId,
      question: stimuliQuestion,
      question_type_id: stimuliType,
      question_options: stimuliOptions,
      description: description,
    },
    validationSchema: Yup.object().shape({
      question: Yup
        .string()
        .required(t('admin.project-overview.round_edit.title_required')),
    }),

    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (roundValues) => {
      if (id) {
        // Update round and stimulus
        buildRoundBodyAndUpdate(roundValues);
        buildStimulusBodyAndUpdate(roundValues);
      } else {
        // Create round
        buildRoundBodyAndCreate(roundValues);
      }
    },
  });

  return (
    <div className="AdminFlowEdit AdminSurveyFlowEdit">
      <div className="AdminFlowEdit__header">
        <h4>{t('admin.project-overview.round_edit.survey.title')}</h4>
      </div>

      {/* Form - Start */}
      <form onSubmit={formik.handleSubmit}>

        {/* Field - question */}
        <label className="input-block" htmlFor="question">
          <div className="label">{t('admin.project-overview.round_edit.question')}</div>
          <p className="small">{t('admin.project-overview.round_edit.survey_description')}</p>
          <input
            id="question"
            name="question"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.question}
            maxLength={TEXT_INPUT_MAX_LENGTH}
          />
        </label>
        {formik.errors.question ? (
          <ErrorMessage message={formik.errors.question} />
        ) : null}

        {/* Field - Stimuli */}
        <label className="input-block" htmlFor="description">
          <div className="label">{t('admin.project-overview.round_edit.round_description')}</div>
          <p className="small">{t('admin.project-overview.round_edit.survey_description_description')}</p>
          <MarkdownEditor
            value={formik.values.description}
            id="description_markdown"
            name="description"
            onChange={(data) => formik.setFieldValue('description', data)}
          />
        </label>
        <label className="input-block" htmlFor="question_type_id">
          <div className="row">
            <div className="col-12">
              <label className="label-with-description">{t('admin.project-overview.round_edit.survey.question_type')}</label>
            </div>
          </div>
          <p className="small">{t('admin.project-overview.round_edit.survey.question_type_description')}</p>
          <SelectStyled
            id="question_type_id"
            key={`question_type_${id}`}
            inputId="question_type_id"
            className="SelectInput__select"
            classNamePrefix="SelectInput__inner"
            isSearchable={false}
            defaultValue={defaultSelectedType}
            onChange={(e) => {
              formik.setFieldValue('question_type_id', e.value);
            }}
            options={selectableQuestionTypes}
            placeholder="Selecteer een vraagtype"
          />

        </label>
        {formik.errors.question_type_id ? (
          <ErrorMessage message={formik.errors.question_type_id} />
        ) : null}

        {formik && formik.values.question_type_id.toString() === SURVEY_QUESTION_TYPES.SINGLE_CHOICE.toString() && (
          <FieldEditableList
            id="question_options"
            key={`question_options${id}`}
            fieldName="question_options"
            onChange={(value) => formik.setFieldValue('question_options', value)}
            value={formik.values.question_options}
          />
        )}

        <div className="AdminFlowEdit__buttons">
          <div onClick={deleteProjectRound}>
            {t('admin.project-overview.round_edit.delete_question')}
          </div>
          {id && (
            <Button className="Button--inverted" onClick={redirectToPreviewRound}>
              {t('admin.project-overview.round_edit.preview')}
              <i className="icon icon-eye-open" />
            </Button>
          )}
          <Button type="submit" className="Button Button--primary ProjectOverviewRoundEdit__Button">
            {t('project.overview.form.save')}
            <i className="icon icon-save icon-variant-1" />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SurveyFlowEdit;
