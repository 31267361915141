import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';

import { TRACK_EVENT, COOKIE_CONSENT_GRANTED } from '../actions/UI';

/**
 * Track an event with GTM. Default GTM setup will forward the event to GA.
 * @param {object} action - Event attributes.
 * @return {object} GTM event that gets passed to GA.
 */
const genericTrackEvent = (action) => {
  return {
    event: 'Custom event',
    category: action.category,
    action: action.action,
    label: action.label,
    value: action.value,
  };
};

/**
 * Load additional tags from GTM after consent.
 * @return {object} GTM event that triggers loading of additional tags.
 */
const cookieConsentGranted = () => {
  return {
    event: 'Cookie consent granted',
  };
};

// Map events to a Redux actions
const eventsMap = {
  [COOKIE_CONSENT_GRANTED]: cookieConsentGranted,
  [TRACK_EVENT]: genericTrackEvent,
};

export default createMiddleware(eventsMap, GoogleTagManager());
