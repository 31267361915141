import Immutable from 'immutable';

import {
  SURVEY_LOADED,
} from '../../actions/survey';


const initialState = Immutable.fromJS({
  survey: {},
});

export default function survey(state = initialState, action = {}) {
  switch (action.type) {
    case SURVEY_LOADED: {
      return state.setIn(['survey'], action.data);
    }

    default:
      return state;
  }
}
