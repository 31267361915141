import React from 'react';
import {
  useDispatch,
} from 'react-redux';
import Immutable from 'immutable';

import {
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';


import {
  hideModal as hideModalAction,
} from '../../actions/UI';

import './style.scss';

const Modal = (props) => {
  const {
    popup,
    closeable,
    title,
    description,
    content,
    contentData,
    onSubmit,
    onSubmitText,
    closeOnSubmit,
    className,
    dark,
    showBackButton,
    onBack,
  } = props;

  const dispatch = useDispatch();
  const hideModal = () => dispatch(hideModalAction());
  const submitModal = (data) => {
    if (closeOnSubmit !== false) {
      hideModal();
    }
    if (onSubmit) onSubmit(data);
  };
  // Combine render prop data
  let contentRenderData = Immutable.fromJS({ onSubmit: submitModal });
  const contentDataInner = Immutable.isMap(contentData) ? contentData : Immutable.fromJS(contentData);
  contentRenderData = contentData ? ({ contentData: contentDataInner, contentRenderData }) : contentRenderData;

  return (
    <div className={`Modal ${!popup ? 'type-full' : ''} ${className}`}>
      {popup && (
        <div className="Modal__overlay" onClick={hideModal} />
      )}
      <div className="Modal__outer">
        <div className="Modal__wrapper">
          {showBackButton && (
            <>
              {(!popup && dark !== false) && (
                <div onClick={onBack} className="Modal__back">
                  <div className="icon icon-arrow-right icon-variant-1" alt="Cross" />
                </div>
              )}
              {(popup || dark === false) && (
                <div onClick={onBack} className="Modal__back">
                  <div className="icon icon-arrow-right" alt="Cross" />
                </div>
              )}
            </>
          )}
          {closeable !== false && !showBackButton && (
            <>
              {(!popup && dark !== false) && (<div onClick={hideModal} className="icon icon-cross icon-variant-1 Modal__cross" alt="Cross" />)}
              {(popup || dark === false) && (<div onClick={hideModal} className="icon icon-cross Modal__cross" alt="Cross" />)}
            </>
          )}
          {title && (
            <h2 className="Modal__title">{title}</h2>
          )}
          {description && (
            <div className="Modal__description">{description}</div>
          )}
          {content(contentRenderData)}
          {onSubmitText && (
            <Button type="submit" onClick={submitModal} className="Button Button--primary Button--block">{onSubmitText}</Button>
          )}
        </div>
      </div>
    </div>
  );

};

export default Modal;
