import { ANSWER_STATUS } from '../../config/constants';
import { apiRequest } from '../api';

export const SET_LAST_ACCESS_CODE = 'SET_LAST_ACCESS_CODE';
export function setLastAccessCode(data) {
  return {
    type: SET_LAST_ACCESS_CODE,
    data,
  };
}

export const CLEAR_LAST_ACCESS_CODE = 'CLEAR_LAST_ACCESS_CODE';
export function clearLastAccessCode() {
  return {
    type: CLEAR_LAST_ACCESS_CODE,
  };
}

export const PROJECT_LOADED = 'PROJECT_LOADED';
export function projectLoaded(data) {
  return {
    type: PROJECT_LOADED,
    data,
  };
}

export const PUBLIC_PROJECT_LOADED = 'PUBLIC_PROJECT_LOADED';
export function publicProjectLoaded(data) {
  return {
    type: PUBLIC_PROJECT_LOADED,
    data,
  };
}

export const ANSWERS_LOADED = 'ANSWERS_LOADED';
export function answersLoaded(data) {
  return {
    type: ANSWERS_LOADED,
    data,
  };
}

export const ANSWERS_MERGED = 'ANSWERS_MERGED';
export function answersMerged(data) {
  return {
    type: ANSWERS_MERGED,
    data,
  };
}

export const REMOVE_ANSWER = 'REMOVE_ANSWER';
export function removeAnswer(data) {
  return {
    type: REMOVE_ANSWER,
    data,
  };
}

export const ROUND_MARKED_COMPLETE = 'ROUND_MARKED_COMPLETE';
export function roundMarkedComplete(data) {
  return {
    type: ROUND_MARKED_COMPLETE,
    data,
  };
}

export const CURRENT_ROUND = 'CURRENT_ROUND';
export function setCurrentRound(data) {
  return {
    type: CURRENT_ROUND,
    data,
  };
}

export const SET_PROJECT_FLOW_PROGRESS = 'SET_PROJECT_FLOW_PROGRESS';
export function setProjectFlowProgress(data) {
  return {
    type: SET_PROJECT_FLOW_PROGRESS,
    data,
  };
}

export const CURRENT_ROUND_INDEX = 'CURRENT_ROUND_INDEX';
export function setCurrentRoundIndex(data) {
  return {
    type: CURRENT_ROUND_INDEX,
    data: Number(data),
  };
}

export const SET_CURRENT_ROUND_STIMULUS_ANSWERED =
  'SET_CURRENT_ROUND_STIMULUS_ANSWERED';
export function setCurrentRoundStimulusAnswered() {
  return {
    type: SET_CURRENT_ROUND_STIMULUS_ANSWERED,
  };
}

export const SET_ANSWER_PROCESSED = 'SET_ANSWER_PROCESSED';
export function setAnswerProcessed(data) {
  return {
    type: SET_ANSWER_PROCESSED,
    data,
  };
}

export const SET_LOCAL_ROUND_COMPLETE = 'SET_LOCAL_ROUND_COMPLETE';
export function setLocalRoundComplete(data) {
  return {
    type: SET_LOCAL_ROUND_COMPLETE,
    data,
  };
}

export const STIMULI_ACTIVE = 'STIMULI_ACTIVE';
export function stimuliIndexActive(data) {
  return {
    type: STIMULI_ACTIVE,
    data,
  };
}

export const ANSWER_POSTED = 'ANSWER_POSTED';
export function answerPosted(data) {
  return {
    type: ANSWER_POSTED,
    data,
  };
}

export const ANSWER_UNCLUSTERED = 'ANSWER_UNCLUSTERED';
export function answersUnclustered(data) {
  return {
    type: ANSWER_UNCLUSTERED,
    data,
  };
}

export const ACCESS_CODE_CHECK_SUCCESS = 'ACCESS_CODE_CHECK_SUCCESS';
export function accessCodeCheckSuccess(data) {
  return {
    type: ACCESS_CODE_CHECK_SUCCESS,
    data,
  };
}

export const ACCESS_CODE_RESPONSE_SUCCESS = 'ACCESS_CODE_RESPONSE_SUCCESS';
export function accessCodeResponseSuccess(data) {
  return {
    type: ACCESS_CODE_RESPONSE_SUCCESS,
    data,
  };
}

export const PROJECT_REQUEST_FAILED = 'PROJECT_REQUEST_FAILED';
export function projectRequestFailed(data) {
  return {
    type: PROJECT_REQUEST_FAILED,
    errors: data && data.get ? data.get('error') : data,
  };
}

export const CLEAN_PROJECT_ACCESS_VALIDATION_STATUS =
  'CLEAN_PROJECT_ACCESS_VALIDATION_STATUS';
export function cleanProjectAccessValidationStatus() {
  return {
    type: CLEAN_PROJECT_ACCESS_VALIDATION_STATUS,
  };
}

export const getProject = (projectSlug, successCallback) => apiRequest(
  'GET',
  `/projects/${projectSlug}`,
  null,
  projectLoaded,
  projectRequestFailed,
  null,
  successCallback,
);

export const getPublicProject = (projectSlug) => apiRequest(
  'GET',
  `/projects/${projectSlug}/public`,
  null,
  publicProjectLoaded,
);

export const getProjectByCode = (accessCode) => apiRequest(
  'GET',
  `/projects/find-by-access-code/${accessCode}`,
  null,
  projectLoaded,
  projectRequestFailed,
);

export const checkAccessCode = (projectSlug, accessCode, successFn) => apiRequest(
  'POST',
  `/projects/${projectSlug}/check-access-code`,
  { access_code: accessCode },
  accessCodeResponseSuccess,
  projectRequestFailed,
  null,
  successFn,
);

export const postAccessCode = (projectSlug, accessCode, successFn) => apiRequest(
  'POST',
  `/projects/${projectSlug}/enter-access-code`,
  { access_code: accessCode },
  accessCodeResponseSuccess,
  projectRequestFailed,
  null,
  successFn,
);

export const getAnswers = (projectSlug, round, stimuliId) => apiRequest(
  'GET',
  `/projects/${projectSlug}/rounds/${round}/stimuli/${stimuliId}/answers`,
  null,
  answersLoaded,
);

export const postAnswers = (
  projectSlug,
  round,
  stimuliId,
  data,
  successCallback,
) => apiRequest(
  'POST',
  `/projects/${projectSlug}/rounds/${round}/stimuli/${stimuliId}/answers`,
  data,
  answerPosted,
  null,
  null,
  successCallback,
);

export const postProcessAnswer = (
  projectSlug,
  roundId,
  stimilusId,
  answerId,
  successCallback,
) => apiRequest(
  'POST',
  `/projects/${projectSlug}/rounds/${roundId}/stimuli/${stimilusId}/answers/process`,
  { answer_id: answerId },
  (data) => setAnswerProcessed(data.get(0).get('id')),
  null,
  null,
  successCallback,
);

export const postAnswerFlagged = (
  projectSlug,
  roundId,
  stimulusId,
  answerId,
  successCallback,
) => apiRequest(
  'POST',
  `/projects/${projectSlug}/rounds/${roundId}/stimuli/${stimulusId}/answers/${answerId}/flags`,
  null,
  null,
  projectRequestFailed,
  null,
  successCallback,
);

export const postMarkRoundComplete = (projectSlug, roundId, successCallback) => apiRequest(
  'POST',
  `/projects/${projectSlug}/rounds/${roundId}/mark-completed`,
  null,
  roundMarkedComplete,
  null,
  null,
  successCallback,
);

export const mergeClusters = (
  projectSlug,
  round,
  sourceId,
  destinationId,
  successCallback,
) => apiRequest(
  'POST',
  `/projects/${projectSlug}/rounds/${round}/clusters/merge/${destinationId}/${sourceId}`,
  null,
  answersMerged,
  null,
  null,
  successCallback,
);

export const unclusterAnswer = (projectSlug, round, clusterId, answerId, successCallback) => apiRequest(
  'POST',
  `/projects/${projectSlug}/rounds/${round}/clusters/${clusterId}/uncluster/${answerId}`,
  null,
  answersUnclustered,
  projectRequestFailed,
  null,
  successCallback,
);

export const moveAnswerToTop = (
  projectSlug,
  round,
  clusterId,
  answerId,
  successCallback,
) => apiRequest(
  'POST',
  `/projects/${projectSlug}/rounds/${round}/clusters/${clusterId}/move-to-top/${answerId}`,
  null,
  null,
  projectRequestFailed,
  null,
  successCallback,
);

export const updateAnswerStatusStacked = (
  projectSlug,
  roundId,
  stimulusId,
  answerId,
  successCallback,
) => apiRequest(
  'POST',
  `/projects/${projectSlug}/rounds/${roundId}/stimuli/${stimulusId}/answers/updateStatus`,
  { answer_id: answerId, status: ANSWER_STATUS.STACKED },
  null,
  null,
  null,
  successCallback,
);

export const updateAnswerStatusVerified = (
  projectSlug,
  roundId,
  stimulusId,
  answerId,
  successCallback,
) => apiRequest(
  'POST',
  `/projects/${projectSlug}/rounds/${roundId}/stimuli/${stimulusId}/answers/updateStatus`,
  { answer_id: answerId, status: ANSWER_STATUS.VERIFIED },
  null,
  null,
  null,
  successCallback,
);
