import React, { useState } from 'react';
import c from 'classnames';
import { confirm } from 'react-confirm-box';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'immutable';

import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import { Confirm, CardStack, GenericScreen } from '../../../../../../../components';
import { mergeClusters, updateAnswerStatusVerified } from '../../../../../../../actions/project';
import SeeIntoClusterModal from '../SeeIntoClusterModal';

import './style.scss';

const ProjectFlowRoundTypeQuestionV3StackingPerform = ({ ownAnswerCount, currentAnswerIndex, currentAnswer, stackingSuccessCallback }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Global state
  const projectName = useSelector((state) => state.getIn(['project', 'name'], null));
  const projectSlug = useSelector((state) => state.getIn(['project', 'slug'], null));
  const userId = useSelector((state) => state.get('user_id', null));
  const strangersAnswers = useSelector((state) => state.getIn(['project', 'currentRound', 'stimuli', 0, 'answers', 'answers'], List()));
  const currentRoundId = useSelector((state) => state.getIn(['project', 'currentRound', 'id'], null));
  const stimulusId = useSelector((state) => state.getIn(['project', 'currentRound', 'stimuli', 0, 'id'], null));

  const [displayedStackModal, setDisplayedStackModal] = useState(null);

  const classNames = c('ProjectFlowRoundStackingPerform', {
    'page-has-sticky-button': displayedStackModal,
    'page-blue-dark': displayedStackModal,
    'page-blue': !displayedStackModal,
  });

  /**
   * When a cluster is successfully merged, set the answer to processed, if the end is reached, fire the stacking complete method
   */
  const onClusterMergeSuccess = () => {
    stackingSuccessCallback();
    setDisplayedStackModal();
  };

  /**
   * When a clustering button is clicked, perform the POST merge request to merge the stacks together
   * @param {String} targetClusterId
   */
  const onClusterButtonClicked = async (targetClusterId) => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={t('project.stacking.confirm.header')}
            confirm={t('project.stacking.confirm.submit')}
            cancel={t('project.stacking.confirm.cancel')}
          />
        );
      },
    };

    const result = await confirm('', options);

    if (!result) {
      return;
    }

    dispatch(mergeClusters(projectSlug, currentRoundId, currentAnswer.get('cluster_id'), targetClusterId, onClusterMergeSuccess));
  };

  /**
   * When the unique button is clicked, process the answer
   */
  const onUniqueButtonClicked = async () => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={t('project.stacking.unique_confirm.header')}
            confirm={t('project.stacking.unique_confirm.submit')}
            cancel={t('project.stacking.unique_confirm.cancel')}
          />
        );
      },
    };

    const result = await confirm('', options);

    if (!result) {
      return;
    }

    dispatch(updateAnswerStatusVerified(projectSlug, currentRoundId, stimulusId, currentAnswer.get('id'), () => {
      stackingSuccessCallback();
      window.scrollTo(0, 0);
    }));
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={classNames}>
      <GenericScreen
        title={projectName}
        closeButton={!!displayedStackModal}
        onClose={() => setDisplayedStackModal(null)}
      >
        {displayedStackModal ?
          <SeeIntoClusterModal
            contentData={displayedStackModal}
            userId={userId}
            footerButtonClickCallback={onClusterButtonClicked}
          /> : (
            <>
              <div className="ProjectFlowRoundStackingPerform__content">
                <h2 className="ProjectFlowRoundStackingPerform__title">{t('project.stacking.title')}</h2>
                <p className="ProjectFlowRoundStackingPerform__body">{t('project.stacking.description')}</p>
                <div className="ProjectFlowRoundStackingPerform__ownAnswers">
                  <h5>{t('project.stacking.stack_your_answer', { currentNumber: currentAnswerIndex + 1, totalNumber: ownAnswerCount })}</h5>
                  {currentAnswer && (
                    <CardStack
                      title={currentAnswer.get('answer')}
                      amount={1}
                      participantType={currentAnswer.get('user_type')}
                      ownCard
                    />
                  )}
                  <div className="ProjectFlowRoundStackingPerform__uniqueButton">
                    <Button className="Button Button--small Button--blue" onClick={onUniqueButtonClicked}>
                      <span>{t('project.stacking.unique')}</span>
                      <div className="icon icon-star" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="ProjectFlowRoundStackingPerform__content">
                <h5>{t('project.stacking.cards_of_other_people')}</h5>
                <div className="ProjectFlowRoundStackingPerform__cards__wrapper">
                  {strangersAnswers && strangersAnswers.size > 0 && strangersAnswers.map((answer, index) => {
                    const title = answer.get('answer');
                    const participantType = answer.get('user_type', null);
                    const amount = answer.getIn(['cluster', 'ordered_answers'], List()).size;
                    return (
                      <CardStack
                        key={index}
                        clusterId={answer.get('cluster_id')}
                        title={title}
                        amount={amount}
                        participantType={participantType}
                        clusterButtonClickedCallback={onClusterButtonClicked}
                        onClick={() => setDisplayedStackModal(answer)}
                      />
                    );
                  })}
                </div>
                <div className="ProjectFlowRoundStackingPerform__uniqueBlock">
                  <p className="small">{t('project.stacking.no_match')}</p>
                  <Button className="Button Button--small Button--blue" onClick={onUniqueButtonClicked}>
                    <span>{t('project.stacking.unique')}</span>
                    <div className="icon icon-star" />
                  </Button>
                </div>
                <Button className="Button Button--small Button--primary Button__to-top" onClick={scrollToTop}>
                  {t('project.stacking.scroll-to-top')}
                  <i className="icon icon-arrow-right icon-variant-1" />
                </Button>
              </div>
            </>
          )}
      </GenericScreen>
    </div>
  );
};

export default ProjectFlowRoundTypeQuestionV3StackingPerform;
