import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { List } from 'immutable';

import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import { useTranslation } from 'react-i18next';
import { ErrorMessage, HeaderAdmin } from '../../../components';
import {
  clearErrors,
} from '../../../actions/errors';

import {
  getAccount,
  updateAccount,
  addRole,
  removeRole,
  getRoles,
} from '../../../actions/managing';

import './style.scss';
import { ROUTE_ACCOUNT_OVERVIEW } from '../../../config/routes';
import { setGlobalMessage } from '../../../actions/messages';


const AccountOverviewEdit = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { match: { params: { accountId } }, history } = props;
  const [submitted, setSubmitted] = useState(false);

  const name = useSelector((state) => state.getIn(['managing', 'account', 'name'], ''));
  const email = useSelector((state) => state.getIn(['managing', 'account', 'email'], ''));
  const error = useSelector((state) => state.getIn(['errors', 'managing', 'errors', 'errors', 'name', 0]));
  const roles = useSelector((state) => state.getIn(['managing', 'roles'], List()));
  const activeUserRoles = useSelector((state) => state.getIn(['managing', 'account', 'roles'], List()));

  const newRolesRef = useRef(null);
  const oldRolesRef = useRef(null);

  const goBack = () => {
    history.push(ROUTE_ACCOUNT_OVERVIEW);
  };

  const successCallback = () => {
    dispatch(setGlobalMessage('Account bijgewerkt.'));
    goBack();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name,
      email: email,
      password: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup
        .string()
        .required(t('form.name.required')),
      email: Yup
        .string()
        .email(t('form.email.invalid'))
        .required(t('form.email.required')),
      password: Yup
        .string()
        .min(8, t('form.password.min')),
    }),

    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      setSubmitted(true);
      dispatch(clearErrors());
      dispatch(updateAccount(values, accountId, successCallback));
    },
  });

  const addRoleToAccount = () => {
    const id = parseInt(newRolesRef.current.value);
    dispatch(addRole(accountId, id));
    setTimeout(() => {
      dispatch(getAccount(accountId));
    }, 600);
  };


  const removeRoleFromAccount = () => {
    const id = parseInt(oldRolesRef.current.value);
    dispatch(removeRole(accountId, id));
    setTimeout(() => {
      dispatch(getAccount(accountId));
    }, 600);
  };

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getAccount(accountId));
  }, [dispatch, accountId]);

  useEffect(() => {
    if (submitted && name === formik.values.name) {
      setSubmitted(false);
    }
  }, [name, submitted, formik]);

  return (
    <div className="AccountOverviewEdit">
      <Container>
        <div className="AccountOverviewEdit__content">
          <HeaderAdmin
            onBack={goBack}
          />
          <h1 className="AccountOverviewEdit__title">
            {t('admin.account-edit.title')}
          </h1>
          {error && (
            <div className="alert alert-danger">
              {error}
            </div>
          )}
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="name">
              <div className="AccountOverviewEdit__label">{t('form.name.label')}</div>
              <input
                id="name"
                name="name"
                type="text"
                placeholder={t('form.name.placeholder')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </label>
            {formik.errors.name ? (
              <ErrorMessage message={formik.errors.name} />
            ) : null}
            <label htmlFor="email">
              <div className="AccountOverviewEdit__label">{t('form.email.label')}</div>
              <input
                id="email"
                name="email"
                type="email"
                placeholder={t('form.email.placeholder')}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </label>
            {formik.errors.email ? (
              <ErrorMessage message={formik.errors.email} />
            ) : null}
            <label htmlFor="password">
              <div className="AccountOverviewEdit__label">
                {t('form.password.label')}
              </div>
              <input
                id="password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
            </label>
            {formik.errors.password ? (
              <ErrorMessage message={formik.errors.password} />
            ) : null}

            <div className="mt-4 AccountOverviewEdit__content__roles">
              <div className="row">
                <div className="col-md-6 col-12">
                  <h5>
                    {t('admin.account-edit.add_role')}
                  </h5>
                  <select name="roles" id="roles" ref={newRolesRef}>
                    {roles.map((role) => {
                      const notExists = activeUserRoles.filter((e) => e.get('id') === role.get('id')).size === 0;
                      return notExists && (
                        <option key={role.get('name')} value={role.get('id')}>
                          {role.get('name')}
                        </option>
                      );
                    })};
                  </select>
                  <Button onClick={addRoleToAccount} className="Button Button--small Button--light">Add role</Button>
                </div>
                <div className="col-md-6 col-12">
                  <h5>
                    {t('admin.account-edit.remove_role')}
                  </h5>
                  <select name="old_roles" id="old_roles" ref={oldRolesRef}>
                    {activeUserRoles.map((role) => {
                      const roleName = role.get('name');

                      return (
                        <option key={roleName} value={role.get('id')}>
                          {roleName}
                        </option>
                      );
                    })};
                  </select>
                  <Button onClick={removeRoleFromAccount} className="Button Button--small Button--light">
                    {t('admin.account-edit.remove_role')}
                  </Button>
                </div>
              </div>
            </div>
            <Button type="submit" className="Button Button--primary Button--block">
              {t('admin.account-edit.submit')}
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default AccountOverviewEdit;
