import Cookies from 'js-cookie';
import { apiRequest } from '../api';


const setAccessToken = (token) => {
  Cookies.set('token', token, { expires: 365, path: '/' });
};
export function clearAccessToken() {
  Cookies.remove('token');
}

export const convertToken = (temporaryToken) => (
  apiRequest('POST', '/v1/user/get-access-token', null, setAccessToken, null, temporaryToken)
);

export const USER_PROFILE_LOADED = 'USER_PROFILE_LOADED';
export function userProfileLoaded(data) {
  setAccessToken(data.get('token'));
  return {
    type: USER_PROFILE_LOADED,
    data,
  };
}

export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
export function updateUserProfileState(data) {
  return {
    type: USER_PROFILE_UPDATED,
    data,
  };
}
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export function userResetPasswordSent(data) {
  return {
    type: USER_RESET_PASSWORD,
    data,
  };
}

export const USER_PROFILE_DELETED = 'USER_PROFILE_DELETED';
export function userProfileDeleted(data) {
  clearAccessToken();
  return {
    type: USER_PROFILE_DELETED,
  };
}

export const SET_ACCESS_TOKEN_EXPIRED = 'SET_ACCESS_TOKEN_EXPIRED';
export function setAccessTokenExpired() {
  return {
    type: SET_ACCESS_TOKEN_EXPIRED,
  };
}

export const SET_ACCESS_TOKEN_VALID = 'SET_ACCESS_TOKEN_VALID';
export function setAccessTokenValid() {
  return {
    type: SET_ACCESS_TOKEN_VALID,
  };
}

export const USER_REQUEST_FAILED = 'USER_REQUEST_FAILED';
export function userRequestFailed(data) {
  return {
    type: USER_REQUEST_FAILED,
    errors: data,
  };
}

export const SET_PASSWORDLESS_LINK_SENT = 'SET_PASSWORDLESS_LINK_SENT';
export function setPasswordlessLinkSent(data) {
  return {
    type: SET_PASSWORDLESS_LINK_SENT,
    value: data,
  };
}

export const SET_PASSWORD_RESET_LINK_SENT = 'SET_PASSWORD_RESET_LINK_SENT';
export function setPasswordResetLinkSent(data) {
  return {
    type: SET_PASSWORD_RESET_LINK_SENT,
    value: data,
  };
}

export const SET_PASSWORD_RESET_SUCCESS = 'SET_PASSWORD_RESET_SUCCESS';
export function setPasswordResetSuccess(data) {
  return {
    type: SET_PASSWORD_RESET_SUCCESS,
    value: data,
  };
}

export const updateUserProfile = (data) => (
  apiRequest('POST', '/user/update', data, updateUserProfileState, userRequestFailed)
);

export const getUserProfile = () => (
  apiRequest('GET', '/user/details', null, userProfileLoaded, userRequestFailed)
);

export const deleteUserProfile = () => (
  apiRequest('POST', '/user/delete', null, userProfileDeleted, userRequestFailed)
);

export const userLogin = (email, password) => (
  apiRequest('POST', '/user/login', { email, password }, userProfileLoaded, userRequestFailed)
);

export const userPasswordlessLogin = (email, project = null, accessCode = null) => (
  apiRequest('POST', '/user/passwordless', {
    email,
    project,
    access_code: accessCode,
  }, setPasswordlessLinkSent, userRequestFailed)
);

export const userPasswordlessVerify = (token, search) => (
  apiRequest('GET', `/user/passwordless/${token}${search}`, null, userProfileLoaded, userRequestFailed)
);

export const userForgotPassword = (email) => (
  apiRequest('POST', '/user/forgot-password', { email }, setPasswordResetLinkSent, userRequestFailed)
);

export const userResetPassword = (email, token, password, passwordConfirmation) => (
  apiRequest('POST', '/user/reset-password', {
    email,
    token,
    password,
    password_confirmation: passwordConfirmation,
  }, userResetPasswordSent, userRequestFailed)
);

export const userCreate = (name, email, password) => (
  apiRequest('POST', '/user/register', {
    name,
    email,
    password,
  }, userProfileLoaded, userRequestFailed)
);

export const userAnonymousLogin = () => (
  apiRequest('POST', '/user/anonymous-login', null, userProfileLoaded, userRequestFailed)
);
