import React from 'react';
import c from 'classnames';
import { Button } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';

import './style.scss';

const CardStack = ({
  title,
  answerId,
  clusterId,
  participantType,
  amount,
  onClick,
  ownCard,
  flaggedByUser,
  isDragging,
  isDraggedOver,
  clusterButtonClickedCallback,
  placeTopClickedCallback,
  flagClickedCallback,
  isCorrectButtonCallback,
  displayCluster = false,
}) => {
  const { t } = useTranslation();

  const CardClasses = c('CardStack', {
    'CardStack--single': amount === 1,
    'CardStack--multiple': amount > 1,
    'CardStack--ownCard': ownCard,
    'CardStack--isDragging': isDragging,
    'CardStack--isDraggedOver': isDraggedOver,
    'CardStack--displayCluster': displayCluster,
  });

  const onFlagButtonClicked = (event) => {
    event.stopPropagation();
    event.currentTarget.blur();
    if (flagClickedCallback) {
      flagClickedCallback(answerId);
    }
  };

  const onPlaceTopButtonClicked = (event) => {
    event.stopPropagation();
    event.currentTarget.blur();
    if (placeTopClickedCallback) {
      placeTopClickedCallback(answerId);
    }
  };

  const onClusterButtonClicked = (event) => {
    event.stopPropagation();
    if (clusterButtonClickedCallback) {
      clusterButtonClickedCallback(clusterId);
    }
  };

  const cardClicked = () => {
    if (amount > 1) {
      onClick();
    }
  };

  return (
    <div className={CardClasses} onClick={cardClicked}>
      <div className="CardStack__inner">
        <div className="CardStack__title">{title}</div>{
          participantType && (
            <div className="pb-1 ReportingCardStack__user">
              <div className="icon icon-account icon-variant-1" />
              {participantType}
            </div>
          )
        }

        {(ownCard || clusterButtonClickedCallback || amount > 1 || placeTopClickedCallback || isCorrectButtonCallback) &&
          <div className="CardStack__details">
            {flagClickedCallback && !ownCard && (
              <div className="CardStack__details__flag_container">
                <Button
                  className="Button Button--flagged"
                  disabled={flaggedByUser}
                  onClick={onFlagButtonClicked}>
                  <div className={`icon icon-flag icon-variant-${flaggedByUser ? '0' : '1'}`} />
                </Button>
                <div className="tooltip">
                  {t('project.detail.flag_tooltip')}
                </div>
              </div>
            )}

            {ownCard && (
              <h5 className="CardStack__your_card">{t('project.stacking.my-answer')}</h5>
            )}

            {placeTopClickedCallback && (
              <Button className="Button Button--small Button--blue" onClick={onPlaceTopButtonClicked}>
                <span>{t('project.stacking.to-top')}</span>
                <div className="icon icon-to-top" />
              </Button>
            )}

            {amount > 1 && (
              <Button className="Button Button--small Button--black Button__stack">
                <span className="Button__label">{t('project.stacking.display_cluster')}</span>
                <div className="icon icon-layers icon-variant-1" />
                {amount}
              </Button>
            )}

            {!ownCard && clusterButtonClickedCallback && (
              <Button className="Button Button--small Button--blue" onClick={onClusterButtonClicked}>
                <span>{t('project.stacking.add_card_here')}</span>
                <div className="icon icon-plus" />
              </Button>
            )}

            {isCorrectButtonCallback && (
              <Button className="Button Button--small Button--blue" onClick={isCorrectButtonCallback}>
                <span>{t('project.stacking.correct')}</span>
                <div className="icon icon-check" />
              </Button>
            )}
          </div>}
      </div>
      {amount > 1 && (
        <div className="CardStack__stack" />
      )}
    </div>
  );
};

export default CardStack;
