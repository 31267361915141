import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';
import { userPasswordlessVerify } from '../../../actions/user';
import { buildUrl } from '../../../helpers';
import { ROUTE_HOME, ROUTE_PROJECT_ENTRY } from '../../../config/routes';

import './style.scss';
import { accessCodeResponseSuccess } from '../../../actions/project';

const AccountVerify = (props) => {
  const { t } = useTranslation();
  const { match: { params: { token } }, location: { search }, history } = props;

  const dispatch = useDispatch();

  const userToken = useSelector((state) => state.getIn(['user', 'token'], false));
  const errors = useSelector((state) => state.getIn(['errors', 'user', 'errors', 'error']));

  useEffect(() => {
    dispatch(userPasswordlessVerify(token, search));
  }, [dispatch, token, search]);

  const redirectUrl = useCallback(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('project_slug')) {
      history.push(buildUrl(ROUTE_PROJECT_ENTRY, [searchParams.get('project_slug')]));
    } else {
      history.push(ROUTE_HOME);
    }
  }, [search, history]);

  // Redirect after successful login
  useMemo(() => {
    if (userToken !== false) {
      dispatch(accessCodeResponseSuccess());
      redirectUrl();
    }
  }, [dispatch, redirectUrl, userToken]);

  return (
    <div className="Verify">
      <Container>
        <div className="Verify__content">
          {errors && (
            <>
              <h1 className="Verify__title">
                {t('form.account_verify.header1')}<br />
                {t('form.account_verify.header2')}
              </h1>
              <p className="Verify__body">
                {t('form.account_verify.body')}
              </p>
              <Button className="Button--blue inline mr-2" onClick={redirectUrl}>
                {t('form.account_verify.try_again')}
              </Button>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default AccountVerify;
