import React from 'react';
import { Button } from '@uncinc/uncinc-react-kitchen-sink';
import './style.scss';

interface StickyFooterProps {
  buttonText: string;
  buttonType?: string;
  buttonClick: () => void;
  buttonIcon?: React.ReactNode;
  disabled: boolean;
}

const StickyFooter: React.FC<StickyFooterProps> = ({
  buttonText, buttonClick, buttonType = 'button', buttonIcon, disabled = false,
}) => (
  <div className="StickyFooter">
    <Button htmlType={buttonType} onClick={buttonClick} disabled={disabled}>
      {buttonText}
      {buttonIcon}
    </Button>
  </div>
);

export default StickyFooter;
