import React, { useRef } from 'react';
import c from 'classnames';
import { confirm } from 'react-confirm-box';
import { useTranslation } from 'react-i18next';

import { Button } from '@uncinc/uncinc-react-kitchen-sink';

import Confirm from '../../Confirm';

import './style.scss';

const FieldFileUpload = ({
  name,
  label,
  types,
  fileName,
  preview,
  deleteConfirmMessage,
  className,
  onSuccess,
  onDelete,
}) => {
  const { t } = useTranslation();
  const classNames = c('FieldFileUpload', className);

  const ref = useRef();

  const handleUpload = async () => {
    const file = await ref.current.files[0];

    if (file.size > 3e6) {
      ref.current.value = '';
      const fileSize = Math.round(file.size / 10e3) / 100;
      // eslint-disable-next-line no-alert
      window.alert(t('form.file_upload.exceed_size', { fileSize }));
      return false;
    }

    const formData = new FormData();

    formData.append(`${file.name}`, file);
    const reader = new FileReader();
    // On load
    reader.onload = function(event) {
      const contents = event.target.result;
      const data = contents.split(',')[1];

      onSuccess(file.name, data);
    };

    reader.readAsDataURL(file);
  };

  const handleDelete = async () => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            description={deleteConfirmMessage || t('form.file_upload.confirm')}
            confirm={t('button.yes')}
          />
        );
      },
    };

    const answer = await confirm('', options);

    if (answer === true) {
      onDelete(fileName);
    }
  };

  return (
    <div className={classNames}>
      <label htmlFor={name}>
        <div className="FieldFileUpload__label">
          {label}
        </div>

        {fileName && (
          <div className="FieldFileUpload__preview">
            {preview || <span>{fileName}</span>}

            {onDelete && (
              <Button
                primary={false}
                aria-label={t('button.delete')}
                className="Button Button--small"
                onClick={handleDelete}
              >
                <div className="icon icon-trash" />
              </Button>
            )}
          </div>
        )}

        <input
          ref={ref}
          type="file"
          id={name}
          name={name}
          accept={types}
          onChange={handleUpload}
        />
      </label>
    </div>
  );
};

export default FieldFileUpload;
