import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Immutable from 'immutable';
import { Button, Container } from '@uncinc/uncinc-react-kitchen-sink';
import { saveAs } from 'file-saver';
import Truncate from 'react-truncate';
import { useTranslation } from 'react-i18next';
import {
  Link,
} from 'react-router-dom';

import {
  getProject,
  getAccounts,
  getRounds,
  getProjectExport,
  clearProjectExport,
} from '../../../actions/managing';
import {
  clearErrors,
} from '../../../actions/errors';
import { setGlobalMessage } from '../../../actions/messages';
import { buildUrl, createExportCSV, formatDate } from '../../../helpers';
import {
  ROUTE_ADMIN_PROJECT_DETAIL_SECTION,
  ROUTE_PROJECT_OVERVIEW,
} from '../../../config/routes';

import ProjectDetailGeneral from './ProjectDetailGeneral';
import ProjectDetailFlow from './ProjectDetailFlow';
import ProjectDetailUsers from './ProjectDetailUsers';

const GENERAL = 'GENERAL';
const FLOW = 'FLOW';
const USERS = 'USERS';

const SECTIONS = {
  GENERAL: ProjectDetailGeneral,
  FLOW: ProjectDetailFlow,
  USERS: ProjectDetailUsers,
};

import './style.scss';

const ProjectDetail = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { match: { params: { projectSlug, section } }, history } = props;
  const [sectionActive, setSectionActive] = useState(null);
  const activeSection = (section && Object.keys(SECTIONS).includes(section.toUpperCase())) ? section.toUpperCase() : GENERAL;

  const projectId = useSelector((state) => state.getIn(['managing', 'project', 'id']));
  const name = useSelector((state) => state.getIn(['managing', 'project', 'name']));
  const slug = useSelector((state) => state.getIn(['managing', 'project', 'slug']));
  const exportData = useSelector((state) => state.getIn(['managing', 'export'], null));
  const accessCode = useSelector((state) => state.getIn(['managing', 'project', 'access_code']));
  const submitErrors = useSelector((state) => state.getIn(['errors', 'managing', 'errors'], Immutable.List()));

  const SectionComponent = SECTIONS[activeSection];

  const redirectToSection = (targetSection) => {
    setSectionActive(targetSection);
    history.push(buildUrl(
      ROUTE_ADMIN_PROJECT_DETAIL_SECTION,
      [projectSlug, targetSection.toLowerCase()],
    ));
  };

  const shareAccessCode = () => {
    const url = window.location;
    navigator.clipboard.writeText(`${url.protocol}//${url.host}/projects/${projectSlug}?access_code=${accessCode}`);
    dispatch(setGlobalMessage(t('admin.project-detail.access_code_copied')));
  };

  const downloadExport = () => dispatch(getProjectExport(projectSlug));

  // Download the received export data as a CSV.
  useEffect(() => {
    if (exportData) {
      const data = createExportCSV(exportData);
      const fileData = new Blob([data], { type: 'text/csv;charset=utf-8' });
      const dateString = formatDate((new Date()), 'yyyy-MM-dd HH-mm');
      saveAs(fileData, `export_${dateString}_${name.replace(' ', '_')}.csv`);
      dispatch(clearProjectExport());
    }
  }, [exportData, createExportCSV, formatDate, name, saveAs, clearProjectExport, dispatch]);


  useEffect(() => {
    dispatch(getProject(projectSlug));
    dispatch(getRounds(projectSlug));
    dispatch(getAccounts());
    setSectionActive(activeSection);
  }, [dispatch, projectSlug]);

  useEffect(() => {
    if (submitErrors) {
      dispatch(setGlobalMessage(`${submitErrors.get('message', '')} : ${JSON.stringify(submitErrors.get('errors'))}`));
      dispatch(clearErrors());
    }

  }, [submitErrors]);

  return (
    <div className="AdminProjectDetail">
      <div className="AdminProjectDetail__hero">
        <Container>
          <div className="row">
            {/* Top bar */}
            <div className="col-12 col-lg-8">
              <Link to={ROUTE_PROJECT_OVERVIEW} className="Link__icon-with-text">
                <i className="icon icon-arrow-right icon-orientation-left" />{t('general.back_to_overview')}
              </Link>
              <div className="AdminProjectDetail__hero__title">
                <div className="AdminProjectDetail__hero__title_h2">
                  <h3>
                    <Truncate lines={1} ellipsis="...">
                      {name}
                    </Truncate>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="AdminProjectDetail__hero__actions">
                <div>
                  <Button type="button" onClick={shareAccessCode} className="Button Button--white">
                    {t('admin.project-detail.share_access_code')}
                    <div className="icon icon-link" />
                  </Button>
                  <Button type="button" onClick={downloadExport} className="Button Button--white">
                    {t('admin.project-detail.export_data')}
                    <div className="icon icon-download" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="AdminProjectDetail__tabs">
        <Container>
          <ul className="Tabs">
            <li className={`Tabs__item ${sectionActive === 'GENERAL' ? 'active' : ''}`} onClick={() => redirectToSection('GENERAL')}>
              {t('managing.sections.general')}
            </li>
            <li className={`Tabs__item ${sectionActive === 'FLOW' ? 'active' : ''}`} onClick={() => redirectToSection('FLOW')}>
              {t('managing.sections.flow')}
            </li>
            <li className={`Tabs__item ${sectionActive === 'USERS' ? 'active' : ''}`} onClick={() => redirectToSection('USERS')}>
              {t('managing.sections.facilitators')}
            </li>
          </ul>
        </Container>
      </div>
      {/* Content */}
      <div className="AdminProjectDetail__content">
        {slug && (
          <SectionComponent slug={slug} history={history} />
        )}
      </div>
    </div>
  );
};

export default ProjectDetail;
