import React from 'react';

import { Pagination } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';

import './style.scss';

const Pager = ({ currentPage, setCurrentPage, totalPages }) => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-12 col-md-6 offset-md-3">
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          previousLabel={<a className="Pagination__previous"><i className="icon icon-before icon-arrow-right icon-variant-2" /><span>{t('general.prev')}</span></a>}
          nextLabel={<a className="Pagination__next"><span>{t('general.next')}</span><i className="icon icon-after icon-arrow-right icon-variant-2" /></a>}
        />
      </div>
    </div>
  );
};
export default Pager;
