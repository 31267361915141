import React from 'react';
import { List } from 'immutable';
import { useTranslation } from 'react-i18next';
import { CardStack, StickyFooter } from '../../../../../../../components';

import './style.scss';

const SeeIntoClusterModal = ({ contentData, userId, footerButtonClickCallback }) => {
  const { t } = useTranslation();

  const clusterId = contentData.getIn(['cluster', 'id'], null);
  const answers = contentData.getIn(['cluster', 'answers'], List());

  const onFooterButtonClick = () => {
    footerButtonClickCallback(clusterId);
  };

  return (
    <div className="SeeIntoClusterModal">
      {answers.size > 0 && answers.map((item) => {
        const id = item.get('id');
        const title = item.get('answer');
        const answerUserId = item.get('user_id');
        return (
          <CardStack
            key={id}
            title={title}
            ownCard={userId === answerUserId}
            displayCluster
          />
        );
      })}
      <StickyFooter
        buttonText={t('project.stacking.my-card-belongs-here')}
        buttonIcon={<i className="icon icon-cross" />}
        buttonClick={onFooterButtonClick}
      />
    </div>
  );
};

export default SeeIntoClusterModal;
