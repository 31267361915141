import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Immutable from 'immutable';
import Masonry from 'react-masonry-css';
import { saveAs } from 'file-saver';
import {
  Button,
  Container,
} from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';
import { buildUrl, createExportCSV, formatDate } from '../../../helpers';

import { ALLOWED_SEARCH_FILTERS, INDEX_NOT_FOUND } from '../../../config/constants';

import {
  getProject,
  roundIndexActive,
  stimuliIndexActive,
  getProjectLabels,
  getProjectRoundExport,
  autoclusterAnswers,
  clearProjectExport,
  clearRoundStimuli,
  getStatisticsForRound,
  mergeMultipleClusters,
  searchAnswers, getSearchFilters,
} from '../../../actions/managing';

import { setGlobalMessage, setGlobalError } from '../../../actions/messages';

import {
  ReportingCardStack,
} from '../../../components';

import {
  ROUTE_PROJECT_RESULTS_CLUSTER,
  ROUTE_ADMIN_PROJECT_DETAIL_SECTION,
} from '../../../config/routes';

const MIN_MERGE_AMOUNT = 1;

import './style.scss';

const ProjectResult = (props) => {
  const { match: { params: { projectSlug, projectRound, stimuliId } }, location, history } = props;
  const [selectedCards, setSelectedCards] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [stimulusIndex, setStimulusIndex] = useState(null);
  const [roundIndex, setRoundIndex] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [filterFlagged, setFilterFlagged] = useState(null);

  const filters = useSelector((state) => state.getIn(['managing', 'project', 'filters'], null));
  const labelFilters = useSelector((state) => state.getIn(['managing', 'project', 'filters', 'labels'], null));
  const flaggedFilters = useSelector((state) => state.getIn(['managing', 'project', 'filters', 'flagged'], null));

  const rounds = useSelector((state) => state.getIn(['managing', 'project', 'rounds'], Immutable.List()));
  const statistics = useSelector((state) => state.getIn(['managing', 'project', 'statistics'], Immutable.Map()));

  const accessCode = useSelector((state) => state.getIn(['managing', 'project', 'access_code']));
  const slug = useSelector((state) => state.getIn(['managing', 'project', 'slug']));
  const stimuli = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli'], Immutable.List()));
  const stimulus = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli', stimulusIndex], Immutable.Map()));
  const answers = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli', stimulusIndex, 'answers'], Immutable.List()));
  const labels = useSelector((state) => state.getIn(['managing', 'project', 'labels'], Immutable.List()));
  const error = useSelector((state) => state.getIn(['errors', 'managing', 'errors'], null));
  const exportData = useSelector((state) => state.getIn(['managing', 'export'], null));

  /**
   * Build search query and perform search
   */
  const doSearch = () => {
    const filtersArray = activeFilters ? activeFilters.toArray() : [];
    const filtersToPass = filtersArray.map((filterInArray) => filterInArray.get('id'));

    const search = {
      query: '',
      filters: { labels: filtersToPass, flagged: filterFlagged },
      sort: { field: 'answers_count', option: 'desc' },
    };

    dispatch(searchAnswers(projectSlug, projectRound, search));
  };

  /**
  * Get the active filters locally and set them in the filter query so they can be reused on reload
  * @param {Immutable.List()} filters
  * @param {boolean} filterFlagged
  * @param {boolean} forceEmpty
  * @returns void
  */
  const setActiveFiltersInQueryParam = (filters = new Immutable.List(), filterFlagged = null, forceEmpty = false) => {
    if (filters && filters.size === 0 && !filterFlagged && forceEmpty) {
      history.replace({ pathname: location.pathname, search: '' });
      return;
    }

    const searchParamBody = {};
    if (filters && filters.size > 0) {
      let filterQuery = '';
      filters.map((filter) => {
        filterQuery += `${filter.get('id')},`;
      });

      searchParamBody[ALLOWED_SEARCH_FILTERS.LABELS] = filterQuery;
    }

    if (filterFlagged) {
      searchParamBody[ALLOWED_SEARCH_FILTERS.FLAGGED] = true;
    }

    const searchParams = new URLSearchParams(searchParamBody);
    history.replace({ pathname: location.pathname, search: searchParams.toString() });
  };

  const refresh = (filtersChanged = false) => {
    if (filtersChanged) {
      setActiveFilters(null);
      dispatch(getSearchFilters(projectSlug, projectRound));
      return;
    }
    dispatch(getStatisticsForRound(projectSlug, projectRound));
    doSearch();
  };

  const refreshCallback = (filtersChanged = false) => {
    refresh(filtersChanged);
  };

  const onClickStack = (e) => {
    dispatch(clearRoundStimuli(roundIndex));
    history.push(buildUrl(
      ROUTE_PROJECT_RESULTS_CLUSTER,
      [slug, projectRound, stimuli.getIn([0, 'id']), e.get('cluster_id')],
    ));
  };

  /**
  * Go through the url params for labels and flagged
  * Check if they all exist in the label data retrieved from the API
  * Rebuild the params based on that data so it cannot be tampered with
  * @param {Immutable.List} localLabelFilters - The array of locally saved filters
  * @returns
  */
  const cleanAndReturnFiltersFromURL = (localLabelFilters) => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamMap = new Map(searchParams);
    const labelURLFilters = searchParamMap.get(ALLOWED_SEARCH_FILTERS.LABELS);
    const flaggedURLFilter = searchParamMap.get(ALLOWED_SEARCH_FILTERS.FLAGGED);
    const flaggedValue = flaggedURLFilter === 'true' ? true : null;

    // If there are filters, split them from the parameter, check local filters and only apply those
    if (labelURLFilters) {
      const splitURLFilters = labelURLFilters.split(',');
      const newFilters = [];
      splitURLFilters.forEach((filterFromURL) => {
        if (filterFromURL.length > 0) {
          const foundFilterIndex = localLabelFilters.findIndex((filter) => filter.get('id').toString() === filterFromURL);
          if (foundFilterIndex > INDEX_NOT_FOUND) {
            newFilters.push(localLabelFilters.get(foundFilterIndex));
          }
        }
      });

      setActiveFiltersInQueryParam(new Immutable.List(newFilters), flaggedValue, true);

      return {
        labels: new Immutable.List(newFilters),
        flagged: flaggedValue,
      };
    } else if (flaggedValue !== true) {
      // If there are not label filters, and flaggedvalue is something other than true, set it as well
      setActiveFiltersInQueryParam(new Immutable.List(), null, true);
    }

    return {
      flagged: flaggedValue,
      labels: new Immutable.List(),
    };
  };

  const shareAccessCode = () => {
    const url = window.location;
    navigator.clipboard.writeText(`${url.protocol}//${url.host}/projects/${projectSlug}?access_code=${accessCode}`);
    dispatch(setGlobalMessage(t('admin.project-detail.access_code_copied')));
  };

  const downloadExport = () => dispatch(getProjectRoundExport(projectSlug, projectRound));

  const onSuccessAutocluster = async () => {
    refreshCallback();
  };

  const onAutocluster = () => {
    dispatch(autoclusterAnswers(
      projectSlug,
      projectRound,
      stimuliId,
      () => setGlobalMessage(t('managing.project-result.autocluster_success')),
      () => setGlobalMessage(t('managing.project-result.autocluster_error')),
      onSuccessAutocluster,
    ));
  };

  const handleCardSelect = (cardData, isSelected) => {
    if (isSelected) {
      setSelectedCards([...selectedCards, cardData]);
    } else {
      setSelectedCards(selectedCards.filter((card) => card.get('id') !== cardData.get('id')));
    }
  };

  const onMergeSelected = () => {
    if (selectedCards.length > MIN_MERGE_AMOUNT) {
      const idsObject = {
        ids: selectedCards.map((map) => map.get('cluster_id')),
      };

      dispatch(mergeMultipleClusters(projectSlug, projectRound, idsObject, () => {
        refreshCallback();
        setSelectedCards([]);
      },
      ));
    }
  };

  const getFilterClass = (filterId) => {
    const activeClass = filterId && activeFilters && activeFilters.findIndex((filter) => filter.get('id') === filterId) > INDEX_NOT_FOUND ? 'active' : '';
    return `ProjectResult--body--filters__list__item ${activeClass}`;
  };

  /**
   * On label click update the active filters based on which item was clicked
   * @param {Number} index - The clicked filter inder
   */
  const onLabelFilterClick = (index) => {
    const filter = filters.getIn(['labels', index], null);
    if (filter) {
      const filterIndex = activeFilters.findIndex((findFilter) => findFilter.get('id') === filter.get('id'));
      const isActive = filterIndex > INDEX_NOT_FOUND;
      if (isActive) {
        const newActiveFilters = Immutable.List((activeFilters.splice(filterIndex, 1)));
        setActiveFilters(newActiveFilters);
        setActiveFiltersInQueryParam(newActiveFilters, filterFlagged, true);
      } else {
        const newActiveFilters = Immutable.List([...activeFilters, filter]);
        setActiveFilters(newActiveFilters);
        setActiveFiltersInQueryParam(newActiveFilters, filterFlagged, true);
      }
    }
  };

  /**
   * On flagged item click toggle the filter flag
   */
  const onFlaggedFilterClick = () => {
    const newValue = filterFlagged ? null : true;
    setFilterFlagged(newValue);
    setActiveFiltersInQueryParam(activeFilters, newValue, true);
  };

  const resetFilters = () => {
    const newActiveFilters = new Immutable.List();
    setFilterFlagged(null);
    setActiveFilters(newActiveFilters);
    setActiveFiltersInQueryParam(newActiveFilters, null, true);
  };

  const onAllFilterClicked = () => {
    if (activeFilters && activeFilters.size === 0 && filterFlagged === null) {
      return;
    }

    resetFilters();
  };

  /**
  * When the label filters have changed, check if there are any in the URL that are not valid, clean the URL and update the filters
  */
  useEffect(() => {
    if (labelFilters && labelFilters.size > 0 && flaggedFilters && flaggedFilters.size > 0) {
      const cleanedFilters = cleanAndReturnFiltersFromURL(labelFilters);
      if (cleanedFilters.flagged) {
        setFilterFlagged(cleanedFilters.flagged);
      }

      setActiveFilters(cleanedFilters.labels);
    } else if (labelFilters && labelFilters.size === 0) {
      cleanAndReturnFiltersFromURL(new Immutable.List());
    }
  }, [labelFilters, flaggedFilters]);

  /**
   * When the filters change, build the body for searching and perform a search
   */
  useEffect(() => {
    if (activeFilters || (!activeFilters && filters && filters.get('labels') && filters.get('labels').size === 0)) {
      doSearch();
    }
  }, [filters, activeFilters, filterFlagged]);

  useEffect(() => {
    if (error) {
      const errorMessage = error.getIn(['error', 'code'], t('general.errors.something_went_wrong'));
      dispatch(setGlobalError(errorMessage));
    }
  }, [error, dispatch, t]);

  useEffect(() => {
    if (stimuli && stimuli.size) {
      const index = stimuli.findIndex ? stimuli.findIndex((obj) => { return obj.get('id') === parseInt(stimuliId); }) : 0;
      setStimulusIndex(index);
      dispatch(stimuliIndexActive(index));
    }
  }, [stimuli]);

  useEffect(() => {
    if (rounds && rounds.size) {
      const index = rounds.findIndex ? rounds.findIndex((obj) => { return obj.get('id') === parseInt(projectRound); }) : 0;
      setRoundIndex(index);
      dispatch(roundIndexActive(index));
    }
  }, [rounds]);


  useEffect(() => {
    if (projectSlug &&
      projectRound &&
      stimuliId
    ) {
      dispatch(getProject(projectSlug, () => {
        dispatch(getSearchFilters(projectSlug, projectRound));
        dispatch(getProjectLabels(projectSlug));
        dispatch(getStatisticsForRound(projectSlug, projectRound));
      }));
    }
  }, [projectSlug, projectRound, stimuliId, dispatch]);

  // Download the received export data as a CSV.
  useEffect(() => {
    if (exportData) {
      const stimuliQuestion = stimulus.get('question');
      const name = stimuliQuestion.replace(' ', '_');
      const data = createExportCSV(exportData);
      const fileData = new Blob([data], { type: 'text/csv;charset=utf-8' });
      const dateString = formatDate((new Date()), 'yyyy-MM-dd HH-mm');
      saveAs(fileData, `export_${dateString}_${name}.csv`);
      dispatch(clearProjectExport());
    }
  }, [exportData, createExportCSV, formatDate, stimulus, saveAs, clearProjectExport, dispatch]);

  // If flagged parameter is passed, add flagged filter
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamMap = new Map(searchParams);
    const flaggedURLFilter = searchParamMap.get(ALLOWED_SEARCH_FILTERS.FLAGGED);
    const flaggedValue = flaggedURLFilter === 'true' ? true : null;

    if (flaggedValue) {
      setFilterFlagged(flaggedValue);
    }
  }, [location.search]);

  return (
    <div className="ProjectResult page-green">
      <div className="ProjectResult__heroBackground">
        <Container>
          <Link to={buildUrl(ROUTE_ADMIN_PROJECT_DETAIL_SECTION, [projectSlug, 'flow'])} className="Link__icon-with-text">
            <i className="icon icon-arrow-right icon-orientation-left" />{t('managing.project-result.back_to_overview')}
          </Link>
          <div className="row">
            <div className="col-lg-7">
              <h2 className="ProjectResult__title">
                {stimulus && stimulus.get('question')}
              </h2>
            </div>
            <div className="col-lg-5">
              <div className="ProjectResult__heroButtons">
                <Button type="button" onClick={shareAccessCode} className="Button Button--white">
                  {t('admin.project-detail.share_access_code')}
                  <div className="icon icon-link" />
                </Button>
                <Button type="button" onClick={downloadExport} className="Button Button--primary">
                  {t('admin.project-detail.export_data')}
                  <div className="icon icon-download icon-variant-1" />
                </Button>
              </div>
            </div>
          </div>
          <div className="ProjectResult__graph">
            <div className="ProjectResult__graph__block">
              <h4>{statistics.get('answers_count', 0)}</h4>
              <label>
                {t('managing.project-dashboard.contributions')}
              </label>
            </div>
            <div className="ProjectResult__graph__block">
              <h4>{statistics.get('answers_users_count', 0)}</h4>
              <label>
                {t('managing.project-dashboard.participants')}
              </label>
            </div>
            <div className="ProjectResult__graph__block">
              <h4>{statistics.get('clusters_count', 0)}</h4>
              <label>
                {t('managing.project-dashboard.clusters')}
              </label>
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <div className="ProjectResult--body--content">
          <h3>{t('managing.project-result.label_results')}</h3>
          <div className="row ProjectResult--body--filters">
            <div className="col-md-8 col-12">
              <h5>{t('managing.project-result.filter')}</h5>
              {filters && filters.size > 0 && (
                <div className="ProjectResult--body--filters__list">
                  <span className={`ProjectResult--body--filters__list__item all ${activeFilters
                    && activeFilters.size === 0
                    && filterFlagged === null ? 'active' : ''}`}
                    onClick={(e) => onAllFilterClicked()}>
                    {t('managing.project-result.all')}
                  </span>
                  {filters.get('labels') && filters.get('labels').size > 0 && filters.get('labels').map((filter, index) => {
                    return (
                      <span key={`filter${index}`} className={getFilterClass(filter.get('id'))} onClick={(e) => onLabelFilterClick(index)}>
                        {filter.get('title')}
                      </span>
                    );
                  })}
                  <span className={`ProjectResult--body--filters__list__item ${filterFlagged ? 'active' : ''}`} onClick={(e) => onFlaggedFilterClick()}>
                    <i className="icon icon-flag" /> {t('managing.project-result.marked-answers')}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {answers.size === 0 && (
                <div className="ProjectResult--body--no-results">
                  <div>
                    <h4>{t('managing.project-result.no_results')}</h4>
                    <p>{t('managing.project-result.no_results_description')}</p>
                  </div>
                  <Button
                    className="Button Button--primary Button--small"
                    onClick={onAllFilterClicked}
                  >
                    {t('managing.project-result.no_results_button')}
                  </Button>
                </div>
              )}
              {answers.size > 0 &&
                <Masonry
                  breakpointCols={{ default: 3, 700: 2, 500: 1 }}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {/* Sort by ID tot   */}
                  {answers.map((answer, i) => {
                    const answerTitle = answer.get('answer', '');
                    const amount = answer.get('answers_count', 0);
                    const isCluster = amount > 1;

                    return (
                      <ReportingCardStack
                        key={answer.get('id')}
                        answer={answer}
                        title={answerTitle}
                        onClick={isCluster ? () => { onClickStack(answer); } : null}
                        activeLabelId={answer.getIn(['cluster', 'label_id'], null)}
                        labels={labels}
                        isCluster={isCluster}
                        projectSlug={projectSlug}
                        stimuliId={stimuliId}
                        projectRound={projectRound}
                        selectedCards={selectedCards}
                        onCardSelect={handleCardSelect}
                        deleteOption
                        refreshCallback={(labelsChanged) => refreshCallback(labelsChanged)}
                      />
                    );
                  })}
                </Masonry>}

            </div>
            <div className="ProjectResult--bottomBar">
              <Container className="Container__full">
                {selectedCards.length ?
                  <div className="ButtonBar">
                    <div className="ButtonBar__select">
                      {t('managing.project-result.selected', { count: selectedCards.length })}
                    </div>
                    <div className="ButtonBar__button" onClick={() => onMergeSelected()}>
                      <i className="icon icon-layers" />
                      {t('managing.project-result.mergeSelected')}
                    </div>
                    {/* TODO add label */}
                    {/* <div className="ButtonBar__button">
                      <i className="icon icon-label" />
                      {t('managing.project-result.addLabel')}
                    </div> */}
                    <div className="ButtonBar__close" onClick={() => setSelectedCards([])}>
                      <i className="icon icon-cross" />
                    </div>
                  </div>
                  :
                  <Button className="Button Button--white" onClick={onAutocluster}>
                    {t('managing.project-result.mergeButton')}
                    <div className="icon icon-layers" />
                  </Button>}
              </Container>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProjectResult;
