import React from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

const Alert = ({ type, title, body, className }) => {
  const classNames = c('Alert', `Alert--${type}`, className);

  return (
    <div className={classNames}>
      <div className="Alert__title">
        <h2 className="asH4">{title}</h2>
      </div>

      {body && (
        <div className="Alert__body">
          <p>{body}</p>
        </div>
      )}
    </div>
  );
};

Alert.defaultProps = {
  body: '',
  className: '',
};

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'error']).isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  className: PropTypes.string,
};

export default Alert;
