import React, { useState } from 'react';
import { fromJS } from 'immutable';
import { confirm } from 'react-confirm-box';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import {
  postAnswers,
  setCurrentRoundStimulusAnswered,
} from '../../../../../../actions/project';

import {
  DEFAULT_HEIGHT_CARD_INPUT,
} from '../../../../../../config/constants';

import {
  Confirm,
  Markdown,
  StickyFooter,
  GenericScreen,
} from '../../../../../../components';

import FieldTextArea from '../../../../../../components/Field/TextArea';

import './style.scss';

const ProjectFlowRoundTypeQuestionV3Stimulus = ({ stimulus, isPreview, onPreviewComplete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [cards, setCards] = useState(fromJS([{ value: '', height: DEFAULT_HEIGHT_CARD_INPUT }]));

  const question = stimulus ? stimulus.get('question') : '';
  const description = stimulus ? stimulus.get('description', null) : null;
  const mediaUrl = stimulus ? stimulus.getIn(['media', 'original_url']) : '';
  const roundId = stimulus ? stimulus.get('round_id') : '';
  const stimulusId = stimulus ? stimulus.get('id') : null;

  const projectName = useSelector((state) => state.getIn(['project', 'name'], null));
  const projectSlug = useSelector((state) => state.getIn(['project', 'slug']), '');

  // On a successfull post, increment to amount of saved answers
  const onPostAnswerSuccess = async () => {
    dispatch(setCurrentRoundStimulusAnswered());
  };

  const showErrorDialog = () => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={t('project.stimulus.error.header')}
            confirm={t('project.stimulus.error.button')}
          />
        );
      },
    };
    confirm('', options);
  };

  /**
   * On form submit, show a confirmation dialog and post the new answers to the API
   * @returns {void}
   */
  const submit = async () => {
    if (isPreview && onPreviewComplete) {
      onPreviewComplete();
      return;
    }

    let atLeastOneAnswerFilledIn = cards.findIndex((card) => card.get('value') && card.get('value') !== '') > -1;

    if (!atLeastOneAnswerFilledIn) {
      showErrorDialog();
      return;
    }

    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={t('project.stimulus.confirm.header')}
            description={t('project.stimulus.confirm.description')}
            confirm={t('project.stimulus.confirm.submit')}
            cancel={t('project.stimulus.back_to_answers')}
          />
        );
      },
    };

    const result = await confirm('', options);

    if (!result) {
      return;
    }

    const filledInAnswers = cards.filter((card) => card.get('value') && card.get('value') !== '');

    const data = filledInAnswers.map((card) => {
      return {
        answer: card.get('value', null),
        cluster_id: null,
        top_answer_id: null,
      };
    });
    dispatch(postAnswers(projectSlug, roundId, stimulusId, data, onPostAnswerSuccess));
  };

  /**
   * Add a new empty card to the list
   * @returns {void}
   */
  const onAddCardClicked = () => {
    const newCards = cards.set(cards.size, fromJS({ value: '', height: DEFAULT_HEIGHT_CARD_INPUT }));
    setCards(newCards);
  };

  /**
   * Add a new card after an answer has been given
   * @param {Number} index - index of the card
   * @param {String} value - value of the card
   * @param {Number} height - height of the card
   * @returns {void}
   */
  const addBodyToCard = (index, value, height) => {
    const newCards = cards
      .setIn([index, 'value'], value)
      .setIn([index, 'height'], height);

    setCards(newCards);
  };

  /**
   * Render a list of cards based on filled in answers
   * @returns {void}
   */
  const StimuliAnswerList = () => {
    return (
      cards.size > 0 && (
        cards.map((card, index) => {
          const name = `card_${index}`;
          const value = card.get('value');
          const height = card.get('height');

          return (
            <FieldTextArea
              key={name}
              index={index}
              fieldName={name}
              initialValue={value}
              placeholder={t('project.stimulus.placeholder_card')}
              height={height}
              onBlur={addBodyToCard}
            />
          );
        })
      )
    );
  };

  return (
    <div className="ProjectFlowRoundStimulus page-green page-has-sticky-button">
      <GenericScreen title={projectName} isPreview={isPreview}>
        <div className="ProjectFlowRoundStimulus__content">
          {description && (
            <>
              <h5 className="ProjectFlowRoundStimulus__subtitle">{t('project.stimulus.explanation')}</h5>
              <div className="ProjectFlowRoundStimulus__description">
                <Markdown>
                  {description}
                </Markdown>
              </div>
            </>
          )}
          <h3 className="ProjectFlowRoundStimulus__title">{question}</h3>
          {mediaUrl && <img src={mediaUrl} alt="foto" />}
          <h5 className="ProjectFlowRoundStimulus__yourcards">{t('project.stimulus.your_cards')}</h5>
          <StimuliAnswerList />
          <Button type="button" className="Button Button--green Button--small" onClick={onAddCardClicked}>
            {t('project.stimulus.add_card')}
            <div className="icon icon-variant-2 icon-plus" />
          </Button>
          <StickyFooter
            buttonText={t('project.stimulus.button-next')}
            buttonClick={submit}
            buttonIcon={<i className="icon icon-arrow-right icon-variant-1" />}
          />
        </div>
      </GenericScreen>
    </div>
  );
};

export default ProjectFlowRoundTypeQuestionV3Stimulus;
