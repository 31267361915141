import i18next from 'i18next';
import Immutable from 'immutable';
import Cookies from 'js-cookie';
import config from 'react-global-configuration';

import { API_REQUEST } from '../actions/api';
import { PROJECT_LOADED, PUBLIC_PROJECT_LOADED } from '../actions/project';
import { DEFAULT_LANGUAGE } from '../config/constants';

import {
  clearAccessToken,
  setAccessTokenExpired,
  setAccessTokenValid,
} from '../actions/user';

const handleApiRequest = (dispatch, action) => {
  const { method, path, successHandler, errorHandler, bearerToken, data, successCallback } = action;
  const basePath = config.get('API_URL');
  const url = `${basePath}${path}`;

  // If the action supplies a bearer token. Use that instead.
  const bearer = bearerToken || Cookies.get('token');

  fetch(url, {
    credentials: 'omit',
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearer}`,
    },
    body: data ? JSON.stringify(data) : null,
  }).then((response) => {
    if (response.ok) {
      response.json().then((result) => {
        dispatch(setAccessTokenValid());
        if (successHandler) {
          dispatch(successHandler(Immutable.fromJS(result)));
        }
        if (successCallback) {
          successCallback(result);
        }
      });
    } else {
      switch (response.status) {
        case 401:
          // User is not authenticated, redirect to login.
          console.log('User not logged in, redirecting to login.');
          clearAccessToken();
          dispatch(setAccessTokenExpired());
          break;
        case 403:
          // Project in draft mode
          window.location.href = '/forbidden';
          break;
        case 422:
        case 429:
        default:
          break;
      }
      response.json().then((result) => {
        if (errorHandler) {
          dispatch(errorHandler(Immutable.fromJS(result)));
        }
      });
    }
  }).catch(() => {
    console.error('Unable to complete request.');
  });
};

const updateProjectTranslations = (translations) => {
  if (translations.size > 0) {
    i18next.addResourceBundle(DEFAULT_LANGUAGE, 'project', translations.toJS(), true, true);
  } else {
    i18next.removeResourceBundle(DEFAULT_LANGUAGE, 'project');
  }
  i18next.reloadResources(DEFAULT_LANGUAGE);
};

const apiMiddleware = ({ dispatch }) => (next) => (action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case API_REQUEST: {
      handleApiRequest(dispatch, action);
      break;
    }

    case PROJECT_LOADED:
    case PUBLIC_PROJECT_LOADED: {
      const translations = action.data.get('translations', Immutable.Map());
      updateProjectTranslations(translations);

      break;
    }
  }

  return next(action);
};

export default apiMiddleware;

