export const SET_GLOBAL_MESSAGE = 'SET_GLOBAL_MESSAGE';
export function setGlobalMessage(data) {
  return {
    type: SET_GLOBAL_MESSAGE,
    message: data,
  };
}

export const CLEAR_GLOBAL_MESSAGE = 'CLEAR_GLOBAL_MESSAGE';
export function clearGlobalMessage() {
  return {
    type: CLEAR_GLOBAL_MESSAGE,
  };
}

export const SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR';
export function setGlobalError(data) {
  return {
    type: SET_GLOBAL_ERROR,
    message: data,
  };
}

export const CLEAR_GLOBAL_ERROR = 'CLEAR_GLOBAL_ERROR';
export function clearGlobalError() {
  return {
    type: CLEAR_GLOBAL_ERROR,
  };
}
