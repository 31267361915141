import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Immutable from 'immutable';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import { setGlobalMessage } from '../../../actions/messages';
import { TEXT_INPUT_MAX_LENGTH } from '../../../config/constants';
import { buildUrl } from '../../../helpers';
import { ROUTE_PROJECT_OVERVIEW_ROUND_EDIT } from '../../../config/routes';

import {
  getRounds,
  questionImageDeleted,
  updateQuestion,
  uploadImage,
  deleteImage,
} from '../../../actions/managing';

import {
  ErrorMessage,
  FieldFileUpload,
  MarkdownEditor,
  HeaderAdmin,
} from '../../../components';

import './style.scss';

const ProjectOverviewQuestionEdit = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { match: { params: { projectSlug, projectRound, stimuliId } }, history } = props;

  const rounds = useSelector((state) => state.getIn(['managing', 'project', 'rounds'], Immutable.List()));
  const roundIndex = rounds.findIndex ? rounds.findIndex((obj) => { return obj.get('id') === parseInt(projectRound); }) : 0;
  const questions = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli'], Immutable.List()));
  const questionIndex = questions.findIndex ? questions.findIndex((obj) => { return obj.get('id') === parseInt(stimuliId); }) : 0;
  const question = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli', questionIndex, 'question']), '');
  const description = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli', questionIndex, 'description']), '');
  const image = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli', questionIndex, 'media', 0], Immutable.List()));
  const message_before = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli', questionIndex, 'message_before']), '');
  const message_after = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli', questionIndex, 'message_after']), '');

  const redirectToRoundDetail = () => {
    history.push(buildUrl(
      ROUTE_PROJECT_OVERVIEW_ROUND_EDIT,
      [projectSlug, projectRound],
    ));
  };

  const successCallback = () => {
    dispatch(setGlobalMessage(t('admin.project-overview.question_edit.success')));
    redirectToRoundDetail();
  };

  const imageDeleteSuccessCallback = () => {
    dispatch(questionImageDeleted({ round: roundIndex, question: questionIndex }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      question: question,
      description: description,
      message_before: message_before,
      message_after: message_after,
      image_data: '',
      image_name: image.get('file_name', ''),
    },
    validationSchema: Yup.object().shape({
      question: Yup
        .string()
        .required(t('admin.project-overview.question_edit.question_required')),
    }),

    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      const projectValuesFiltered = Object
        .entries(values)
        .filter(([key, value]) => !key.startsWith('image'));

      const projectValues = Object.fromEntries(projectValuesFiltered);

      const uploadImageCallback = ({ id }) => {
        if (values.image_data) {
          dispatch(uploadImage({
            image: values.image_data,
            image_name: values.image_name,
            model: 'Stimulus',
            id,
          }, successCallback));
        }
      };

      dispatch(updateQuestion(projectValues, projectSlug, projectRound, stimuliId, uploadImageCallback));
    },
  });

  useEffect(() => {
    dispatch(getRounds(projectSlug));
  }, [dispatch, projectSlug]);

  return (
    <div className="ProjectOverviewQuestionEdit">
      <Container>
        <div className="ProjectOverviewQuestionEdit__content">
          <HeaderAdmin
            onBack={redirectToRoundDetail}
          />
          <h1 className="ProjectOverviewQuestionEdit__title">
            {t('admin.project-overview.question_edit.title')}
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="question">
              <div className="ProjectOverviewQuestionEdit__label">
                {t('admin.project-overview.question_edit.question')}
              </div>
              <input
                id="question"
                name="question"
                type="question"
                onChange={formik.handleChange}
                value={formik.values.question}
                maxLength={TEXT_INPUT_MAX_LENGTH}
              />
            </label>
            {formik.errors.question ? (
              <ErrorMessage message={formik.errors.question} />
            ) : null}
            <FieldFileUpload
              name="image-upload"
              label={t('screens.project-overview.form.image_upload')}
              types=".jpg,.jpeg,.png,capture=camera"
              fileName={formik.values.image_name}
              preview={<img src={image.get('original_url', '')} alt={image.get('file_name', '')} />}
              onSuccess={(fileName, fileData) => {
                formik.setFieldValue('image_data', fileData);
                formik.setFieldValue('image_name', fileName);
              }}
              onDelete={() => {
                formik.setFieldValue('image_data', '');
                formik.setFieldValue('image_name', '');

                // Only delete the image from the backend when we already have one in the state.
                if (image.size > 0) {
                  dispatch(deleteImage(image.get('id', null), imageDeleteSuccessCallback()));
                }
              }}
            />
            <label htmlFor="description">
              <div className="ProjectOverviewQuestionEdit__label">{t('admin.project-overview.question_edit.description')}</div>
              <MarkdownEditor
                value={formik.values.description}
                id="description_markdown"
                name="description"
                onChange={(data) => formik.setFieldValue('description', data)}
              />
            </label>

            <label htmlFor="message_before">
              <div className="ProjectOverviewQuestionEdit__label">{t('admin.project-overview.question_edit.message_before')}</div>
              <MarkdownEditor
                value={formik.values.message_before}
                id="message_before_markdown"
                name="message_before"
                onChange={(data) => formik.setFieldValue('message_before', data)}
              />
            </label>

            <label htmlFor="message_after">
              <div className="ProjectOverviewQuestionEdit__label">{t('admin.project-overview.question_edit.message_after')}</div>
              <MarkdownEditor
                value={formik.values.message_after}
                id="message_after_markdown"
                name="message_after"
                onChange={(data) => formik.setFieldValue('message_after', data)}
              />
            </label>

            <Button type="submit" className="Button Button--primary ProjectOverviewQuestionEdit__Button">
              {t('admin.project-overview.question_edit.save')}
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default ProjectOverviewQuestionEdit;
