import React from 'react';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

import './style.scss';

const MarkdownEditor = (props) => {

  return (
    <div className="MarkdownEditor">
      <SimpleMDE {...props} />
    </div>
  );

};
export default MarkdownEditor;
