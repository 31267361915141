import config from 'react-global-configuration';

export const APP_ENV = process.env.REACT_APP_ENV;
export const __DEBUG__ = APP_ENV !== 'production';
export const USE_SENTRY = process.env.SENTRY_ENABLED;
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const DEFAULT_LANGUAGE = 'nl';
export const SITE_NAME = 'Participatie Panel';
export const APP_ROOT_ELEMENT_ID = 'root';

export const IS_SERVER = !(
  typeof window !== 'undefined'
  && window.document
  && window.document.createElement
);
export const IS_CLIENT = !IS_SERVER;

let API_URL = "//api.panel.nl/api";

switch (window.document.location.host) {
  case 'localhost:3000':
    API_URL = "//panel.devinc.eu/api";
    break;
  case 'accept.tool.panel.nl':
    API_URL = "//accept.api.panel.nl/api";
    break;
  case 'staging.tool.panel.nl':
    API_URL = "//staging.api.panel.nl/api";
    break;
  case 'tool.panel.nl':
    API_URL = "//api.panel.nl/api";
    break;
  default:
    API_URL = "//api.panel.nl/api";
    break;
}

// let API_URL = 'http://localhost:3000/api'; // <- For local proxy.

// If we provide the API URL in the DOM, use that instead.
if (IS_CLIENT && document.getElementById(APP_ROOT_ELEMENT_ID).dataset.apiUrl) {
  API_URL = document.getElementById(APP_ROOT_ELEMENT_ID).dataset.apiUrl;
}

// The value is where the breakpoint starts (mobile-first).
// These values are taken from '/src/src/styles/assets/_variables.scss'.
export const SCREEN_MOBILE = 0;
export const SCREEN_TABLET = 768;
export const SCREEN_NORMAL = 992;
export const SCREEN_WIDE = 1240;
export const SCREEN_HUGE = 2000;

// Unknown index
export const INDEX_NOT_FOUND = -1;

// Use config to share static configuration between components and packages.
config.set({
  'SITE_NAME': SITE_NAME,
  'MULTILINGUAL': false,
  'DEFAULT_LANGUAGE': DEFAULT_LANGUAGE,
  'API_URL': API_URL,
});

// Participatie panel website.
export const PARTICIPATIE_PANEL_WEBSITE_URL = 'https://www.panel.nl';

export const DEFAULT_MAX_CHARACTERS_CARD = 600;

export const USER_ROLES = {
  ADMIN: 'admin',
  PROJECT_MANAGER: 'project-manager',
  FACILITATOR: 'facilitator'
};

// Card types.
export const CARD_TYPE_USER_ANSWER = 'user_answer';
export const CARD_TYPE_CLUSTER = 'cluster';

// Stack limits.
export const STACK_LIMIT_MIN = 2;
export const STACK_LIMIT_MAX = 24;

// Modal types.
export const MODAL_TYPE_INSTRUCTION = 'MODAL_TYPE_INSTRUCTION';
export const MODAL_TYPE_SUCCESS = 'MODAL_TYPE_SUCCESS';
export const MODAL_TYPE_COMBINE_CLUSTER = 'MODAL_TYPE_COMBINE_CLUSTER';
export const MODAL_TYPE_SEE_INTO_CLUSTER = 'MODAL_TYPE_SEE_INTO_CLUSTER';
export const MODAL_TYPE_BEFORE = 'MODAL_TYPE_BEFORE';
export const MODAL_TYPE_AFTER = 'MODAL_TYPE_AFTER';

// Default height for card inputs.
export const DEFAULT_HEIGHT_CARD_INPUT = 72;

// Project access code status.
export const PROJECT_ACCESS_CODE_STATUS_REQUEST = 'request';
export const PROJECT_ACCESS_CODE_STATUS_SUCCESS = 'success';
export const PROJECT_ACCESS_CODE_STATUS_ERROR = 'error';

// Max number of inputs for access code.
export const MAX_ACCESS_CODE_LENGTH = 6;

// Round status.
export const ROUND_STATUS_ACTIVE = 1;
export const ROUND_STATUS_INACTIVE = 2;

// Max length for input fields.
export const TEXT_INPUT_MAX_LENGTH = 255;

export const ROUND_TYPES = {
  INFORMATION: 'information',
  QUESTIONV3: 'question_v3',
  SURVEY: 'survey',
};

export const ROUND_TYPE_LABELS = {
  information: 'Informatie scherm',
  question_v3: 'Open vraag',
  survey: 'Survey vraag',
};

export const ANSWER_STATUS = {
  NEW: 1,
  STACKED: 2,
  VERIFIED: 3,
};

export const SURVEY_QUESTION_TYPES = {
  OPEN: 1,
  NUMERIC: 2,
  SINGLE_CHOICE: 3,
  MULTIPLE_CHOICE: 4,
};

export const ALLOWED_SEARCH_FILTERS = {
  LABELS: 'labels',
  FLAGGED: 'flagged',
};

export const ERROR_MESSAGES = {
  'answer_already_flagged_by_user': 'errors.answer_already_flagged_by_user',
};

export const FALLBACK_ERROR = 'errors.default';