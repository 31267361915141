import React from 'react';
import {
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import './style.scss';
import CardStack from '../CardStack';

const Confirm = ({
  onConfirm,
  onCancel,
  title,
  description,
  confirm,
  cancel,
  cardContent,
}) => {
  return (
    <div className="confirm-box__inner" onClick={(event) => { event.stopPropagation(); }}>
      {!cancel && onCancel &&
        <Button className="Button Button--icon" onClick={onCancel}>
          <div className="icon icon-cross" />
        </Button>}
      <h3>{title}</h3>
      <div className="confirm-box__description">{description}</div>
      {cardContent && (
        <CardStack
          title={cardContent.title}
          ownCard={cardContent.ownCard}
        />
      )}
      {confirm && onConfirm && (
        <Button onClick={onConfirm} className="Button Button--submit Button--primary Button--block">
          {confirm}
          <div className="icon icon-arrow-right icon-variant-1" />
        </Button>
      )}

      {cancel && onCancel && (
        <Button onClick={onCancel} className="p-0 mt-4 Button Button--submit Button--text-link Button--block">{cancel}</Button>
      )}
    </div>
  );

};

export default Confirm;
