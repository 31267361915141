import Immutable from 'immutable';

import {
  USER_REQUEST_FAILED,
} from '../../actions/user';

import {
  PROJECT_REQUEST_FAILED,
} from '../../actions/project';

import {
  MANAGING_REQUEST_FAILED,
} from '../../actions/managing';

import {
  CLEAR_ERRORS,
} from '../../actions/errors';

const initialState = Immutable.fromJS({
  user: {
    errors: null,
  },
  project: {
    errors: null,
  },
  managing: {
    errors: null,
  },
});

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case USER_REQUEST_FAILED: {
      return state.setIn(['user', 'errors'], Immutable.fromJS(action.errors));
    }

    case PROJECT_REQUEST_FAILED: {
      return state.setIn(['project', 'errors'], Immutable.fromJS(action.errors));
    }

    case MANAGING_REQUEST_FAILED: {
      return state.setIn(['managing', 'errors'], Immutable.fromJS(action.errors));
    }

    case CLEAR_ERRORS: {
      return initialState;
    }

    default:
      return state;
  }
}
