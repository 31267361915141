import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import moment from 'moment';

import './style.scss';

const ManagingTable = (props) => {
  const { t } = useTranslation();
  // Retrieve data with destructuring
  const {
    headings,
    rows,
    onDetail,
    onEdit,
    onDelete,
    onShow,
  } = props;

  // Get all the used fields
  const fields = headings.map((a) => a.key);
  const dateFields = ['updated_at', 'created_at'];

  return (
    <table className="ManagingTable">
      <thead>
        <tr>
          {headings.map((heading) => {
            return (
              <th key={heading.key}>
                {heading.value}
              </th>
            );
          })}
          <th className="ManagingTable__actions">Acties</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => {

          const onRowDelete = (e) => {
            e.stopPropagation();
            onDelete(row);
          };

          const onRowDetail = (e) => {
            e.stopPropagation();
            onDetail(row);
          };

          const onRowEdit = (e) => {
            e.stopPropagation();
            onEdit(row);
          };

          const onRowShow = (e) => {
            e.stopPropagation();
            onShow(row);
          };
          const key = `row_${index}`;

          return (
            <tr key={key} onClick={onRowShow}>
              {fields.map((field, index) => {
                return (
                  <td key={`td_${field}`}>
                    {dateFields.includes(field) ? (
                      <>{moment(row[field]).format('DD/MM/YYYY - HH:mm')}</>
                    ) : (
                      <>
                        {index === 1 &&
                          <div className="ManagingTable__showButton">
                            {t('project.overview.show')}
                            <i className="icon icon-arrow-right-top icon-variant-5" />
                          </div>}
                        {row[field]}
                      </>
                    )}
                  </td>
                );
              })}
              {/* Default actions */}
              <td className="ManagingTable__buttons">
                <div className="button__row">
                  {onDetail && (
                    <Button onClick={onRowDetail} className="Button Button--small">
                      {t('project.overview.details')}
                      <i className="icon icon-edit icon-variant-1" />
                    </Button>
                  )}
                  {onEdit && (
                    <Button onClick={onRowEdit} className="Button Button--small Button--round">
                      <i className="icon icon-rewrite icon-variant-1" />
                    </Button>
                  )}
                  {onDelete && (
                    <Button onClick={onRowDelete} className="Button Button--small Button--round Button--delete">
                      <i className="icon icon-trash icon-variant-1" />
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

};
export default ManagingTable;
