import React from 'react';
import { useTranslation } from 'react-i18next';

import { GenericScreen } from '../../components';

import './style.scss';

const NotAllowed = () => {
  const { t } = useTranslation();

  return (
    <GenericScreen
      className="NotAllowed"
      headerLogoIcon
      profileButton
    >
      <h1>{t('navigation.not_allowed.title')}</h1>
      <p>{t('navigation.not_allowed.description')}</p>
      <button type="button" className="Button Button--primary Button--block" onClick={() => window.history.back()}>
        {t('navigation.not_allowed.back')}
      </button>
    </GenericScreen>
  );
};

export default NotAllowed;
