import React, { useEffect, useState } from 'react';
import c from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getPublicProject, getProject } from '../../../actions/project';
import { PROJECT_ACCESS_CODE_STATUS_SUCCESS } from '../../../config/constants';
import { buildUrl } from '../../../helpers';
import { ROUTE_PROJECT_FLOW } from '../../../config/routes';

import {
  FormAccessCode,
  FormAnonymousLogin,
  FormLogin,
  GenericScreen,
  Markdown,
} from '../../../components';

import './style.scss';

const ProjectEntry = (props) => {
  const dispatch = useDispatch();
  const { match: { params: { projectSlug } }, history } = props;

  const isSignedIn = useSelector((state) => state.getIn(['user', 'token'], false));
  const projectName = useSelector((state) => state.getIn(['project', 'name'], null));
  const description = useSelector((state) => state.getIn(['project', 'description'], ''));
  const accessCodeRequired = useSelector((state) => state.getIn(['project', 'is_protected'], true));
  const accessCodeValidationStatus = useSelector((state) => state.getIn(['project', 'access_code_validation_status'], ''));
  const anonymousLogin = useSelector((state) => state.getIn(['project', 'anonymous_mode'], false));

  const [accessCode, setAccessCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showAccessCode, setShowAccessCode] = useState(accessCodeRequired && accessCodeValidationStatus !== PROJECT_ACCESS_CODE_STATUS_SUCCESS);

  const classNames = c('ScreenProjectDetail');

  // Get the public project info if project rounds are not loaded yet.
  useEffect(() => {
    if (isSignedIn && !showAccessCode) {
      // If we're signed in and dont show the access code, navigate to the start of the project
      history.push(buildUrl(ROUTE_PROJECT_FLOW, [projectSlug, '0']));
    } else if (!isSignedIn || (isSignedIn && showAccessCode)) {
      setIsLoading(!projectName);
    }
  }, [dispatch, projectSlug, isSignedIn, showAccessCode, projectName]);

  // Always refetch the project when the URL slug changed
  useEffect(() => {
    if (isSignedIn) {
      dispatch(getProject(projectSlug));
    } else {
      dispatch(getPublicProject(projectSlug));
    }
  }, [dispatch, projectSlug]);

  useEffect(() => {
    setShowAccessCode(accessCodeRequired && accessCodeValidationStatus !== PROJECT_ACCESS_CODE_STATUS_SUCCESS);
  }, [accessCodeRequired, accessCodeValidationStatus]);

  const renderForm = () => {
    // If user is logged in and access code is not required OR successful, we show a loading state while its redirecting
    if (isSignedIn && !showAccessCode) {
      return (
        <span />
      );
    }

    // If access code is required OR not successful (also when logged in), show the access code.
    if (showAccessCode) {
      return (
        <FormAccessCode onSubmit={(code) => setAccessCode(code)} />
      );
    }

    // After access code is successful OR not required, and user is not logged in,
    // show the anonymous login form if project requires this.
    if (anonymousLogin) {
      return (
        <FormAnonymousLogin />
      );
    }

    // If non of the above, fall back to login form.
    return (
      <FormLogin accessCode={accessCode} />
    );
  };

  return (
    <div>
      {!isLoading && (
        <GenericScreen
          className={classNames}
          profileButton={isSignedIn}
          headerLogoIcon
        >
          {projectName && (
            <div className="ScreenProjectDetail__name">
              <h1>{projectName}</h1>
            </div>
          )}

          {description && (
            <div className="ScreenProjectDetail__description">
              <Markdown>{description}</Markdown>
            </div>
          )}

          <div className="ScreenProjectDetail__form">
            {renderForm()}
          </div>
        </GenericScreen>
      )}
    </div>
  );
};

export default ProjectEntry;
