import Immutable from 'immutable';

import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_MESSAGE,
  CLEAR_GLOBAL_MESSAGE,
  CLEAR_GLOBAL_ERROR,
} from '../../actions/messages';

const initialState = Immutable.fromJS({
  globalMessage: null,
  globalError: null,
});

export default function messages(state = initialState, action = {}) {
  switch (action.type) {
    case SET_GLOBAL_ERROR: {
      return state.setIn(['globalError'], Immutable.fromJS(action.message));
    }

    case SET_GLOBAL_MESSAGE: {
      return state.setIn(['globalMessage'], Immutable.fromJS(action.message));
    }

    case CLEAR_GLOBAL_MESSAGE: {
      return state.setIn(['globalMessage'], null);
    }

    case CLEAR_GLOBAL_ERROR: {
      return state.setIn(['globalError'], null);
    }

    default:
      return state;
  }
}
