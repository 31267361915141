import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
} from '@uncinc/uncinc-react-kitchen-sink';

import { ManagingTable, Pager } from '../../../components';

import {
  getProjects, projectLoaded,
} from '../../../actions/managing';

import { buildUrl } from '../../../helpers';

import {
  ROUTE_PROJECT_OVERVIEW_ADD,
  ROUTE_ADMIN_PROJECT_DETAIL,
  ROUTE_PROJECT_OVERVIEW_EDIT,
  ROUTE_PROJECT_ENTRY,
} from '../../../config/routes';

import './style.scss';

const ProjectOverview = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const lastPage = useSelector((state) => state.getIn(['managing', 'projects', 'last_page'], null));

  const projects = useSelector((state) => state.getIn(['managing', 'projects', 'data'], List()));
  const clientId = useSelector((state) => state.getIn(['user', 'clients', 0, 'id'], 0));
  const rows = projects.toJS();

  const userRoles = useSelector((state) => state.getIn(['user', 'roles'], List()));
  const isProjectManager = userRoles.findIndex((i) => i.get('name') === 'project-manager') !== -1;

  // Prepare facilitator data
  if (rows.length > 0) {
    rows.map((project) => {
      const results = project.facilitator_users.map((a) => a.name);
      project.facilitators = results.length > 0 ? results.join(',') : '-';
      project.client_name = project.client.name;
      project.publication_state_text = project.publication_state === 1 ? 'Gepubliceerd' : 'Concept';
    });
  }

  const headings = [
    { key: 'id', value: t('general.headings.id') },
    { key: 'name', value: t('general.headings.name') },
    { key: 'facilitators', value: t('general.headings.facilitators') },
    { key: 'publication_state_text', value: t('general.headings.publication_state') },
    { key: 'client_name', value: t('general.headings.client') },
    { key: 'updated_at', value: t('general.headings.updated_at') },
  ];

  const redirectToAdd = () => {
    const randomAccessCode = Math.round(Math.random() * (999999 - 100000) + 100000);
    dispatch(projectLoaded({ access_code: randomAccessCode }));

    const url = buildUrl(
      ROUTE_PROJECT_OVERVIEW_ADD,
    );
    history.push(`${url}?client_id=${clientId}`);
  };

  const redirectToEdit = (row) => {
    history.push(buildUrl(
      ROUTE_PROJECT_OVERVIEW_EDIT,
      [row.slug],
    ));
  };

  const redirectToDetail = (row) => {
    history.push(buildUrl(
      ROUTE_ADMIN_PROJECT_DETAIL,
      [row.slug],
    ));
  };

  const redirectToShow = (row) => {
    window.location = buildUrl(
      ROUTE_PROJECT_ENTRY,
      [row.slug],
    );
  };

  useEffect(() => {
    dispatch(getProjects(currentPage));
  }, [dispatch, currentPage]);

  return (
    <div className="ProjectsOverview">
      <Container large>
        <div className="ProjectsOverview__content">
          <h2>
            {t('admin.project-overview.title')}
          </h2>
          <ManagingTable
            headings={headings}
            rows={rows}
            onDetail={(row) => {
              redirectToDetail(row);
            }}
            onShow={(row) => {
              redirectToShow(row);
            }}
          />

          {lastPage && (
            <Pager
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={lastPage}
            />
          )}

        </div>
      </Container>
    </div>
  );
};

export default ProjectOverview;
