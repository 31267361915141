import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { List } from 'immutable';
import { useTranslation } from 'react-i18next';

import {
  Link,
} from 'react-router-dom';
import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';
import {
  getUserProfile,
} from '../../actions/user';

import {
  getSurvey,
  sendSurvey,
} from '../../actions/survey';

import {
  ROUTE_HOME, ROUTE_PROJECT_ENTRY,
} from '../../config/routes';


import {
  ErrorMessage,
} from '../../components';

import './style.scss';
import { buildUrl } from '../../helpers';
import { setGlobalMessage } from '../../actions/messages';

const Survey = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const surveyId = useSelector((state) => state.getIn(['survey', 'survey', 'id'], ''));
  const questions = useSelector((state) => state.getIn(['survey', 'survey', 'questions'], List()));
  const projectSlug = useSelector((state) => state.getIn(['project', 'slug']));
  const initialValues = {};
  const validationSchema = {};

  questions.map((question, index) => {
    initialValues[`q${question.getIn(['id'])}`] = '';
    validationSchema[`q${question.getIn(['id'])}`] = Yup.string().required(t('survey.required_field'));
  });

  const successCallback = () => {
    dispatch(getUserProfile());
    dispatch(setGlobalMessage(t('survey.success')));
    history.push(buildUrl(ROUTE_PROJECT_ENTRY, [projectSlug]));
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      dispatch(sendSurvey(surveyId, values, successCallback));
    },
  });

  useEffect(() => {
    dispatch(getSurvey());
  }, [dispatch]);

  return (
    <div className="Survey">
      <Container>
        <div className="Survey__content">
          <h1 className="Survey__title">{t('survey.title')}</h1>
          <div className="Survey__description">
            {t('survey.description')}
          </div>
          <form onSubmit={formik.handleSubmit}>
            {questions.map((question, index) => {

              const questionId = `q${question.getIn(['id'])}`;
              if (question.getIn(['type']) === 'string') {
                return (
                  <div key={questionId}>
                    <label htmlFor={questionId}>
                      <div className="Survey__label">{question.getIn(['content'])}</div>
                      <input
                        id={questionId}
                        name={questionId}
                        type="text"
                        onChange={formik.handleChange}
                      />
                    </label>
                    {formik.errors[questionId] ? (
                      <ErrorMessage message={formik.errors[questionId]} />
                    ) : null}
                  </div>
                );
              }

              if (question.getIn(['type']) === 'number') {
                const rules = question.get('rules');
                const attributes = {};
                rules.forEach(
                  (rule) => {
                    const [key, value] = rule.split(':');
                    attributes[key] = value;
                  },
                );

                return (
                  <div key={questionId}>
                    <label htmlFor={questionId}>
                      <div className="Survey__label">{question.getIn(['content'])}</div>
                      <input
                        id={questionId}
                        name={questionId}
                        type="number"
                        onChange={formik.handleChange}
                        {...attributes}
                      />
                    </label>
                    {formik.errors[questionId] ? (
                      <ErrorMessage message={formik.errors[questionId]} />
                    ) : null}
                  </div>
                );
              }

              if (question.getIn(['type']) === 'radio') {
                return (
                  <div className="Survey__radio" key={questionId}>
                    <div className="Survey__label">{question.getIn(['content'])}</div>
                    <select
                      defaultValue="noAnswer"
                      name={questionId}
                      id={questionId}
                      onChange={formik.handleChange}
                      className="Survey__select">
                      <option disabled value="noAnswer">Selecteer</option>
                      {question.getIn(['options']).map((option) => {
                        return (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        );
                      })};
                    </select>
                    {formik.errors[questionId] ? (
                      <ErrorMessage message={formik.errors[questionId]} />
                    ) : null}
                  </div>
                );
              }


            })}
            <Button type="submit" className="Button Button--primary Button--block">
              {t('survey.submit')}
            </Button>
          </form>
          <div className="Survey__skip">
            <Link to={ROUTE_HOME}>
              {t('survey.skip')}
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Survey;
