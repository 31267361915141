import React, { useState } from 'react';
import c from 'classnames';

import { DEFAULT_MAX_CHARACTERS_CARD } from '../../../config/constants';

import './style.scss';

const FieldTextArea = ({ index, fieldName, initialValue = '', placeholder, height = 72, onBlur, onChange, setInputIsEmpty }) => {
  const [fieldValue, setFieldValue] = useState(initialValue);
  const [inputHeight, setInputHeight] = useState(height);
  const [charCount, setCharCount] = useState(DEFAULT_MAX_CHARACTERS_CARD - initialValue.length);

  const className = c('FieldTextArea', {
    'FieldTextArea--isFilled': initialValue.length > 0,
  });

  return (
    <div className={className}>
      <textarea
        name={fieldName}
        className="FieldTextArea__textarea"
        value={fieldValue}
        placeholder={placeholder}
        maxLength={DEFAULT_MAX_CHARACTERS_CARD}
        style={{ height: inputHeight }}
        onChange={(event) => {
          const { value, scrollHeight } = event.nativeEvent.target;
          setFieldValue(value);
          setInputHeight(scrollHeight);
          setCharCount(DEFAULT_MAX_CHARACTERS_CARD - value.length);
          if (setInputIsEmpty) setInputIsEmpty(value.length === 0);
          if (onChange) onChange(value);
        }}
        onBlur={(event) => {
          const { value, scrollHeight } = event.nativeEvent.target;
          if (onBlur) onBlur(index, value, scrollHeight);
        }}
      />
      <span className="FieldTextArea__counter">{charCount}</span>
    </div>
  );
};

export default FieldTextArea;
