import React from 'react';
import { Formik } from 'formik';
import Immutable from 'immutable';
import { FormNumber, Image } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import {
  postAnswers,
} from '../../../../../actions/project';

import { Markdown, GenericScreen, StickyFooter, FieldRadioList } from '../../../../../components';
import { SURVEY_QUESTION_TYPES } from '../../../../../config/constants';

import './style.scss';
import FieldTextArea from '../../../../../components/Field/TextArea';


const ProjectFlowRoundTypeSurvey = ({ projectName, round, isPreview, roundSubmitSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const roundId = round.get('id');
  const title = round.get('title');
  const body = round.get('description');
  const mediaUrl = round.get('media_url', null);
  const mediaSubtitle = round.get('media_subtitle', null);
  const stimulusId = round.getIn(['stimuli', 0, 'id']);
  const surveyQuestionType = round.getIn(['stimuli', 0, 'type'], null);
  const multipleChoiceAnswers = round.getIn(['stimuli', 0, 'options'], Immutable.List());

  const projectSlug = useSelector((state) => state.getIn(['project', 'slug']), '');

  // Form validation.
  const getValidationSchema = () => {
    let surveyAnswerValidation = Yup.string();

    if (surveyQuestionType === SURVEY_QUESTION_TYPES.NUMBER) {
      surveyAnswerValidation = Yup.number();
    }
    return Yup.object().shape({
      surveyAnswer: surveyAnswerValidation,
    });
  };

  // Success handler.
  const onPostAnswerSuccess = () => {
    roundSubmitSuccess();
  };

  // Submit handler.
  const handleSubmit = ({ surveyAnswer }) => {
    const data = [{ answer: surveyAnswer }];
    dispatch(postAnswers(projectSlug, roundId, stimulusId, data, onPostAnswerSuccess));
  };

  const renderSurveyInput = (values, setFieldValue) => {
    switch (surveyQuestionType) {
      case SURVEY_QUESTION_TYPES.NUMERIC:
        return (
          <FormNumber
            key={`surveyAnswer_${roundId}`}
            fieldName="surveyAnswer"
            min={0}
            step={1}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        );
      case SURVEY_QUESTION_TYPES.SINGLE_CHOICE:
        return (
          <FieldRadioList
            key={`surveyAnswer_${roundId}`}
            fieldName="surveyAnswer"
            options={multipleChoiceAnswers.valueSeq().toArray()}
            onChange={(answer) => {
              setFieldValue('surveyAnswer', answer);
            }}
          />
        );
      case SURVEY_QUESTION_TYPES.OPEN:
      default:
        return (
          <FieldTextArea
            key={`surveyAnswer_${roundId}`}
            fieldName="surveyAnswer"
            onChange={(value) => {
              setFieldValue('surveyAnswer', value);
            }}
          />
        );
    }
  };

  return (
    <GenericScreen title={projectName} isPreview={isPreview}>
      <div className="ProjectFlowRoundTypeInformation">
        <h1 className="ProjectFlowRoundTypeInformation__title">{title}</h1>

        {mediaUrl &&
          <>
            <Image
              src={mediaUrl}
              className="ProjectFlowRoundTypeInformation__image"
            />
            {mediaSubtitle && (
              <p className="ProjectFlowRoundTypeInformation__imageCaption small">
                {mediaSubtitle}
              </p>
            )}
          </>}

        <Markdown className="ProjectFlowRoundTypeInformation__body">
          {body}
        </Markdown>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema()}
          initialValues={{ surveyAnswer: null }}
        >
          {({ values, setFieldValue, submitForm }) => (
            <>
              {renderSurveyInput(values, setFieldValue)}

              <StickyFooter
                className="ProjectFlowRoundTypeSurvey__footer"
                buttonText={t('project.survey.button-next')}
                buttonClick={submitForm}
                disabled={!values.surveyAnswer && values.surveyAnswer !== 0}
                buttonIcon={<i className="icon icon-arrow-right icon-variant-1" />}
              />
            </>
          )}
        </Formik>

      </div>
    </GenericScreen>
  );
};

export default ProjectFlowRoundTypeSurvey;
