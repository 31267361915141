import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Immutable from 'immutable';
import { Button } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  updateRound,
  updateQuestion,
  addRoundToProject,
} from '../../../../../actions/managing';

import {
  ErrorMessage,
  MarkdownEditor,
} from '../../../../../components';

import { TEXT_INPUT_MAX_LENGTH } from '../../../../../config/constants';
import { setGlobalMessage } from '../../../../../actions/messages';

import './style.scss';

const QuestionsV3Edit = ({ round, setFirstRoundActive, redirectToDashboard, deleteProjectRound, answersCount, redirectToPreviewRound }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const id = round.get('id');
  const projectSlug = useSelector((state) => state.getIn(['managing', 'project', 'slug']), null);
  const description = round.get('description');
  const roundTypeId = round.getIn(['round_type', 'id']);
  const stimuli = round.getIn(['stimuli', 0], Immutable.List());
  const stimuliId = stimuli.getIn(['id'], null);
  const stimuliQuestion = stimuli.getIn(['question'], '');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      round_type_id: roundTypeId,
      question: stimuliQuestion,
      description: description,
    },
    validationSchema: Yup.object().shape({
      question: Yup
        .string()
        .required(t('admin.project-overview.round_edit.title_required')),
    }),

    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (roundValues) => {
      const successCallback = () => {
        dispatch(setGlobalMessage('Question V3 Block is succesvol opgeslagen'));
      };
      // Set question value as title as well.
      roundValues.title = roundValues.question;
      // Update round.
      if (id) {
        dispatch(updateRound(roundValues, projectSlug, id, successCallback));
        dispatch(updateQuestion(roundValues, projectSlug, id, stimuliId, successCallback));
      } else {
        dispatch(addRoundToProject(projectSlug, roundValues, () => {
          setFirstRoundActive();
        }));
      }
    },
  });

  const onDashboardButtonClicked = () => {
    redirectToDashboard(stimuliId);
  };

  return (
    <div className="AdminFlowEdit AdminQuestionsV3Edit">
      <div className="AdminFlowEdit__header">
        <h4>{t('admin.project-overview.round_edit.questionV3.title')}</h4>
        {answersCount > 0 &&
          <Button type="button"
            className="Button Button--small Button--blue"
            onClick={onDashboardButtonClicked}
          >
            {t('admin.project-overview.round_edit.questionV3.showResults')}
            <i className="icon icon-layers" />
            {answersCount}
          </Button>}
      </div>

      {/* Form - Start */}
      <form onSubmit={formik.handleSubmit}>

        {/* Field - question */}
        <label htmlFor="question">
          <div className="label">{t('admin.project-overview.round_edit.question')}</div>
          <p className="small">{t('admin.project-overview.round_edit.question_description')}</p>
          <input
            id="question"
            name="question"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.question}
            maxLength={TEXT_INPUT_MAX_LENGTH}
          />
        </label>
        {formik.errors.question ? (
          <ErrorMessage message={formik.errors.question} />
        ) : null}

        {/* Field - Stimuli */}
        <label htmlFor="description">
          <div className="ProjectOverviewRoundEdit__label">{t('admin.project-overview.round_edit.round_description')}</div>
          <p className="small">
            {t('admin.project-overview.round_edit.round_description_label')}
          </p>
          <MarkdownEditor
            value={formik.values.description}
            id="description_markdown"
            name="description"
            onChange={(data) => formik.setFieldValue('description', data)}
          />
        </label>

        <div className="AdminFlowEdit__buttons">
          <div onClick={deleteProjectRound}>
            {t('admin.project-overview.round_edit.delete_question')}
          </div>
          {id && (
            <Button className="Button--inverted" onClick={redirectToPreviewRound}>
              {t('admin.project-overview.round_edit.preview')}
              <i className="icon icon-eye-open" />
            </Button>
          )}
          <Button type="submit" className="Button Button--primary ProjectOverviewRoundEdit__Button">
            {t('project.overview.form.save')}
            <i className="icon icon-save icon-variant-1" />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default QuestionsV3Edit;
