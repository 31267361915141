import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { List } from 'immutable';
import {
  Link,
} from 'react-router-dom';

import {
  ROUTE_ACCOUNT_OVERVIEW,
  ROUTE_PROJECT_OVERVIEW,
  ROUTE_CLIENT_OVERVIEW,
} from '../../../config/routes';

import UserMenu from '../../UserMenu';
import { INDEX_NOT_FOUND, USER_ROLES } from '../../../config/constants';

import './style.scss';

const ManagingNavigation = () => {
  const { t } = useTranslation();
  const userRoles = useSelector((state) => state.getIn(['user', 'roles'], List()));
  const isAdmin = userRoles.findIndex((i) => i.get('name') === USER_ROLES.ADMIN) !== INDEX_NOT_FOUND;

  return (
    <div className="ManagingNavigation">
      <div className="ManagingNavigation__menu">
        <div className="ManagingNavigation__menu__item">
          <Link to={ROUTE_PROJECT_OVERVIEW}>{t('navigation.projects')}</Link>
        </div>
        {isAdmin && (
          <>
            <div className="ManagingNavigation__menu__item">
              <Link to={ROUTE_CLIENT_OVERVIEW}>{t('navigation.clients')}</Link>
            </div>
            <div className="ManagingNavigation__menu__item">
              <Link to={ROUTE_ACCOUNT_OVERVIEW}>{t('navigation.accounts')}</Link>
            </div>
          </>
        )}
        <div className="ManagingNavigation__menu__item profile">
          <UserMenu isManagementMenu />
        </div>
      </div>
    </div>
  );
};

export default ManagingNavigation;
