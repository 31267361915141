import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { confirm } from 'react-confirm-box';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import {
  getAccounts,
  deleteAccount,
} from '../../../actions/managing';

import { Confirm, ManagingTable, Pager } from '../../../components';
import { ROUTE_ACCOUNT_OVERVIEW_EDIT, ROUTE_ACCOUNT_OVERVIEW_ADD } from '../../../config/routes';

import { buildUrl } from '../../../helpers';

import './style.scss';

const AccountOverview = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const lastPage = useSelector((state) => state.getIn(['managing', 'accounts', 'last_page'], null));

  const accounts = useSelector((state) => state.getIn(['managing', 'accounts', 'data'], List()));
  const rows = accounts.toJS();

  // Prepare role data
  if (rows.length > 0) {
    rows.map((account) => {
      const results = account.roles.map((a) => a.name);
      account.accountRoles = results.length > 0 ? results.join(',') : '-';
    });
  }

  const headings = [
    { key: 'id', value: t('general.headings.id') },
    { key: 'name', value: t('general.headings.name') },
    { key: 'accountRoles', value: t('general.headings.roles') },
    { key: 'email', value: t('general.headings.email') },
    { key: 'created_at', value: t('general.headings.created_at') },
  ];

  const onDeleteAccount = async (row) => {
    // eslint-disable-next-line no-alert

    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            description={t('account.overview.confirm', { name: row.name })}
            confirm={t('button.yes')}
          />
        );
      },
    };
    const answer = await confirm('', options);

    if (answer === true) {
      dispatch(deleteAccount(row.id));
    }
  };

  const redirectToEdit = (row) => {
    history.push(buildUrl(
      ROUTE_ACCOUNT_OVERVIEW_EDIT,
      [row.id],
    ));
  };

  const redirectToAdd = () => {
    history.push(buildUrl(
      ROUTE_ACCOUNT_OVERVIEW_ADD,
    ));
  };


  useEffect(() => {
    dispatch(getAccounts(currentPage));
  }, [dispatch, currentPage]);

  return (
    <div className="AccountsOverview">
      <Container large>
        <div className="AccountsOverview__content">
          <h1>
            {t('admin.account-overview.title')}
          </h1>
          <Button type="button" onClick={redirectToAdd} className="Button Button--primary add">
            {t('admin.account-overview.add_account')}
            <div className="icon icon-plus icon-variant-1" />
          </Button>
          <ManagingTable
            headings={headings}
            rows={rows}
            onEdit={(row) => {
              redirectToEdit(row);
            }}
            onDelete={(row) => {
              onDeleteAccount(row);
            }}
          />
          {lastPage && (
            <Pager
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={lastPage}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default AccountOverview;
