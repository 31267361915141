import { Container } from '@uncinc/uncinc-react-kitchen-sink';
import React from 'react';

import './style.scss';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Container className="NotFound">
      <h2>{t('navigation.not_found')}</h2>
    </Container>
  );
};

export default NotFound;
