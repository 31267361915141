import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { confirm } from 'react-confirm-box';
import * as Yup from 'yup';
import { Button, FormTextField } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';
import { createProjectLabel, deleteProjectLabel, editProjectLabel, getAnswers, getProjectLabelInUse } from '../../actions/managing';

import Confirm from '../Confirm';

import './style.scss';

const LabelEditor = (props) => {
  const {
    activeLabelId,
    labels,
    open,
    setOpen,
    onLabelClick,
    projectSlug,
    refreshCallback = () => { }
  } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [editingLabel, setEditingLabel] = useState(null);

  const validationSchema = Yup.object().shape({
    label: Yup.string(),
  });

  const onItemLabelClick = (event, label) => {
    event.stopPropagation();

    if (label) {
      if (label.get('id') !== activeLabelId) {
        onLabelClick(label);
      } else {
        onLabelClick(null);
      }
    }
  };

  const onLabelEditClicked = (event, label) => {
    event.stopPropagation();
    setEditingLabel(label);
  };

  const onCancelEditingLabelClick = (event) => {
    event.stopPropagation();
    setEditingLabel(null);
  };

  const onSubmitLabelFormClick = (event, submitForm) => {
    event.stopPropagation();
    submitForm(null);
  };

  const deleteLabel = (labelId) => {
    dispatch(deleteProjectLabel(projectSlug, labelId, () => {
      refreshCallback(true);
      setEditingLabel(null);
    }));
  };

  const handleLabelDeletion = async (usageCount) => {
    if (usageCount > 0) {
      const options = {
        render: (message, onConfirm, onCancel) => {
          return (
            <Confirm
              onConfirm={onConfirm}
              onCancel={onCancel}
              title={t('managing.project-result.delete_label')}
              description={t('managing.project-result.confirm_label_deletion', { count: usageCount })}
              confirm={t('button.yes')}
              cancel={t('button.no_go_back')}
            />
          );
        },
      };

      const answer = await confirm('', options);

      if (answer === true) {
        deleteLabel(editingLabel.get('id'));
      }
    } else {
      deleteLabel(editingLabel.get('id'));

    }
  };
  const onDeleteLabelClick = async (event) => {
    event.stopPropagation();
    dispatch(getProjectLabelInUse(projectSlug, editingLabel.get('id'), ({ usage_count }) => {
      handleLabelDeletion(usage_count);
    }));
  };

  const getLabelClass = (labelId) => {
    const activeClass = labelId && labelId === activeLabelId ? 'active' : '';
    return `LabelEditor__item ${activeClass}`;
  };

  const closeEditor = () => {
    setEditingLabel(null);
    setOpen(false);
  };

  const handleCreateInputFormSubmit = ({ label }, { resetForm }) => {
    setEditingLabel(null);
    // Create the label
    dispatch(createProjectLabel(projectSlug, { title: label }, () => refreshCallback(true)));
    resetForm();
  };

  const handleEditInputFormSubmit = ({ label }, { resetForm }) => {
    setEditingLabel(null);
    // Update the label
    dispatch(editProjectLabel(projectSlug, editingLabel.get('id'), { title: label }, () => refreshCallback(true)));
    resetForm();
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('click', closeEditor, { passive: true, once: true });
    }
  }, [open]);

  return (
    <div className={`LabelEditor ${!open ? 'hidden' : ''}`} onClick={(event) => event.stopPropagation()}>
      <div className="LabelEditor__input">
        {editingLabel && (
          <Formik
            initialValues={{ label: editingLabel.get('title') }}
            validationSchema={validationSchema}
            onSubmit={handleEditInputFormSubmit}
          >
            {({ values, _setFieldValue, submitForm }) => (
              <div className="LabelEditor__input__instance">
                <FormTextField
                  fieldName="label"
                  value={values.label}
                  onClick={(event) => event.stopPropagation()} />
                <Button
                  className="Button Button--text Button--edit"
                  onClick={(event) => onSubmitLabelFormClick(event, submitForm)}
                >{t('button.done')}
                </Button>
              </div>
            )}
          </Formik>
        )}
        {!editingLabel && (
          <Formik
            initialValues={{ label: '' }}
            validationSchema={validationSchema}
            onSubmit={handleCreateInputFormSubmit}
          >
            {({ values, _setFieldValue, submitForm }) => (
              <div className="LabelEditor__input__instance">
                <FormTextField
                  fieldName="label"
                  value={values.label}
                  placeholder="Nieuw label"
                  onClick={(event) => event.stopPropagation()}
                />
                <Button
                  disabled={values.label === ''}
                  className="Button Button--icon"
                  onClick={(event) => onSubmitLabelFormClick(event, submitForm)}
                >
                  <div className="icon icon-plus" />
                </Button>
              </div>
            )}
          </Formik>
        )}
      </div>
      {!editingLabel && labels && labels.size > 0 && (
        <div className="LabelEditor__list">
          {labels.map((label, index) => {
            return (
              <span key={`label_${index}`} className={getLabelClass(label.get('id'))} onClick={(e) => onItemLabelClick(e, label)}>
                {label.get('title')}
                <span
                  className="labelEdit icon icon-edit"
                  onClick={(event) => onLabelEditClicked(event, label)}
                />
              </span>
            );
          })}
        </div>
      )}
      {editingLabel && (
        <div className="LabelEditor__editMenu">
          <Button className="Button Button--text" onClick={onDeleteLabelClick}><i className="icon icon-trash" />{t('button.delete')}</Button>
          <Button className="Button Button--text" onClick={onCancelEditingLabelClick}><i className="icon icon-cross" />{t('button.cancel')}</Button>
        </div>
      )}
    </div>
  );
};

export default LabelEditor;
