import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Immutable, { List } from 'immutable';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import config from 'react-global-configuration';
import { confirm } from 'react-confirm-box';

import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';
import { clearErrors } from '../../../../actions/errors';
import { setGlobalMessage } from '../../../../actions/messages';
import { STACK_LIMIT_MIN, STACK_LIMIT_MAX, TEXT_INPUT_MAX_LENGTH, USER_ROLES, INDEX_NOT_FOUND } from '../../../../config/constants';

import { buildUrl } from '../../../../helpers';
import { ROUTE_ADMIN_PROJECT_DETAIL } from '../../../../config/routes';

import {
  updateProject,
  uploadProjectTranslations,
  deleteProject,
} from '../../../../actions/managing';

import {
  ErrorMessage,
  FieldFileUpload,
  MarkdownEditor,
  Confirm,
} from '../../../../components';

import './style.scss';

const ProjectDetailGeneral = ({ slug, history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const name = useSelector((state) => state.getIn(['managing', 'project', 'name'], ''));
  const entitySlug = useSelector((state) => state.getIn(['managing', 'project', 'slug'], ''));
  const description = useSelector((state) => state.getIn(['managing', 'project', 'description'], ''));
  const accessCode = useSelector((state) => state.getIn(['managing', 'project', 'access_code'], ''));
  const stackLimit = useSelector((state) => state.getIn(['managing', 'project', 'stack_limit'], STACK_LIMIT_MAX));
  const clientId = useSelector((state) => state.getIn(['managing', 'project', 'client_id'], ''));
  const publicationState = useSelector((state) => state.getIn(['managing', 'project', 'publication_state'], ''));
  const anonymousMode = useSelector((state) => state.getIn(['managing', 'project', 'anonymous_mode'], false));
  const translations = useSelector((state) => state.getIn(['managing', 'project', 'translations'], Immutable.List()));
  const projectId = useSelector((state) => state.getIn(['managing', 'project', 'id'], ''));
  const basePath = config.get('API_URL');
  const downloadableUrl = `${basePath}/projects/download-translation-file/${projectId}`;

  const userRoles = useSelector((state) => state.getIn(['user', 'roles'], List()));
  const isAdmin = userRoles.findIndex((i) => i.get('name') === USER_ROLES.ADMIN) !== INDEX_NOT_FOUND;

  const [currentSlug, setCurrentSlug] = useState(entitySlug);

  const successCallback = () => {
    const message = `Project '${name}' aangepast`;
    dispatch(setGlobalMessage(message));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name,
      description: description,
      access_code: accessCode,
      stack_limit: stackLimit,
      translations_file_data: '',
      translations_file_name: '',
      publication_state: publicationState,
      client_id: clientId,
      anonymous_mode: anonymousMode,
    },
    validationSchema: Yup.object().shape({
      name: Yup
        .string()
        .required('Titel verplicht'),
      description: Yup
        .string()
        .required('Beschrijving verplicht'),
      publication_state: Yup
        .string(),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      const projectValuesFiltered = Object
        .entries(values)
        .filter(([key]) => !key.startsWith('translations_file'));

      const projectValues = Object.fromEntries(projectValuesFiltered);

      const updateProjectCallback = ({ id }) => {
        // Upload the translation file if it was set.
        if (values.translations_file_data) {
          dispatch(uploadProjectTranslations({
            id,
            data: values.translations_file_data,
          }));
        }
        successCallback();
      };

      dispatch(clearErrors());
      dispatch(updateProject(projectValues, slug, updateProjectCallback));
    },
  });

  const onDeleteProject = async (slug) => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            description={t('project.overview.confirm', { name })}
            confirm={t('button.yes')}
          />
        );
      },
    };
    const answer = await confirm('', options);

    if (answer === true) {
      dispatch(deleteProject(slug));
    }
  };

  useEffect(() => {
    if (currentSlug !== entitySlug) {
      setCurrentSlug(entitySlug);
      history.push(buildUrl(
        ROUTE_ADMIN_PROJECT_DETAIL,
        [entitySlug],
      ));
    }
  }, [entitySlug, currentSlug, setCurrentSlug, history, ROUTE_ADMIN_PROJECT_DETAIL]);

  return (
    <div className="ProjectDetailGeneral">
      <Container>
        <div className="ProjectDetailLayout__intro">
          <p className="ProjectDetailLayout__intro__text">{t('admin.project-detail.general.intro')}</p>
          <Button type="button" onClick={() => document.querySelector('.Button-send-form').click()}>
            {t('admin.project-overview.round_edit.save')}
            <i className="icon icon-save icon-variant-1" />
          </Button>
        </div>
        <form onSubmit={formik.handleSubmit}>

          <div className="ProjectDetailLayout">

            <div className="ProjectDetailLayout__leftColumn">
              <h4>{t('admin.project-detail.general.project_status')}</h4>
              <p className="small">{t('admin.project-detail.general.project_status_description')}</p>
              <label htmlFor="publication_state">
                <select
                  id="publication_state"
                  name="publication_state"
                  onChange={formik.handleChange}
                  value={formik.values.publication_state}
                >
                  <option value={0} label="Concept">Concept</option>
                  <option value={1} label="Gepubliceerd">Gepubliceerd</option>
                </select>
              </label>
              {formik.errors.publication_state ? (
                <ErrorMessage message={formik.errors.publication_state} />
              ) : null}

              <Button className="Button Button--red" onClick={() => onDeleteProject(slug)}>
                {t('project.overview.delete')}
                <i className="icon icon-trash" />
              </Button>
            </div>

            <div className="ProjectDetailLayout__rightColumn">
              <h4>{t('admin.project-detail.general.general')}</h4>

              <label htmlFor="name">
                <div className="ProjectDetailGeneral__label">{t('project.overview.form.title')}</div>
                <p className="small">{t('project.overview.form.title_description')}</p>
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder={t('form.name.placeholder')}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  maxLength={TEXT_INPUT_MAX_LENGTH}
                />
              </label>
              {formik.errors.name ? (
                <ErrorMessage message={formik.errors.name} />
              ) : null}

              <label htmlFor="description">
                <div className="ProjectDetailGeneral__label">{t('project.overview.form.description')}</div>
                <p className="small">{t('project.overview.form.description_description')}</p>
                <MarkdownEditor
                  value={formik.values.description}
                  id="description_markdown"
                  name="description"
                  onChange={(data) => formik.setFieldValue('description', data)}
                />
              </label>
              {formik.errors.description ? (
                <ErrorMessage message={formik.errors.description} />
              ) : null}

              <label htmlFor="anonymous_mode">
                <div className="ProjectDetailGeneral__label">{t('project.overview.form.anonymous_mode')}</div>
                <p className="small">{t('project.overview.form.anonymous_mode_description')}</p>
                <input
                  id="anonymous_mode"
                  name="anonymous_mode"
                  type="checkbox"
                  onChange={() => (
                    formik.setFieldValue(
                      'anonymous_mode',
                      !formik.values.anonymous_mode,
                    )
                  )}
                  checked={formik.values.anonymous_mode}
                  className="checkbox"
                  value="1"
                />
              </label>
              {formik.errors.anonymous_mode ? (
                <ErrorMessage message={formik.errors.anonymous_mode} />
              ) : null}

              <label htmlFor="access_code">
                <div className="ProjectDetailGeneral__label">{t('project.overview.form.access_code')}</div>
                <p className="small">{t('project.overview.form.access_code_description')}</p>
                <input
                  id="access_code"
                  name="access_code"
                  type="number"
                  placeholder="123456"
                  onChange={formik.handleChange}
                  value={formik.values.access_code}
                />
              </label>
              {formik.errors.access_code ? (
                <ErrorMessage message={formik.errors.access_code} />
              ) : null}

              <label htmlFor="stack_limit">
                <div className="ProjectDetailGeneral__label">{t('project.overview.form.stack_limit')}</div>
                <p className="small">{t('project.overview.form.stack_limit_description')}</p>
                <input
                  id="stack_limit"
                  name="stack_limit"
                  type="number"
                  placeholder={STACK_LIMIT_MAX}
                  min={STACK_LIMIT_MIN}
                  max={STACK_LIMIT_MAX}
                  onChange={formik.handleChange}
                  value={formik.values.stack_limit}
                />
              </label>
              {formik.errors.stack_limit ? (
                <ErrorMessage message={formik.errors.stack_limit} />
              ) : null}

              {isAdmin && (
                <label htmlFor="translations_file">
                  <div className="ProjectDetailGeneral__label">{t('project.overview.form.translations_file')}</div>
                  <p className="small">{t('project.overview.form.translations_file_description')}</p>
                  <FieldFileUpload
                    name="translations_file"
                    types=".po"
                    fileName={formik.values.translations_file_name}
                    onSuccess={(fileName, fileData) => {
                      formik.setFieldValue('translations_file_data', fileData);
                      formik.setFieldValue('translations_file_name', fileName);
                    }}
                  />

                  {translations.size > 0 && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={downloadableUrl}
                      className="ProjectDetailGeneral__po-download-link"
                    >
                      {t('project.overview.po_file_download_link')}
                    </a>
                  )}
                </label>
              )}

              <Button type="submit" className="Button Button--primary Button-send-form">
                {t('project.overview.form.save')}
                <i className="icon icon-save icon-variant-1" />
              </Button>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default ProjectDetailGeneral;
