import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { List } from 'immutable';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';
import { ErrorMessage, ManagingTable, HeaderAdmin } from '../../../components';

import {
  clearErrors,
} from '../../../actions/errors';

import {
  createClient,
  getClient,
  projectLoaded,
  updateClient,
} from '../../../actions/managing';

import { buildUrl } from '../../../helpers';

import {
  ROUTE_CLIENT_OVERVIEW,
  ROUTE_PROJECT_OVERVIEW_ADD,
} from '../../../config/routes';

import { setGlobalMessage } from '../../../actions/messages';

import './style.scss';


const AccountOverviewEdit = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const name = useSelector((state) => state.getIn(['managing', 'client', 'name'], ''));
  const id = useSelector((state) => state.getIn(['managing', 'client', 'id'], ''));
  const projects = useSelector((state) => state.getIn(['managing', 'client', 'projects'], List()));
  const error = useSelector((state) => state.getIn(['errors', 'managing', 'errors', 'errors', 'name', 0]));
  const [submitted, setSubmitted] = useState(false);
  const { match: { params: { clientId } }, history } = props;

  const editMode = name !== '';

  const redirectToOverview = useCallback(() => {
    history.push(buildUrl(
      ROUTE_CLIENT_OVERVIEW,
    ));
  }, [history]);

  const successCallback = () => {
    const message = editMode ? `Client '${name}' aangepast.` : 'Client aangemaakt.';
    dispatch(setGlobalMessage(message));
    redirectToOverview();
  };

  const redirectToAddProject = () => {
    const randomAccessCode = Math.round(Math.random() * (999999 - 100000) + 100000);
    dispatch(projectLoaded({ access_code: randomAccessCode }));
    // possibly refactor to CoreLink
    const baseUrl = buildUrl(
      ROUTE_PROJECT_OVERVIEW_ADD,
    );
    history.push(`${baseUrl}?client_id=${id}`);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name,
    },
    validationSchema: Yup.object().shape({
      name: Yup
        .string()
        .required(t('form.name.required')),
    }),

    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      setSubmitted(true);
      dispatch(clearErrors());
      if (editMode) {
        dispatch(updateClient(values, clientId, successCallback));
      } else {
        dispatch(createClient(values, successCallback));
      }
    },
  });

  useEffect(() => {
    if (clientId) {
      dispatch(getClient(clientId));
    }
  }, [dispatch, clientId]);


  useEffect(() => {
    if (submitted && name === formik.values.name) {
      redirectToOverview();
    }
  }, [name, submitted, formik, redirectToOverview]);


  const rows = [];

  projects.map((project) => {
    rows.push({ id: project.getIn(['id']), name: project.getIn(['name']) });
  });

  const headings = [
    { key: 'id', value: t('general.headings.id') },
    { key: 'name', value: t('general.headings.name') },
  ];

  return (
    <div className="ClientOverviewEdit">
      {/* <div className="ClientOverviewEdit__header">
        <div onClick={redirectToOverview} className="icon icon-arrow-right" />
      </div> */}
      <Container>
        <div className="ClientOverviewEdit__content">
          <HeaderAdmin
            onBack={redirectToOverview}
          />
          <h1 className="ClientOverviewEdit__title">{editMode
            ? t('admin.client-overview-edit.edit_client')
            : t('admin.client-overview-edit.add_client')}
          </h1>
          {error && (
            <div className="alert alert-danger">
              {error}
            </div>
          )}
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="name">
              <div className="ClientOverviewEdit__label">
                {t('form.name.label')}
              </div>
              <input
                id="name"
                name="name"
                type="text"
                placeholder={t('form.name.placeholder')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </label>
            {formik.errors.name ? (
              <ErrorMessage message={formik.errors.name} />
            ) : null}
            <div className="ClientOverviewEdit__projects">
              <div className="ClientOverviewEdit__projects__label">
                {t('admin.client-overview-edit.projects')}
              </div>
              <Button type="button" className="Button Button--primary ProjectsOverview__add-project-button" onClick={() => redirectToAddProject()}>
                {t('admin.client-overview-edit.add_project')}
                <div className="icon icon-variant-1 icon-plus" />
              </Button>
              <ManagingTable
                headings={headings}
                rows={rows}
              />
            </div>
            <Button type="submit" className="Button Button--primary Button--block">
              {t('admin.client-overview-edit.save')}
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default AccountOverviewEdit;
