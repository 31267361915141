import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';
import { Button, Container } from '@uncinc/uncinc-react-kitchen-sink';
import { confirm } from 'react-confirm-box';
import { useTranslation } from 'react-i18next';
import { useUserRole } from '../../../../helpers';
import { ManagingTable, Confirm } from '../../../../components';
import { USER_ROLES } from '../../../../config/constants';
import {
  getProject,
  assignFacilitator,
  removeFacilitator,
} from '../../../../actions/managing';

import './style.scss';

const ProjectDetailUsers = ({ slug }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRole = useUserRole();
  const userCanManageFacilitators = userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.PROJECT_MANAGER;
  const userRef = useRef(null);

  const facilitatorUsers = useSelector((state) => state.getIn(['managing', 'project', 'facilitator_users'], List()));
  const accounts = useSelector((state) => state.getIn(['managing', 'accounts', 'data'], List()));
  const projectId = useSelector((state) => state.getIn(['managing', 'project', 'id']));

  const headings = [
    { key: 'id', value: t('general.headings.id') },
    { key: 'name', value: t('general.headings.name') },
    { key: 'email', value: t('general.headings.email') },
    { key: 'created_at', value: t('general.headings.created_at') },
  ];

  const submitFacilitator = async () => {
    const id = parseInt(userRef.current.value);

    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            description={t('confirm')}
            confirm={t('button.yes')}
          />
        );
      },
    };
    const answer = await confirm('', options);

    if (answer === true) {
      dispatch(assignFacilitator(id, projectId));
      setTimeout(() => {
        dispatch(getProject(slug));
      }, 600);
    }
  };

  const deleteFaciliator = (row) => {
    dispatch(removeFacilitator(row.id, projectId));
    setTimeout(() => {
      dispatch(getProject(slug));
    }, 600);
  };

  return (
    <div className="AdminProjectDetailUsers">
      <Container>
        <div className="ProjectDetailLayout__intro">
          <p className="ProjectDetailLayout__intro__text">{t('admin.project-detail.facilitators.intro')}</p>
        </div>

        <div className="ProjectDetailLayout">
          <div className="ProjectDetailLayout__leftColumn">
            <h4>
              {t('admin.project-detail.facilitators.title')}
            </h4>

            {userCanManageFacilitators && (
              <div className="AdminProjectDetail__account_add">
                <select name="accounts" id="accounts" ref={userRef}>
                  {accounts.map((account) => {
                    const isFacilitator = account.getIn(['roles']).findIndex((e) => e.get('name') === 'facilitator') !== -1;
                    const notExists = facilitatorUsers.findIndex((e) => e.get('id') === account.get('id')) !== -1;
                    return isFacilitator && !notExists && (
                      <option key={account.get('id')} value={account.get('id')}>
                        {account.get('name')}
                      </option>
                    );
                  })}
                </select>
                <Button onClick={submitFacilitator} className="Button Button--round ml-2">
                  <i className="icon icon-plus icon-variant-1" />
                </Button>
              </div>
            )}
          </div>

          <div className="ProjectDetailLayout__rightColumn">
            <ManagingTable
              headings={headings}
              rows={facilitatorUsers.toJS()}
              onDelete={userCanManageFacilitators && deleteFaciliator}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProjectDetailUsers;
