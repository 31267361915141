import React, { useState, useEffect, useRef } from 'react';
import c from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { confirm } from 'react-confirm-box';
import { List } from 'immutable';
import { Button } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  deleteAnswer,
  deleteCluster,
  updateAnswer,
  updateCluster,
  duplicateAnswer,
  destroyAllFlags,
  storeFlag,
} from '../../actions/managing';

import LabelEditor from '../LabelEditor';
import Confirm from '../Confirm';
import { INDEX_NOT_FOUND, USER_ROLES } from '../../config/constants';
import { unclusterAnswer, moveAnswerToTop } from '../../actions/project';


import './style.scss';

const ReportingCardStack = (props) => {
  const { t } = useTranslation();
  const {
    onClick,
    title,
    answer,
    isCluster = false,
    dark,
    activeLabelId,
    labels,
    projectSlug,
    stimuliId,
    projectRound,
    showLabels,
    moveToTopOption = false,
    unclusterOption = false,
    deleteOption = false,
    selectedCards,
    onCardSelect,
    refreshCallback = () => { }
  } = props;
  const dispatch = useDispatch();
  const [labelEditorOpen, setLabelEditorOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [labelIndex, setLabelIndex] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  const userId = answer ? answer.get('user_id', null) : null;
  const date = answer ? answer.getIn(['created_at'], null) : null;

  const clusterId = answer.get('cluster_id');

  const flagsCount = answer.get('flagged_count');
  const clusterFlaggedCount = answer.get('cluster_flagged_count');
  const amount = answer.get('answers_count', 0);

  const canDeleteCard = deleteOption && !isCluster;
  const canDuplicateCard = !isCluster;
  const canFlagCard = !isCluster;

  const userRoles = useSelector((state) => state.getIn(['user', 'roles'], List()));
  const isAdmin = userRoles.findIndex((i) => i.get('name') === USER_ROLES.ADMIN) !== INDEX_NOT_FOUND;
  const isFacilitator = userRoles.findIndex((i) => i.get('name') === USER_ROLES.FACILITATOR) !== INDEX_NOT_FOUND;

  const parsedDate = moment(date);
  const isCreatedToday = parsedDate.isSame(new Date(), 'day');
  const formattedDate = isCreatedToday ? 'Vandaag' : parsedDate.format('DD/MM/YYYY - HH:mm');

  const CardClasses = c('ReportingCardStack', {
    'ReportingCardStack--multiple': isCluster && amount > 1,
    'ReportingCardStack--dark': dark,
    'ReportingCardStack--is-cluster': isCluster,
    'ReporingCardStack--is-selected': isSelected,
  });

  useEffect(() => {
    setLabelIndex(labels.findIndex((obj) => { return obj.get('id') === activeLabelId; }));
  }, [activeLabelId, labels]);

  const refreshAnswers = (filtersUpdated = false) => {
    refreshCallback(filtersUpdated);
  };

  const updateSingleAnswer = (text) => {
    dispatch(updateAnswer(projectSlug, projectRound, stimuliId, answer.get('id'), text, () => refreshAnswers()));
  };

  const updateClusterLabel = (labelId, text) => {
    dispatch(updateCluster(projectSlug, projectRound, stimuliId, answer.get('cluster_id'), labelId, () => refreshAnswers(true)));
  };

  const onLabelClick = (label) => {
    const id = label ? label.get('id') : null;
    updateClusterLabel(id);
  };

  const OnAddLabelClick = (e) => {
    e.stopPropagation();
    setLabelEditorOpen(!labelEditorOpen);
  };

  const onDeleteClick = async (e, item) => {
    e.stopPropagation();

    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={t('project.overview.confirm-delete')}
            confirm={t('button.yes')}
            cancel={t('button.no_go_back')}
          />
        );
      },
    };

    const result = await confirm('', options);

    if (result) {
      if (amount > 1 && isCluster) {
        dispatch(deleteCluster(projectSlug, projectRound, stimuliId, item.get('cluster_id'), () => refreshAnswers()));
      } else {
        dispatch(deleteAnswer(projectSlug, projectRound, stimuliId, item.get('id'), () => refreshAnswers()));
      }
    }
  };

  const onMoveToTopClick = (e) => {
    e.stopPropagation();
    dispatch(moveAnswerToTop(projectSlug, projectRound, clusterId, answer.get('id'), () => refreshAnswers()));
  };

  const onDuplicateCardClick = (e) => {
    e.stopPropagation();
    dispatch(duplicateAnswer(projectSlug, projectRound, stimuliId, answer.get('id'), () => refreshAnswers()));
  };

  const onToggleFlag = (e) => {
    e.stopPropagation();
    if (flagsCount > 0) {
      dispatch(destroyAllFlags(projectSlug, projectRound, stimuliId, answer.get('id'), () => refreshAnswers()));
    } else {
      dispatch(storeFlag(projectSlug, projectRound, stimuliId, answer.get('id'), () => refreshAnswers()));
    }
  };

  const onUnclusterClick = (e) => {
    e.stopPropagation();
    dispatch(unclusterAnswer(projectSlug, projectRound, clusterId, answer.get('id'), () => refreshAnswers()));
  };

  const textInput = useRef(null);

  const submitStack = () => {
    updateSingleAnswer(textInput.current.value);
  };

  const editStack = (e) => {
    e.stopPropagation();

    if (editMode === false) {
      setEditMode(true);
    } else {
      setEditMode(false);
      submitStack();
    }
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    setIsSelected(!isSelected);
    onCardSelect(answer, !isSelected); // Notify the parent about the selection change
  };

  useEffect(() => {
    if (selectedCards && selectedCards.length === 0) {
      setIsSelected(false);
    }
  }, [selectedCards]);


  return (
    <div className={CardClasses} onClick={onClick}>
      <div className="ReportingCardStack__inner">
        {showLabels !== false && (
          <div className="ReportingCardStack__labeling">
            <Button
              className={c('Button Button--small', {
                'Button--light': activeLabelId === null,
                'Button--green': activeLabelId !== null,
              })}
              onClick={(e) => OnAddLabelClick(e)}
            >
              {activeLabelId ? (
                <div>{labels.getIn([labelIndex, 'title'])}</div>
              ) : (
                <>{t('component.reportingCard.label')}<div className="icon icon-plus icon-variant-1" /></>
              )}
            </Button>

            {onCardSelect && <i className={`icon icon-input-checkbox ${isSelected ? 'icon-variant-3' : ''}`} onClick={handleCheckboxChange} />}

            <LabelEditor
              labels={labels}
              activeLabelId={activeLabelId}
              open={labelEditorOpen}
              setOpen={setLabelEditorOpen}
              onLabelClick={onLabelClick}
              projectSlug={projectSlug}
              roundId={projectRound}
              stimultiId={stimuliId}
              refreshCallback={(labelsChanged = false) => refreshAnswers(labelsChanged)}
            />
          </div>
        )}

        {editMode ? (
          <textarea
            key="answerInput"
            ref={textInput}
            className="FieldTextArea__textarea"
            name="answerInput"
            defaultValue={title}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <h4 className="ReportingCardStack__title">{title}</h4>
        )}

        <div className="ReportingCardStack__informationWrapper">
          <div className="ReportingCardStack__user">
            <div className="icon icon-account icon-variant-1" />
            {userId}
          </div>
          {
            (isAdmin || isFacilitator) && (
              <div className="ReportingCardStack__date">
                <div className="icon icon-date icon-variant-1" />
                {formattedDate}
              </div>
            )
          }
        </div>

        <div className="ReportingCardStack__details">
          <Button
            className="Button Button--round-small Button--primary"
            onClick={(e) => editStack(e)}
          >
            <div className={`icon ${editMode === false ? 'icon-edit' : 'icon-save'} icon-variant-1`} />
          </Button>

          {isCluster && amount > 1 && (
            <Button className="Button Button--primary Button--small pl-2">
              <div className="icon icon-layers icon-variant-1" />
              {amount}
            </Button>
          )}
          {isCluster ? (
            <>
              {clusterFlaggedCount > 0 && (
                <Button type="button"
                  className="Button Button--small Button--flag"
                >
                  <i className="icon icon-flag" />
                  {clusterFlaggedCount}
                </Button>
              )}
            </>
          ) : (
            <>
              {flagsCount > 0 && (
                <Button type="button"
                  className="Button Button--small Button--flag"
                >
                  <i className="icon icon-flag" />
                  {flagsCount}
                </Button>
              )}
            </>
          )}

          {(moveToTopOption || canDuplicateCard || unclusterOption || canDeleteCard || canFlagCard) &&
            <div className="Dropdown">
              <Button
                className="Button Button--small Button--primary"
                onClick={(e) => e.stopPropagation()}
              >
                {t('component.reportingCard.dropdown.title')}
                <i className="icon icon-vertical-dots icon-variant-1" />
              </Button>
              <ul className="Dropdown__menu">
                {moveToTopOption &&
                  <li onClick={(e) => onMoveToTopClick(e)}>
                    <i className="icon icon-to-top" />
                    {t('component.reportingCard.dropdown.moveToTop')}
                  </li>}
                {canDuplicateCard &&
                  <li onClick={(e) => onDuplicateCardClick(e)}>
                    <i className="icon icon-layers" />
                    {t('component.reportingCard.dropdown.duplicate')}
                  </li>}
                {unclusterOption &&
                  <li onClick={(e) => onUnclusterClick(e)}>
                    <i className="icon icon-redirect" />
                    {t('component.reportingCard.dropdown.unclusterOption')}
                  </li>}
                {canFlagCard &&
                  <li onClick={(e) => onToggleFlag(e)}>
                    <i className="icon icon-flag" />
                    {flagsCount > 0 && t('component.reportingCard.dropdown.flag-remove')}
                    {flagsCount <= 0 && t('component.reportingCard.dropdown.flag')}
                  </li>}
                {canDeleteCard &&
                  <li onClick={(e) => onDeleteClick(e, answer)}>
                    <i className="icon icon-trash" />
                    {t('component.reportingCard.dropdown.delete')}
                  </li>}
              </ul>
            </div>}
        </div>
      </div>
      {
        isCluster && amount > 1 && (
          <div className="ReportingCardStack__stack" />
        )
      }
    </div>
  );
};

export default ReportingCardStack;
