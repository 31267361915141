import React from 'react';
import { useSelector } from 'react-redux';

import ProjectFlowRoundTypeQuestionV3Stimulus from './Stimulus';
import ProjectFlowRoundTypeQuestionV3Stacking from './Stacking';

import './style.scss';

const ProjectFlowRoundTypeQuestionV3 = ({ isPreview, round, roundSubmitSuccess }) => {
  const stimulus = round.getIn(['stimuli', '0'], null);
  const userHasCompletedStimulus = useSelector((state) => state.getIn(['project', 'currentRound', 'stimuli', 0, 'current_user_completed_stimulus'], false));

  const QUESTION_ROUND_STATES = {
    STIMULUS: 'STIMULUS',
    STACKING: 'STACKING',
  };

  const currentRoundQuestionState = userHasCompletedStimulus ? QUESTION_ROUND_STATES.STACKING : QUESTION_ROUND_STATES.STIMULUS;

  /**
   * Based on the current state of the question round
   * load either the stacking component or stimulus component (where questions can be answered)
   * @returns {JSX.Element} The component to render based on the current state of the question round
   */
  const renderRoundState = () => {
    if (isPreview) {
      return (
        <ProjectFlowRoundTypeQuestionV3Stimulus
          isPreview={isPreview}
          stimulus={stimulus}
          onPreviewComplete={roundSubmitSuccess}
        />
      );
    }

    switch (currentRoundQuestionState) {
      case QUESTION_ROUND_STATES.STACKING: {
        return (
          <ProjectFlowRoundTypeQuestionV3Stacking
            stackingComplete={roundSubmitSuccess}
          />
        );
      }
      case QUESTION_ROUND_STATES.STIMULUS:
      default: {
        return (
          <ProjectFlowRoundTypeQuestionV3Stimulus
            isPreview={isPreview}
            stimulus={stimulus}
          />
        );
      }
    }

  };
  return (
    <div className="ProjectFlowRoundTypeQuestionV3">
      {renderRoundState()}
    </div>
  );
};

export default ProjectFlowRoundTypeQuestionV3;
