import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Button } from '@uncinc/uncinc-react-kitchen-sink';

import { FieldFileUpload, MarkdownEditor } from '../../../../components';
import { TEXT_INPUT_MAX_LENGTH } from '../../../../config/constants';
import { addQuestionToRound, uploadImage } from '../../../../actions/managing';
import { setGlobalMessage } from '../../../../actions/messages';
import { hideModal as hideModalAction } from '../../../../actions/UI';

import './style.scss';

const FormAddQuestion = (props) => {
  const { t } = useTranslation();
  const { contentData } = props;
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      question: '',
      description: '',
      image_data: '',
      image_name: '',
    },
    validationSchema: Yup.object().shape({
      question: Yup
        .string()
        .required(t('admin.project-overview.question_edit.question_required')),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      const projectValuesFiltered = Object
        .entries(values)
        .filter(([key, value]) => !key.startsWith('image'));

      const projectValues = Object.fromEntries(projectValuesFiltered);

      const uploadImageCallback = ({ id }) => {
        if (values.image_data) {
          dispatch(uploadImage({
            image: values.image_data,
            image_name: values.image_name,
            model: 'Stimulus',
            id,
          }));
        }
      };

      dispatch(addQuestionToRound(contentData.getIn(['projectSlug']), contentData.getIn(['projectRound']), projectValues, uploadImageCallback));
      dispatch(setGlobalMessage(t('admin.project-overview.question_edit.question_added')));
      dispatch(hideModalAction());
    },
  });

  return (
    <div className="FormAddQuestion">

      <form onSubmit={formik.handleSubmit}>
        <label htmlFor="question">
          <div className="FormAddQuestion__label">
            {t('admin.project-overview.question_edit.question')}
          </div>
          <input
            id="question"
            name="question"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.question}
            maxLength={TEXT_INPUT_MAX_LENGTH}
          />
        </label>
        <FieldFileUpload
          name="image-upload"
          label="Upload foto"
          types=".jpg,.jpeg,.png,capture=camera"
          fileName={formik.values.image_name}
          onSuccess={(fileName, fileData) => {
            formik.setFieldValue('image_data', fileData);
            formik.setFieldValue('image_name', fileName);
          }}
          onDelete={() => {
            formik.setFieldValue('image_data', '');
            formik.setFieldValue('image_name', '');
          }}
        />
        <label htmlFor="description_markdown">
          <div className="FormAddQuestion__label">{t('admin.project-overview.question_edit.description')}</div>
          <MarkdownEditor
            value={formik.values.description}
            id="description_markdown"
            name="description"
            onChange={(data) => formik.setFieldValue('description', data)}
          />
        </label>

        <label htmlFor="message_before">
          <div className="ProjectOverviewQuestionEdit__label">{t('admin.project-overview.question_edit.message_before')}</div>
          <MarkdownEditor
            value={formik.values.message_before}
            id="message_before_markdown"
            name="message_before"
            onChange={(data) => formik.setFieldValue('message_before', data)}
          />
        </label>

        <label htmlFor="message_after">
          <div className="ProjectOverviewQuestionEdit__label">{t('admin.project-overview.question_edit.message_after')}</div>
          <MarkdownEditor
            value={formik.values.message_after}
            id="message_after_markdown"
            name="message_after"
            onChange={(data) => formik.setFieldValue('message_after', data)}
          />
        </label>

        <Button type="submit" className="Button Button--primary Button--block">
          {t('admin.project-overview.question_edit.add')}
        </Button>
      </form>

    </div>
  );
};

export default FormAddQuestion;
