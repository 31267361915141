import { fromJS, List } from 'immutable';

import {
  PUBLIC_PROJECT_LOADED,
  PROJECT_LOADED,
  ANSWERS_LOADED,
  ANSWER_POSTED,
  SET_LOCAL_ROUND_COMPLETE,
  SET_ANSWER_PROCESSED,
  STIMULI_ACTIVE,
  ROUND_MARKED_COMPLETE,
  CURRENT_ROUND,
  CURRENT_ROUND_INDEX,
  SET_PROJECT_FLOW_PROGRESS,
  SET_CURRENT_ROUND_STIMULUS_ANSWERED,
  ACCESS_CODE_RESPONSE_SUCCESS,
  SET_LAST_ACCESS_CODE,
  CLEAR_LAST_ACCESS_CODE,
  ANSWERS_MERGED,
  REMOVE_ANSWER,
  PROJECT_REQUEST_FAILED,
  CLEAN_PROJECT_ACCESS_VALIDATION_STATUS,
} from '../../actions/project';

import {
  PROJECT_ACCESS_CODE_STATUS_SUCCESS,
  PROJECT_ACCESS_CODE_STATUS_ERROR,
} from '../../config/constants';

const initialState = fromJS({
  access_code_validation_status: '',
  name: '',
  description: '',
  media_subtitle: '',
  created_at: '',
  updated_at: '',
  rounds: [],
  currentRound: null,
});

export default function project(state = initialState, action = {}) {
  switch (action.type) {
    case PUBLIC_PROJECT_LOADED: {
      return state.merge(fromJS(action.data));
    }

    case PROJECT_LOADED: {
      return state
        .set('access_code_validation_status', PROJECT_ACCESS_CODE_STATUS_SUCCESS)
        .merge(fromJS(action.data));
    }

    case ANSWERS_LOADED: {
      return state.setIn(['currentRound', 'stimuli', 0, 'answers'], action.data);
    }

    case SET_LOCAL_ROUND_COMPLETE: {
      return state.setIn(['rounds', action.data, 'completed'], true);
    }

    case SET_ANSWER_PROCESSED: {
      const answers = state.getIn(['currentRound', 'stimuli', 0, 'answers', 'user_answers'], List());
      const answerIndex = answers.findIndex((answer) => answer.get('id') === action.data);
      return state.setIn(['currentRound', 'stimuli', 0, 'answers', 'user_answers', answerIndex, 'processed'], true);
    }

    case ANSWERS_MERGED: {
      const round = state.getIn(['roundIndexActive']);
      const stimuli = state.getIn(['stimuliIndexActive']);
      const answers = state.getIn(['rounds', round, 'stimuli', stimuli, 'answers'], List());
      const answerIndex = answers.findIndex((answer) => answer.get('cluster_id') === action.data.get('id'));

      return state.setIn(['rounds', round, 'stimuli', stimuli, 'answers', answerIndex, 'cluster'], action.data);
    }

    case REMOVE_ANSWER: {
      const round = state.getIn(['roundIndexActive']);
      const stimuli = state.getIn(['stimuliIndexActive']);
      const answers = state.getIn(['rounds', round, 'stimuli', stimuli, 'answers'], List());
      const answersFiltered = answers.filter((answer) => answer.get('cluster_id') !== action.data);

      return state.setIn(['rounds', round, 'stimuli', stimuli, 'answers'], answersFiltered);
    }

    case STIMULI_ACTIVE: {
      return state.setIn(['stimuliIndexActive'], action.data);
    }

    case SET_CURRENT_ROUND_STIMULUS_ANSWERED: {
      return state.setIn(['currentRound', 'stimuli', 0, 'current_user_completed_stimulus'], true);
    }

    case CURRENT_ROUND: {
      return state.setIn(['currentRound'], action.data);
    }

    case CURRENT_ROUND_INDEX: {
      return state.setIn(['currentRoundIndex'], action.data);
    }

    case ROUND_MARKED_COMPLETE: {
      return state.setIn(['lastRoundMarkedComplete'], action.data);
    }

    case ANSWER_POSTED: {
      return state.setIn(['lastPostedAnswer'], action.data);
    }

    case SET_LAST_ACCESS_CODE: {
      return state.setIn(['lastAccessCode'], action.data);
    }

    case SET_PROJECT_FLOW_PROGRESS: {
      return state.setIn(['progress'], action.data);
    }

    case CLEAR_LAST_ACCESS_CODE: {
      return state.setIn(['lastAccessCode'], null);
    }

    case ACCESS_CODE_RESPONSE_SUCCESS: {
      return state.set('access_code_validation_status', PROJECT_ACCESS_CODE_STATUS_SUCCESS);
    }

    case PROJECT_REQUEST_FAILED: {
      return state.set('access_code_validation_status', PROJECT_ACCESS_CODE_STATUS_ERROR);
    }

    case CLEAN_PROJECT_ACCESS_VALIDATION_STATUS: {
      return state.set('access_code_validation_status', '');
    }

    default:
      return state;
  }
}
