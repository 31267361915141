import { apiRequest } from '../api';

export const SURVEY_LOADED = 'SURVEY_LOADED';
export function surveyLoaded(data) {
  return {
    type: SURVEY_LOADED,
    data,
  };
}

export const MANAGING_REQUEST_FAILED = 'MANAGING_REQUEST_FAILED';
export function managingRequestFailed(data) {
  return {
    type: MANAGING_REQUEST_FAILED,
    errors: data,
  };
}

export const getSurvey = () => apiRequest('GET', '/surveys', null, surveyLoaded, managingRequestFailed);
export const sendSurvey = (surveyId, data, successCallback) => apiRequest('POST', `/surveys/${surveyId}/submissions`, data, null, managingRequestFailed, null, successCallback);

