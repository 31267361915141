import React, { useEffect, useState } from 'react';
import Immutable, { List } from 'immutable';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import Masonry from 'react-masonry-css';
import {
  HeaderFlow,
  ReportingCardStack,
} from '../../../components';

import { getProject, roundIndexActive, stimuliIndexActive, getProjectLabels, getCluster, clearCluster } from '../../../actions/managing';

import './style.scss';

const EditCluster = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { match: { params: { projectSlug, projectRound, stimuliId, clusterId } }, history } = props;

  const [bestAnswerInCluster, setBestAnswerInCluster] = useState(Immutable.Map());
  const [answersWithoutBest, setAnswersWithoutBest] = useState(List());

  const roundIndex = useSelector((state) => state.getIn(['managing', 'roundIndexActive'], null));
  const stimulusIndex = useSelector((state) => state.getIn(['managing', 'stimuliIndexActive'], null));
  const rounds = useSelector((state) => state.getIn(['managing', 'project', 'rounds'], List()));
  const stimuli = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli'], List()));
  const labels = useSelector((state) => state.getIn(['managing', 'project', 'labels'], List()));
  const currentCluster = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli', stimulusIndex, 'currentCluster'], null));

  const fetchCluster = () => {
    dispatch(getCluster(projectSlug, projectRound, stimuliId, clusterId));
  };

  const refreshCallback = () => {
    fetchCluster();
  };

  useEffect(() => {
    if (currentCluster) {
      const clusterAnswers = currentCluster.getIn(['answers'], List());
      const bestAnswerInCluster = clusterAnswers.find((answer) => answer.get('parent_id') === null) || Immutable.Map();
      const answersWithoutBest = clusterAnswers.filter((answer) => answer.get('parent_id') !== null) || List();

      setBestAnswerInCluster(bestAnswerInCluster);
      setAnswersWithoutBest(answersWithoutBest);
    }

  }, [currentCluster]);

  useEffect(() => {
    if (projectSlug &&
      projectRound &&
      stimuliId &&
      roundIndex !== null && roundIndex > -1 &&
      stimulusIndex !== null && stimulusIndex > -1) {
      fetchCluster();
    }
  }, [roundIndex, stimulusIndex]);

  useEffect(() => {
    if (stimuli && stimuli.size) {
      const index = stimuli.findIndex ? stimuli.findIndex((obj) => { return obj.get('id') === parseInt(stimuliId); }) : 0;
      dispatch(stimuliIndexActive(index));
    }
  }, [stimuli]);

  useEffect(() => {
    if (rounds && rounds.size) {
      const index = rounds.findIndex ? rounds.findIndex((obj) => { return obj.get('id') === parseInt(projectRound); }) : 0;
      dispatch(roundIndexActive(index));
    }
  }, [rounds]);

  useEffect(() => {
    if (projectSlug &&
      projectRound &&
      stimuliId
    ) {
      dispatch(clearCluster(projectSlug, projectRound, stimuliId));
      dispatch(getProject(projectSlug, () => {
        dispatch(getProjectLabels(projectSlug));
      }));
    }

  }, [projectSlug, projectRound, stimuliId]);

  const onReturn = () => history.push(`/projects/${projectSlug}/round/${projectRound}/stimuli/${stimuliId}/results`);

  return (
    <div className="EditCluster">
      <HeaderFlow
        hideProgress
        closeButton
        onClose={onReturn}
      />
      <Container>
        <div className="heading">
          <div>
            <h4>{t('managing.edit-cluster.cluster_description')}</h4>
            {bestAnswerInCluster.size > 0 &&
              <ReportingCardStack
                dark
                answer={bestAnswerInCluster}
                key={bestAnswerInCluster.get('answer', '')}
                title={bestAnswerInCluster.get('answer', '')}
                activeLabelId={bestAnswerInCluster.getIn(['cluster', 'label_id'], null)}
                labels={labels}
                projectSlug={projectSlug}
                projectRound={projectRound}
                stimuliId={stimuliId}
                flagOption
                refreshCallback={refreshCallback}
              />}
          </div>
          <div>
            <Button className="Button Button--primary" onClick={onReturn}>
              {t('managing.edit-cluster.return')}
              <div className="icon icon-cross icon-variant-1" />
            </Button>
          </div>
        </div>
        <hr />
        {answersWithoutBest.size > 0 &&
          <Masonry
            breakpointCols={{ default: 3, 700: 2, 500: 1 }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {answersWithoutBest.map((answer, i) => {
              const answerTitle = answer.get('answer');

              return (
                <ReportingCardStack
                  dark
                  showLabels={false}
                  answer={answer}
                  key={answer.get('id')}
                  title={answerTitle}
                  labels={labels}
                  projectSlug={projectSlug}
                  projectRound={projectRound}
                  stimuliId={stimuliId}
                  deleteOption
                  moveToTopOption
                  duplicateCardOption
                  unclusterOption
                  refreshCallback={refreshCallback}
                  flagOption
                />
              );
            })}
          </Masonry>}
      </Container>
    </div>
  );
};

export default EditCluster;
