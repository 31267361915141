import {
  Home,
  AccountLogin,
  AccountVerify,
  AccountOverview,
  AccountOverviewEdit,
  AccountOverviewAdd,
  AccountEdit,
  ProjectFlow,
  ProjectEntry,
  ClientOverview,
  ClientOverviewEdit,
  ProjectOverview,
  ProjectOverviewEdit,
  ProjectOverviewRoundEdit,
  ProjectOverviewQuestionEdit,
  ProjectResult,
  Survey,
  AdminProjectDetail,
  NotAvailable,
  EditCluster,
} from '../containers';

import {
  ROUTE_HOME,
  ROUTE_ACCOUNT_EDIT,
  ROUTE_ACCOUNT_LOGIN,
  ROUTE_ACCOUNT_VERIFY,
  ROUTE_ACCOUNT_OVERVIEW,
  ROUTE_ACCOUNT_OVERVIEW_EDIT,
  ROUTE_ACCOUNT_OVERVIEW_ADD,
  ROUTE_PROJECT_ENTRY,
  ROUTE_PROJECT_FLOW,
  ROUTE_CLIENT_OVERVIEW,
  ROUTE_CLIENT_OVERVIEW_ADD,
  ROUTE_CLIENT_OVERVIEW_EDIT,
  ROUTE_PROJECT_OVERVIEW,
  ROUTE_PROJECT_OVERVIEW_EDIT,
  ROUTE_PROJECT_OVERVIEW_ROUND_EDIT,
  ROUTE_PROJECT_OVERVIEW_QUESTION_EDIT,
  ROUTE_PROJECT_RESULTS,
  ROUTE_PROJECT_RESULTS_CLUSTER,
  ROUTE_PROJECT_OVERVIEW_ADD,
  ROUTE_PROJECT_PREVIEW_FLOW,
  ROUTE_ADMIN_PROJECT_DETAIL,
  ROUTE_ADMIN_PROJECT_DETAIL_SECTION,
  ROUTE_SURVEY,
  ROUTE_UNAUTHORIZED,
} from '../config/routes';

// Default routes
export const routes = [
  { route: ROUTE_HOME, component: Home },
  { route: ROUTE_ACCOUNT_LOGIN, component: AccountLogin },
  { route: ROUTE_ACCOUNT_VERIFY, component: AccountVerify },
  { route: ROUTE_PROJECT_ENTRY, component: ProjectEntry },
  { route: ROUTE_UNAUTHORIZED, component: NotAvailable },
];

// Logedin routes
export const protectedRoutes = [
  { route: ROUTE_PROJECT_FLOW, component: ProjectFlow },
  { route: ROUTE_ACCOUNT_EDIT, component: AccountEdit },
  { route: ROUTE_SURVEY, component: Survey },
];

// Facilitator routes
export const facilitatorRoutes = [
  // Project
  // Mag alleen eigen projecten zien!!
  { route: ROUTE_PROJECT_OVERVIEW, component: ProjectOverview },
  { route: ROUTE_PROJECT_OVERVIEW_EDIT, component: ProjectOverviewEdit },
  { route: ROUTE_PROJECT_OVERVIEW_ROUND_EDIT, component: ProjectOverviewRoundEdit },
  { route: ROUTE_PROJECT_OVERVIEW_QUESTION_EDIT, component: ProjectOverviewQuestionEdit },
  { route: ROUTE_PROJECT_RESULTS, component: ProjectResult },
  { route: ROUTE_PROJECT_RESULTS_CLUSTER, component: EditCluster },
];

// Project manager routes
export const managingRoutes = [
  { route: ROUTE_PROJECT_OVERVIEW_ADD, component: ProjectOverviewEdit },
  { route: ROUTE_PROJECT_OVERVIEW_EDIT, component: ProjectOverviewEdit },
  { route: ROUTE_PROJECT_OVERVIEW_ROUND_EDIT, component: ProjectOverviewRoundEdit },
  { route: ROUTE_PROJECT_OVERVIEW_QUESTION_EDIT, component: ProjectOverviewQuestionEdit },
  { route: ROUTE_PROJECT_RESULTS, component: ProjectResult },
  { route: ROUTE_ADMIN_PROJECT_DETAIL, component: AdminProjectDetail },
  { route: ROUTE_ADMIN_PROJECT_DETAIL_SECTION, component: AdminProjectDetail },
  { route: ROUTE_PROJECT_PREVIEW_FLOW, component: ProjectFlow },
];

// Admin routes
export const adminRoutes = [
  // Account
  { route: ROUTE_ACCOUNT_OVERVIEW, component: AccountOverview },
  { route: ROUTE_ACCOUNT_OVERVIEW_ADD, component: AccountOverviewAdd },
  { route: ROUTE_ACCOUNT_OVERVIEW_EDIT, component: AccountOverviewEdit },
  // Client
  { route: ROUTE_CLIENT_OVERVIEW, component: ClientOverview },
  { route: ROUTE_CLIENT_OVERVIEW_ADD, component: ClientOverviewEdit },
  { route: ROUTE_CLIENT_OVERVIEW_EDIT, component: ClientOverviewEdit },
];
