import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Immutable from 'immutable';
import { Button } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  updateRound,
  uploadImage,
  roundImageDeleted,
  addRoundToProject,
  deleteImage,
} from '../../../../../actions/managing';

import {
  ErrorMessage,
  FieldFileUpload,
  MarkdownEditor,
} from '../../../../../components';

import { TEXT_INPUT_MAX_LENGTH } from '../../../../../config/constants';
import { setGlobalMessage } from '../../../../../actions/messages';

import './style.scss';

const InformationFlowEdit = ({ round, setFirstRoundActive, redirectToPreviewRound, deleteProjectRound }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const id = round.get('id');
  const title = round.get('title');
  const link = round.get('link');
  const projectSlug = useSelector((state) => state.getIn(['managing', 'project', 'slug']), null);
  const description = round.get('description');
  const roundTypeId = round.getIn(['round_type', 'id']);
  const image = round.getIn(['media', 0], Immutable.List());
  const mediaSubtitle = round.get('media_subtitle');
  const isFirstRound = round.get('is_first_round', false);
  const isLastRound = round.get('is_last_round', false);

  const rounds = useSelector((state) => state.getIn(['managing', 'project', 'rounds'], Immutable.List()));
  const roundIndex = rounds.findIndex ? rounds.findIndex((obj) => { return obj.get('id') === parseInt(id); }) : 0;

  const imageDeleteSuccessCallback = () => {
    dispatch(roundImageDeleted(roundIndex));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: title,
      description: description || '',
      media_subtitle: mediaSubtitle || '',
      link: link || '',
      round_type_id: roundTypeId,
      image_data: '',
      image_name: image.get('file_name', ''),
    },
    validationSchema: Yup.object().shape({
      title: Yup
        .string()
        .required(t('admin.project-overview.round_edit.title_required')),
    }),

    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      // TODO - refactor this.
      const roundValuesFiltered = Object.entries(values).filter(([key, value]) => !key.startsWith('image'));
      const roundValues = Object.fromEntries(roundValuesFiltered);

      const updateRoundCallback = ({ id }) => {
        // Check if image needs to be uploaded.
        if (values.image_data) {
          dispatch(uploadImage({
            image: values.image_data,
            image_name: values.image_name,
            model: 'Round',
            id,
          }));
        }
        // Show message
        dispatch(setGlobalMessage('Informatie block is succesvol opgeslagen'));
      };

      if (id) {
        dispatch(updateRound(roundValues, projectSlug, id, updateRoundCallback));
      } else {
        dispatch(addRoundToProject(projectSlug, roundValues, () => {
          dispatch(setGlobalMessage('Informatie block is succesvol aangemaakt'));
          setFirstRoundActive();
        }));
      }
    },
  });

  return (
    <div className="AdminFlowEdit AdminInformationFlowEdit">
      <div className="AdminFlowEdit__header">
        {isFirstRound ? (
          <h4>{t('admin.project-detail.flow.form_title_start')}</h4>
        ) : isLastRound ? (
          <h4>{t('admin.project-detail.flow.form_title_end')}</h4>
        ) : (
          <h4>{t('admin.project-detail.flow.form_title')}</h4>
        )}
      </div>
      {/* Form - Start */}
      <form onSubmit={formik.handleSubmit}>

        {/* Field - Title */}
        <label htmlFor="title">
          <div className="ProjectOverviewRoundEdit__label">{t('admin.project-overview.round_edit.round_title')}</div>
          <p className="small">{t('admin.project-overview.round_edit.round_title_description')}</p>
          <input
            id="title"
            name="title"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.title}
            maxLength={TEXT_INPUT_MAX_LENGTH}
          />
        </label>
        {formik.errors.title ? (
          <ErrorMessage message={formik.errors.title} />
        ) : null}

        {id ? (
          <label htmlFor="image-upload">
            <div className="ProjectOverviewRoundEdit__label">{t('project.overview.form.image_upload')}</div>
            <p className="small">{t('project.overview.form.image_upload_description')}</p>
            {/* Field - Image */}
            <FieldFileUpload
              name="image-upload"
              types=".jpg,.jpeg,.png,capture=camera"
              fileName={formik.values.image_name}
              preview={<img src={image.get('original_url', '')} alt={image.get('file_name', '')} />}
              onSuccess={(fileName, fileData) => {
                formik.setFieldValue('image_data', fileData);
                formik.setFieldValue('image_name', fileName);
              }}
              onDelete={() => {
                formik.setFieldValue('image_data', '');
                formik.setFieldValue('image_name', '');

                // Only delete the image from the backend when we already have one in the state.
                if (image.size > 0) {
                  dispatch(deleteImage(image.get('id', null), imageDeleteSuccessCallback()));
                }
              }}
            />

            {(image.get('file_name', null) || (formik.values.image_data)) && (
              <>
                {/* Field - subtitle */}
                <label htmlFor="media_subtitle">
                  <div className="row">
                    <div className="col-12">
                      {t('project.overview.form.image_subtitle')}
                    </div>
                    <div className="col-12">
                      <input
                        id="media_subtitle"
                        name="media_subtitle"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.media_subtitle}
                        maxLength={TEXT_INPUT_MAX_LENGTH}
                      />
                    </div>
                  </div>
                </label>
                {formik.errors.media_subtitle ? (
                  <ErrorMessage message={formik.errors.media_subtitle} />
                ) : null}
              </>
            )}
          </label>
        ) : (
          <>
            {/* TODO replace with actual message */}
            Note: Afbeelding kan pas na het opslaan worden toegevoegd.
          </>
        )}

        {/* Field - Description */}
        <label htmlFor="description">
          <div className="ProjectOverviewRoundEdit__label">{t('admin.project-overview.round_edit.round_description')}</div>
          <p className="small">
            {t('admin.project-overview.round_edit.round_description_label')}
          </p>
          <MarkdownEditor
            value={formik.values.description}
            id="description_markdown"
            name="description"
            onChange={(data) => formik.setFieldValue('description', data)}
          />
        </label>

        {/* Field - Link */}
        {isLastRound && (
          <>
            <label htmlFor="link">
              <div className="row">
                <div className="col-12">
                  <div className="ProjectOverviewRoundEdit__label">
                    {t('admin.project-overview.round_edit.round_link')}
                  </div>
                  <p className="small">
                    {t('admin.project-overview.round_edit.round_link_label')}
                  </p>
                </div>
                <div className="col-12">
                  <input
                    id="link"
                    name="link"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.link}
                    maxLength={TEXT_INPUT_MAX_LENGTH}
                  />
                </div>
              </div>
            </label>
            {formik.errors.link ? (
              <ErrorMessage message={formik.errors.link} />
            ) : null}
          </>
        )}

        <div className="AdminFlowEdit__buttons">
          {deleteProjectRound &&
            <div onClick={deleteProjectRound}>
              {t('admin.project-overview.round_edit.delete_question')}
            </div>}
          {id && (
            <Button className="Button--inverted" onClick={redirectToPreviewRound}>
              {t('admin.project-overview.round_edit.preview')}
              <i className="icon icon-eye-open" />
            </Button>
          )}
          <Button type="submit" className="Button Button--primary ProjectOverviewRoundEdit__Button Button-send-form">
            {t('project.overview.form.save')}
            <i className="icon icon-save icon-variant-1" />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InformationFlowEdit;
