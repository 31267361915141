import React, { useState } from 'react';
import c from 'classnames';

import { MAX_ACCESS_CODE_LENGTH } from '../../../config/constants';

import './style.scss';

const FieldAccessCode = ({ name, label, disabled, errorLabel, error, className, onChange }) => {
  const [code, setCode] = useState('');

  const classNames = c('FieldAccessCode', className, {
    'FieldAccessCode--error': error,
  });

  return (
    <label className={classNames} htmlFor={`${name}_0`}>
      {label && (
        <div className="FieldAccessCode__label">{label}</div>
      )}
      <div className="FieldAccessCode__inputs">
        {[...Array(MAX_ACCESS_CODE_LENGTH)].map((_, index) => {
          const fieldName = `${name}_${index}`;

          return (
            <input
              key={fieldName}
              type="number"
              id={fieldName}
              name={fieldName}
              min={0}
              max={9}
              disabled={disabled}
              onChange={(evt) => {
                const { value } = evt.target;
                if (value) {
                  const nextInput = document.getElementById(`${name}_${index + 1}`);

                  onChange(code + value);
                  setCode((oldCode) => oldCode + value);

                  // Set focus on the next input when a value is filled in.
                  if (nextInput) {
                    evt.currentTarget.blur();
                    nextInput.focus();
                  }
                }
              }}
              onKeyUp={(evt) => {
                // Set focus on previous input when pressing backspace.
                if (evt.key === 'Backspace') {
                  const prevInput = document.getElementById(`${name}_${index - 1}`);
                  const currentInput = document.getElementById(`${name}_${index}`);
                  const isLastInput = index === MAX_ACCESS_CODE_LENGTH - 1;
                  const isMaxCode = code.toString().length === MAX_ACCESS_CODE_LENGTH;

                  onChange(code.slice(0, -1));
                  setCode((oldCode) => oldCode.slice(0, -1));

                  if (isLastInput && !isMaxCode) {
                    prevInput.focus();
                    prevInput.value = '';
                  } else if (isLastInput) {
                    currentInput.value = '';
                  } else if (prevInput) {
                    evt.currentTarget.blur();
                    prevInput.focus();
                    prevInput.value = '';
                  }
                }
              }}
            />
          );
        })}
      </div>
      {error && (
        <p className="FieldAccessCode__error">{errorLabel}</p>
      )}
    </label>
  );
};

export default FieldAccessCode;
