import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import './style.scss';

const HeaderAdmin = ({
  onBack,
}) => {
  const { t } = useTranslation();

  return (
    <div className="HeaderAdmin">
      <div className="HeaderAdmin__segment">
        <div className="HeaderAdmin__Button" onClick={onBack}>
          <Button className="Button Button--icon">
            <div className="icon icon-arrow-right icon-orientation-left" />
          </Button>
          <span className="HeaderAdmin__ButtonLabel">{t('navigation.back')}</span>
        </div>
      </div>
      <div className="HeaderAdmin__segment" />
    </div>
  );

};
export default HeaderAdmin;
