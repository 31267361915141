import React from 'react';
import { NavigationScroll, Container } from '@uncinc/uncinc-react-kitchen-sink';
import PanelLogo from '../../assets/images/panel-logo-dark.svg';
import UserMenu from '../UserMenu';

import './style.scss';

const HeaderLogoIcon = ({ profileButton }) => {

  return (
    <NavigationScroll>
      <div className="HeaderLogoIcon__background">
        <Container>
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <div className="HeaderLogoIcon">
                <div className="HeaderLogoIcon__segment">
                  <img src={PanelLogo} className="HeaderLogoIcon__logo" alt="" />
                </div>
                <div className="HeaderLogoIcon__segment">
                  {profileButton && (
                    <UserMenu isParticipantMenu className="HeaderLogoIcon__ProfileButton" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </NavigationScroll>
  );

};
export default HeaderLogoIcon;
