import Immutable from 'immutable';
import Cookies from 'js-cookie';

import {
  USER_PROFILE_UPDATED,
  USER_PROFILE_LOADED,
  SET_ACCESS_TOKEN_EXPIRED,
  SET_ACCESS_TOKEN_VALID,
  USER_PROFILE_DELETED,
  SET_PASSWORDLESS_LINK_SENT,
  SET_PASSWORD_RESET_LINK_SENT,
  SET_PASSWORD_RESET_SUCCESS,
} from '../../actions/user';

const initialState = Immutable.fromJS({
  name: '',
  email: '',
  managed: false,
  created_at: '',
  updated_at: '',
  token: Cookies.get('token') ? Cookies.get('token') : false,
  id: null,
  passwordless_link_sent: false,
  password_reset_link_sent: false,
  password_reset_success: false,
  roles: [],
});

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case USER_PROFILE_UPDATED: {
      return Immutable.fromJS(action.data);
    }

    case USER_PROFILE_LOADED: {
      return Immutable.fromJS(action.data);
    }

    case SET_ACCESS_TOKEN_EXPIRED: {
      return initialState.set('token', false);
    }

    case SET_ACCESS_TOKEN_VALID: {
      return state.set('access_token_expired', false);
    }

    case SET_PASSWORDLESS_LINK_SENT: {
      return state.set('passwordless_link_sent', action.value);
    }

    case SET_PASSWORD_RESET_LINK_SENT: {
      return state.set('password_reset_link_sent', action.value);
    }

    case SET_PASSWORD_RESET_SUCCESS: {
      return state.set('password_reset_success', action.value);
    }

    case USER_PROFILE_DELETED: {
      return initialState;
    }


    default:
      return state;
  }
}
