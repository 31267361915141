import { ERROR_MESSAGES, FALLBACK_ERROR } from "../config/constants";

export const getErrorMessage = (code) => {
 if (ERROR_MESSAGES[code]) return ERROR_MESSAGES[code];

 return FALLBACK_ERROR;
};

export default {
    getErrorMessage
}