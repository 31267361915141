import { apiRequest } from '../api';

export const MANAGING_ACCOUNTS_LOADED = 'MANAGING_ACCOUNTS_LOADED';
export function accountsLoaded(data) {
  return {
    type: MANAGING_ACCOUNTS_LOADED,
    data,
  };
}

export const MANAGING_ACCOUNT_LOADED = 'MANAGING_ACCOUNT_LOADED';
export function accountLoaded(data) {
  return {
    type: MANAGING_ACCOUNT_LOADED,
    data,
  };
}

export const MANAGING_ACCOUNT_ADDED = 'MANAGING_ACCOUNT_ADDED';
export function accountAdded(data) {
  return {
    type: MANAGING_ACCOUNT_ADDED,
    data,
  };
}

export const MANAGING_CLIENTS_LOADED = 'MANAGING_CLIENTS_LOADED';
export function clientsLoaded(data) {
  return {
    type: MANAGING_CLIENTS_LOADED,
    data,
  };
}

export const MANAGING_CLIENT_LOADED = 'MANAGING_CLIENT_LOADED';
export function clientLoaded(data) {
  return {
    type: MANAGING_CLIENT_LOADED,
    data,
  };
}

export const MANAGING_CLIENT_SAVED = 'MANAGING_CLIENT_SAVED';
export function clientSaved(data) {
  return {
    type: MANAGING_CLIENT_SAVED,
    data,
  };
}

export const MANAGING_PROJECTS_LOADED = 'MANAGING_PROJECTS_LOADED';
export function projectsLoaded(data) {
  return {
    type: MANAGING_PROJECTS_LOADED,
    data,
  };
}

export const MANAGING_PROJECT_LOADED = 'MANAGING_PROJECT_LOADED';
export function projectLoaded(data) {
  return {
    type: MANAGING_PROJECT_LOADED,
    data,
  };
}

export const MANAGING_STATISTICS_LOADED = 'MANAGING_STATISTICS_LOADED';
export function statisticsLoaded(data) {
  return {
    type: MANAGING_STATISTICS_LOADED,
    data,
  };
}

export const MANAGING_PROJECT_EXPORT_LOADED = 'MANAGING_PROJECT_EXPORT_LOADED';
export function projectExportLoaded(data) {
  return {
    type: MANAGING_PROJECT_EXPORT_LOADED,
    data,
  };
}

export const CLEAR_PROJECT_EXPORT = 'CLEAR_PROJECT_EXPORT';
export function clearProjectExport(data) {
  return {
    type: CLEAR_PROJECT_EXPORT,
  };
}

export const MANAGING_PROJECT_SAVED = 'MANAGING_PROJECT_SAVED';
export function projectSaved(data) {
  return {
    type: MANAGING_PROJECT_SAVED,
    data,
  };
}

export const MANAGING_ROUND_ACTIVE = 'MANAGING_ROUND_ACTIVE';
export function roundIndexActive(data) {
  return {
    type: MANAGING_ROUND_ACTIVE,
    data,
  };
}

export const MANAGING_STIMULI_ACTIVE = 'MANAGING_STIMULI_ACTIVE';
export function stimuliIndexActive(data) {
  return {
    type: MANAGING_STIMULI_ACTIVE,
    data,
  };
}

export const MANAGING_PROJECT_ANSWERS_LOADED = 'MANAGING_PROJECT_ANSWERS_LOADED';
export function answersLoaded(data) {
  return {
    type: MANAGING_PROJECT_ANSWERS_LOADED,
    data,
  };
}

export const MANAGING_PROJECT_SEARCH_LOADED = 'MANAGING_PROJECT_SEARCH_LOADED';
export function searchLoaded(data) {
  return {
    type: MANAGING_PROJECT_SEARCH_LOADED,
    data,
  };
}

export const MANAGING_PROJECT_CLUSTER_LOADED = 'MANAGING_PROJECT_CLUSTER_LOADED';
export function clusterLoaded(data) {
  return {
    type: MANAGING_PROJECT_CLUSTER_LOADED,
    data,
  };
}

export const MANAGING_PROJECT_SEARCH_FILTERS_LOADED = "MANAGING_PROJECT_SEARCH_FILTERS_LOADED";
export function searchFiltersLoaded(data) {
  return {
    type: MANAGING_PROJECT_SEARCH_FILTERS_LOADED,
    data,
  };
};

export const MANAGING_PROJECT_QUESTIONS_LOADED = 'MANAGING_PROJECT_QUESTIONS_LOADED';
export function questionsLoaded(data) {
  return {
    type: MANAGING_PROJECT_QUESTIONS_LOADED,
    data,
  };
}

export const MANAGING_PROJECT_ROUNDS_LOADED = 'MANAGING_PROJECT_ROUNDS_LOADED';
export function roundsLoaded(data) {
  return {
    type: MANAGING_PROJECT_ROUNDS_LOADED,
    data,
  };
}

export const MANAGING_REQUEST_FAILED = 'MANAGING_REQUEST_FAILED';
export function managingRequestFailed(data) {
  return {
    type: MANAGING_REQUEST_FAILED,
    errors: data,
  };
}

export const PROJECT_LABELS_LOADED = 'PROJECT_LABELS_LOADED';
export function projectLabelsLoaded(data) {
  return {
    type: PROJECT_LABELS_LOADED,
    data,
  };
}

export const MANAGING_ROLES_LOADED = 'MANAGING_ROLES_LOADED';
export function rolesLoaded(data) {
  return {
    type: MANAGING_ROLES_LOADED,
    data,
  };
}


export const MANAGING_ANSWER_UPDATED = 'MANAGING_ANSWER_UPDATED';
export function answerUpdated(data) {
  return {
    type: MANAGING_ANSWER_UPDATED,
    data,
  };
}
export const MANAGING_CLUSTER_UPDATED = 'MANAGING_CLUSTER_UPDATED';
export function clusterUpdated(data) {
  return {
    type: MANAGING_CLUSTER_UPDATED,
    data,
  };
}

export const MANAGING_ROLE_ADDED = 'MANAGING_ROLE_ADDED';
export function roleAdded(data) {
  return {
    type: MANAGING_ROLE_ADDED,
    data,
  };
}

export const MANAGING_ROLE_REMOVED = 'MANAGING_ROLE_REMOVED';
export function roleRemoved(data) {
  return {
    type: MANAGING_ROLE_REMOVED,
    data,
  };
}

export const MANAGING_CLEAR_ACCOUNT = 'MANAGING_CLEAR_ACCOUNT';
export function clearAccount() {
  return {
    type: MANAGING_CLEAR_ACCOUNT,
  };
}

export const PROJECT_IMAGE_DELETED = 'PROJECT_IMAGE_DELETED';
export function projectImageDeleted() {
  return {
    type: PROJECT_IMAGE_DELETED,
  };
}

export const ROUND_IMAGE_DELETED = 'ROUND_IMAGE_DELETED';
export function roundImageDeleted(data) {
  return {
    type: ROUND_IMAGE_DELETED,
    data,
  };
}

export const QUESTION_IMAGE_DELETED = 'QUESTION_IMAGE_DELETED';
export function questionImageDeleted(data) {
  return {
    type: QUESTION_IMAGE_DELETED,
    data,
  };
}

export const MANAGING_CLEAR_ROUND_STIMULI = 'MANAGING_CLEAR_ROUND_STIMULI';
export function clearRoundStimuli(round) {
  const data = { round };
  return {
    type: MANAGING_CLEAR_ROUND_STIMULI,
    data,
  };
}

export const MANAGING_CLEAR_CURRENT_CLUSTER = 'MANAGING_CLEAR_CURRENT_CLUSTER';
export function clearCluster(projectName, round, stimuliId) {
  const data = { projectName, round, stimuliId };
  return {
    type: MANAGING_CLEAR_CURRENT_CLUSTER,
    data,
  };
}

// Actions for managing accounts
export const getAccounts = (page = 1) => apiRequest(
  'GET',
  `/users?page=${page}`,
  null,
  accountsLoaded,
  managingRequestFailed,
);
export const getAccount = (accountId) => apiRequest(
  'GET',
  `/users/${accountId}`,
  null,
  accountLoaded,
  managingRequestFailed);
export const updateAccount = (
  data,
  accountId,
  successCallback,
) => apiRequest(
  'PUT',
  `/users/${accountId}`,
  data,
  accountLoaded,
  managingRequestFailed,
  null,
  successCallback,
);
export const deleteAccount = (accountId) => apiRequest(
  'POST',
  `/users/${accountId}`,
  { _method: 'DELETE' },
  getAccounts,
  managingRequestFailed,
);
export const addAccount = (data) => apiRequest('POST', '/users', data, accountAdded, managingRequestFailed);

// Actions for managing clients
export const getClients = (page = 1) => apiRequest(
  'GET',
  `/clients?page=${page}`,
  null,
  clientsLoaded,
  managingRequestFailed,
);
export const getClient = (clientId) => apiRequest(
  'GET',
  `/clients/${clientId}`,
  null,
  clientLoaded,
  managingRequestFailed,
);
export const createClient = (data, successCallback) => apiRequest(
  'POST',
  '/clients',
  data,
  clientSaved,
  managingRequestFailed,
  null,
  successCallback,
);
export const updateClient = (data, clientId, successCallback) => apiRequest(
  'PUT',
  `/clients/${clientId}`,
  data,
  clientLoaded,
  managingRequestFailed,
  null,
  successCallback,
);
export const deleteClient = (clientId) => apiRequest(
  'POST',
  `/clients/${clientId}`,
  { _method: 'DELETE' },
  getClients,
  managingRequestFailed,
);

// Actions for managing projects
export const getProjects = (page = 1) => apiRequest(
  'GET',
  `/projects?page=${page}`,
  null,
  projectsLoaded,
  managingRequestFailed,
);
export const getProject = (slug, callback = false) => apiRequest(
  'GET',
  `/projects/${slug}`,
  null,
  projectLoaded,
  managingRequestFailed,
  null,
  callback,
);

export const getProjectExport = (projectSlug) => apiRequest(
  'GET',
  `/reporting/projects/${projectSlug}/export`,
  null,
  projectExportLoaded,
  managingRequestFailed,
);

export const getProjectRoundExport = (projectSlug, roundId) => apiRequest(
  'GET',
  `/reporting/projects/${projectSlug}/rounds/${roundId}/export`,
  null,
  projectExportLoaded,
  managingRequestFailed,
);

export const createProject = (data, successCallback) => apiRequest(
  'POST',
  '/projects',
  data,
  projectSaved,
  managingRequestFailed,
  null,
  successCallback,
);
export const updateProject = (data, slug, successCallback) => apiRequest(
  'PUT',
  `/projects/${slug}`,
  data,
  projectLoaded,
  managingRequestFailed,
  null,
  successCallback,
);
export const deleteProject = (slug) => apiRequest(
  'POST',
  `/projects/${slug}`,
  { _method: 'DELETE' },
  getProjects,
  managingRequestFailed,
);

export const getStatisticsForRound = (projectSlug, roundId) => apiRequest(
  'GET',
  `/reporting/projects/${projectSlug}/rounds/${roundId}/statistics`,
  null,
  statisticsLoaded,
  managingRequestFailed,
);

export const getAnswers = (projectName, round, stimuliId) => apiRequest(
  'GET',
  `/reporting/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}/answers`,
  null,
  answersLoaded,
  managingRequestFailed,
);

export const getSearchFilters = (projectName, round) => apiRequest(
  'POST',
  `/projects/${projectName}/rounds/${round}/search`,
  {
    query: '',
    filters: { labels: [], flagged: false },
    sort: { field: 'answers_count', option: 'desc' },
  },
  searchFiltersLoaded,
  managingRequestFailed,
);

export const searchAnswers = (projectName, round,
  searchBody = {
    query: '',
    filters: { labels: [], flagged: false },
    sort: { field: 'answers_count', option: 'desc' }
  }
) => apiRequest(
  'POST',
  `/projects/${projectName}/rounds/${round}/search`,
  searchBody,
  searchLoaded,
  managingRequestFailed,
);

export const getCluster = (projectName, round, stimuliId, clusterId) => apiRequest(
  'GET',
  `/reporting/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}/clusters/${clusterId}`,
  null,
  clusterLoaded,
  managingRequestFailed,
);

export const getRounds = (projectName) => apiRequest(
  'GET',
  `/projects/${projectName}/rounds`,
  null,
  roundsLoaded,
  managingRequestFailed,
);

export const autoclusterAnswers = (projectName, round, stimuliId, successHandler, errorHandler, successCallback) => apiRequest(
  'POST',
  `/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}/autocluster`,
  null,
  successHandler,
  errorHandler,
  null,
  successCallback,
);

export const updateRound = (data, projectName, round, successCallback) => apiRequest(
  'PUT',
  `/projects/${projectName}/rounds/${round}`,
  data,
  () => getRounds(projectName),
  managingRequestFailed,
  null,
  successCallback,
);
export const updateRoundWeights = (data, projectName, successCallback) => apiRequest(
  'POST',
  `/projects/${projectName}/rounds/update-weights`,
  data,
  () => getRounds(projectName),
  managingRequestFailed,
  null,
  successCallback,
);
export const deleteRound = (projectName, round) => apiRequest(
  'POST',
  `/projects/${projectName}/rounds/${round}`,
  { _method: 'DELETE' },
  () => getRounds(projectName),
  managingRequestFailed,
);
export const updateQuestion = (data, projectName, round, stimuli, successCallback) => apiRequest(
  'PUT',
  `/projects/${projectName}/rounds/${round}/stimuli/${stimuli}`,
  data,
  () => getRounds(projectName),
  managingRequestFailed,
  null,
  successCallback,
);
export const deleteQuestion = (projectName, round, stimuliId) => apiRequest(
  'DELETE',
  `/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}`,
  {},
  questionsLoaded,
  managingRequestFailed,
);
export const addRoundToProject = (projectName, data, successCallback) => apiRequest(
  'POST',
  `/projects/${projectName}/rounds`,
  data,
  () => getRounds(projectName),
  managingRequestFailed,
  null,
  successCallback,
);
export const addQuestionToRound = (projectName, projectRound, data, successCallback) => apiRequest(
  'POST',
  `/projects/${projectName}/rounds/${projectRound}/stimuli`,
  data,
  () => getRounds(projectName),
  managingRequestFailed,
  null,
  successCallback,
);

export const getProjectLabels = (projectSlug) => apiRequest(
  'GET',
  `/projects/${projectSlug}/labels`,
  null,
  projectLabelsLoaded,
  managingRequestFailed,
);

export const getProjectLabelInUse = (projectSlug, labelId, successCallback) => apiRequest(
  'GET',
  `/projects/${projectSlug}/labels/${labelId}/usage`,
  null,
  null,
  managingRequestFailed,
  null,
  successCallback,
);

export const createProjectLabel = (projectSlug, data, successCallback) => apiRequest(
  'POST',
  `/projects/${projectSlug}/labels`,
  data,
  () => getProjectLabels(projectSlug),
  managingRequestFailed,
  null,
  successCallback,
);

export const editProjectLabel = (projectSlug, labelId, data, successCallback) => apiRequest(
  'PUT',
  `/projects/${projectSlug}/labels/${labelId}`,
  data,
  () => getProjectLabels(projectSlug),
  managingRequestFailed,
  null,
  successCallback,
);

export const deleteProjectLabel = (projectSlug, labelId, successCallback) => apiRequest(
  'DELETE',
  `/projects/${projectSlug}/labels/${labelId}`,
  null,
  () => getProjectLabels(projectSlug),
  managingRequestFailed,
  null,
  successCallback,
);

export const updateAnswer = (projectName, round, stimuliId, answerId, answer, successCallback) => apiRequest(
  'PUT',
  `/reporting/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}/answers/${answerId}`,
  { answer },
  null,
  managingRequestFailed,
  null,
  successCallback
);

export const updateCluster = (projectName, round, stimuliId, clusterId, labelId, successCallback) => apiRequest(
  'PUT',
  `/reporting/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}/clusters/${clusterId}`,
  { label_id: labelId },
  null,
  managingRequestFailed,
  null,
  successCallback,
);

export const deleteAnswer = (projectName, round, stimuliId, answerId, successCallback) => apiRequest(
  'DELETE',
  `/reporting/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}/answers/${answerId}`,
  {},
  answersLoaded,
  managingRequestFailed,
  null,
  successCallback
);
export const deleteCluster = (projectName, round, stimuliId, clusterId, successCallback) => apiRequest(
  'DELETE',
  `/reporting/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}/clusters/${clusterId}`,
  {},
  answersLoaded,
  managingRequestFailed,
  null,
  successCallback
);

export const duplicateAnswer = (projectName, round, stimuliId, answerId, successCallback) => apiRequest(
  'POST',
  `/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}/answers/${answerId}/duplicate`,
  {},
  null,
  managingRequestFailed,
  null,
  successCallback,
);

export const getRoles = () => apiRequest('GET', '/roles', null, rolesLoaded, managingRequestFailed);
export const addRole = (userId, roleId) => apiRequest(
  'PUT',
  `/users/${userId}/roles/${roleId}`,
  {},
  roleAdded,
  managingRequestFailed,
);
export const removeRole = (userId, roleId) => apiRequest(
  'DELETE',
  `/users/${userId}/roles/${roleId}`,
  null,
  roleRemoved,
  managingRequestFailed,
);

export const assignFacilitator = (userId, projectId) => apiRequest(
  'PUT',
  `/users/${userId}/projects/${projectId}`,
  null,
  null,
  managingRequestFailed,
);
export const removeFacilitator = (userId, projectId) => apiRequest(
  'DELETE',
  `/users/${userId}/projects/${projectId}`,
  null,
  null,
  managingRequestFailed,
);

// Action for managing images
export const uploadImage = (imageData, successCallback) => {
  if (imageData.image) {
    return apiRequest(
      'POST',
      '/images',
      imageData,
      null,
      null,
      null,
      successCallback,
    );
  }

  successCallback();
  return {
    type: null,
  };
};

export const deleteImage = (id, successCallback) => apiRequest(
  'DELETE',
  `/images/${id}`,
  null,
  successCallback,
);

// Actions for managing project translations
export const uploadProjectTranslations = (data, successCallback) => apiRequest(
  'POST',
  '/project_translations',
  data,
  null,
  null,
  null,
  successCallback,
);

export const mergeMultipleClusters = (projectName, round, data, successCallback) => apiRequest(
  'POST',
  `/projects/${projectName}/rounds/${round}/clusters/merge-multiple`,
  data,
  null,
  managingRequestFailed,
  null,
  successCallback,
);

export const destroyAllFlags = (projectName, round, stimuliId, answerId, successCallback) => apiRequest(
  'DELETE',
  `/reporting/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}/answers/${answerId}/flags`,
  null,
  null,
  managingRequestFailed,
  null,
  successCallback,
);

export const storeFlag = (projectName, round, stimuliId, answerId, successCallback) => apiRequest(
  'POST',
  `/reporting/projects/${projectName}/rounds/${round}/stimuli/${stimuliId}/answers/${answerId}/flags`,
  null,
  null,
  managingRequestFailed,
  null,
  successCallback,
);
