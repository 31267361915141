import { fromJS } from 'immutable';
import { DEFAULT_LANGUAGE } from '../../config/constants';

import {
  LANGUAGE_SET,
  DISABLE_MAINTENANCE_MODE,
  ENABLE_MAINTENANCE_MODE,
  SHOW_MOBILE_MENU,
  HIDE_MOBILE_MENU,
  MODAL_SHOW,
  MODAL_HIDE,
} from '../../actions/UI';

const initialState = fromJS({
  language: DEFAULT_LANGUAGE,
  sideBarActive: false,
  maintenanceMode: false,
  showMobileMenu: false,
  modal: {
    type: null,
    visible: false,
  },
});

export default function UI(state = initialState, action = {}) {
  switch (action.type) {
    case ENABLE_MAINTENANCE_MODE: {
      return state.set('maintenanceMode', true);
    }

    case DISABLE_MAINTENANCE_MODE: {
      return state.set('maintenanceMode', false);
    }

    case LANGUAGE_SET: {
      return state.set('language', action.language);
    }

    case SHOW_MOBILE_MENU: {
      return state.set('showMobileMenu', true);
    }

    case HIDE_MOBILE_MENU: {
      return state.set('showMobileMenu', false);
    }

    case MODAL_SHOW: {
      return state.setIn(['modal', 'visible'], true).setIn(['modal', 'type'], action.modalType);
    }

    case MODAL_HIDE: {
      return state.setIn(['modal', 'visible'], false);
    }

    default:
      return state;
  }
}
