import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Immutable from 'immutable';
import { Container, Button } from '@uncinc/uncinc-react-kitchen-sink';

import { confirm } from 'react-confirm-box';
import { useTranslation } from 'react-i18next';
import { setGlobalMessage } from '../../../actions/messages';
import { updateUserProfile, deleteUserProfile } from '../../../actions/user';
import { Confirm, ErrorMessage, HeaderAdmin } from '../../../components';
import { clearErrors } from '../../../actions/errors';

import './style.scss';

const AccountEdit = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.getIn(['user'], Immutable.Map()));
  const error = useSelector((state) => state.getIn(['errors', 'user', 'errors', 'errors', 'name', 0]));
  const [submitted, setSubmitted] = useState(false);
  const { history } = props;

  const deleteAccount = async () => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            description={t('admin.account-edit.confirm_delete')}
            confirm={t('button.yes')}
          />
        );
      },
    };
    const answer = await confirm('', options);
    if (answer === true) {
      dispatch(deleteUserProfile());
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user.get('name', ''),
    },
    validationSchema: Yup.object().shape({
      name: Yup
        .string()
        .required(t('form.name.required')),
    }),

    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      setSubmitted(true);
      dispatch(clearErrors());
      dispatch(updateUserProfile(values));
    },
  });

  useEffect(() => {
    if (submitted && user.get('name', '') === formik.values.name) {
      dispatch(setGlobalMessage(t('account.overview.edit-account_updated')));
    }
  }, [user, submitted, formik, dispatch, t]);

  return (
    <div className="EditAccount">
      <Container>
        <div className="EditAccount__content">
          <HeaderAdmin
            onBack={goBack}
          />
          <h1 className="EditAccount__title">
            {t('account.overview.settings')}
          </h1>
          {error && (
            <div className="alert alert-danger">
              {error}
            </div>
          )}
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="name">
              <div className="EditAccount__label">
                {t('form.name.label')}
              </div>
              <input
                id="name"
                name="name"
                type="text"
                placeholder={t('form.name.placeholder')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </label>
            {formik.errors.name ? (
              <ErrorMessage message={formik.errors.name} />
            ) : null}

            <Button type="submit" className="Button Button--primary Button--block">
              {t('account.overview.save_changes')}
            </Button>
            <div className="EditAccount__actions">
              <a className="EditAccount__delete" onClick={() => deleteAccount()}>
                {t('account.overview.remove_account')}
              </a>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default AccountEdit;
