import React from 'react';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import './style.scss';

const Markdown = ({ children }) => {

  return (
    <div className="Markdown">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        linkTarget="_blank"
      >
        {children}
      </ReactMarkdown>
    </div>
  );

};
export default Markdown;
