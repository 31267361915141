import React, { useEffect, useState } from 'react';
import { confirm } from 'react-confirm-box';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'immutable';

import { GenericScreen, Confirm, CardStack, StickyFooter } from '../../../../../../../components';
import { moveAnswerToTop, postAnswerFlagged, updateAnswerStatusVerified } from '../../../../../../../actions/project';
import { setGlobalError, setGlobalMessage } from '../../../../../../../actions/messages';
import { getErrorMessage } from '../../../../../../../helpers/errors';

import './style.scss';

const ProjectFlowRoundTypeQuestionV3StackingVerify = ({ stimulusId, answerId, cluster, requestRefresh }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const projectName = useSelector((state) => state.getIn(['project', 'name'], null));
  const projectSlug = useSelector((state) => state.getIn(['project', 'slug']), null);
  const userId = useSelector((state) => state.get('user_id', null));
  const currentRoundId = useSelector((state) => state.getIn(['project', 'currentRound', 'id'], null));
  const error = useSelector((state) => state.getIn(['errors', 'project', 'errors'], null));
  const [locallyFlaggedAnswerIds, setLocallyFlaggedAnswerIds] = useState([]);
  const answersInCluster = cluster && cluster.get('ordered_answers') || new List();

  const bestAnswerInCluster = answersInCluster.find((answer) => answer.get('parent_id') === null);
  const answersWithoutBest = answersInCluster.filter((answer) => answer.get('parent_id') !== null);
  const sortedClusterAnswers = answersWithoutBest.sort((a) => {
    if (a.get('user_id') === userId) {
      return 1;
    } else {
      return -1;
    }
  });

  /**
   * When the footer button is clicked, show a confirmation dialog and set the answer to verified
   * @returns
   */
  const onFooterButtonClicked = async () => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={t('project.stacking.verify.confirm.header')}
            confirm={t('project.stacking.verify.confirm.submit')}
            cancel={t('project.stacking.verify.confirm.cancel')}
          />
        );
      },
    };

    const result = await confirm('', options);

    if (!result) {
      return;
    }

    dispatch(updateAnswerStatusVerified(projectSlug, currentRoundId, stimulusId, answerId, () => {
      requestRefresh();
    }));
  };

  /**
   * When placetop is clicked, perform the move to top api request and call the success callback
   * @param {String} answerId
   */
  const onPlaceTopClicked = (answerId) => {
    dispatch(moveAnswerToTop(projectSlug, currentRoundId, cluster.get('id'), answerId, () => {
      requestRefresh();
    }));
  };

  const getAnswerIsFlagged = (answer) => {
    if (answer.get('flagged_by_user') || locallyFlaggedAnswerIds.indexOf(answer.get('id')) > -1) {
      return true;
    }
    return false;
  }

  const onFlagClicked = async (answerId) => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={t('project.stacking.verify.flag-confirm.header')}
            description={t('project.stacking.verify.flag-confirm.description')}
            confirm={t('project.stacking.verify.flag-confirm.submit')}
            cancel={t('project.stacking.verify.flag-confirm.cancel')}
          />
        );
      },
    };

    const result = await confirm('', options);

    if (!result) {
      return;
    }

    dispatch(postAnswerFlagged(projectSlug, currentRoundId, stimulusId, answerId, () => {
      dispatch(setGlobalMessage(t('project.stacking.flagged-success')));
      setLocallyFlaggedAnswerIds([...locallyFlaggedAnswerIds, answerId]);
    }));
  };

  useEffect(() => {
    if (error) {
      dispatch(setGlobalError(t(getErrorMessage(error.get('code')))));
    }
  }, [error]);

  return (
    <div className="ProjectFlowRoundStackingVerify page-blue-dark page-has-sticky-button">
      <GenericScreen
        title={projectName}
      >
        <div className="ProjectFlowRoundStackingVerify__body">
          <h2 className="ProjectFlowRoundStackingVerify__title">{t('project.stacking.verify.title')}</h2>
          <p className="ProjectFlowRoundStackingVerify__description">{t('project.stacking.verify.description')}</p>
        </div>
        <div className="ProjectFlowRoundStackingVerify__best-answer">
          <h5 className="ProjectFlowRoundStackingVerify__subtitle">{t('project.stacking.verify.best-answer')}</h5>
          {bestAnswerInCluster && (
            <CardStack
              clusterId={bestAnswerInCluster.get('cluster_id')}
              title={bestAnswerInCluster.get('answer')}
              amount={1}
              participantType={bestAnswerInCluster.get('user_type')}
              ownCard={bestAnswerInCluster.get('user_id') === userId}
              isCorrectButtonCallback={onFooterButtonClicked}
            />
          )}
          {!bestAnswerInCluster && (
            <p className="ProjectFlowRoundStackingVerify__no-best-answer">{t('project.stacking.verify.no-best-answer')}</p>
          )}
        </div>
        <div className="ProjectFlowRoundStackingVerify__cards__wrapper">
          {sortedClusterAnswers.size > 0 && sortedClusterAnswers.map((answer, index) => {
            const title = answer.get('answer');
            const answerId = answer.get('id');
            const participantType = answer.get('user_type', null);
            const answerUserId = answer.get('user_id', null);

            return (
              <CardStack
                key={index}
                answerId={answerId}
                clusterId={answer.get('cluster_id')}
                title={title}
                amount={1}
                flaggedByUser={getAnswerIsFlagged(answer)}
                participantType={participantType}
                ownCard={answerUserId === userId}
                placeTopClickedCallback={onPlaceTopClicked}
                flagClickedCallback={onFlagClicked}
              />
            );
          })}
        </div>
        <StickyFooter
          buttonText={t('project.stacking.verify.button-next')}
          buttonClick={onFooterButtonClicked}
          buttonIcon={<i className="icon icon-arrow-right" />}
          color="dark-blue" />
      </GenericScreen>
    </div>
  );
};

export default ProjectFlowRoundTypeQuestionV3StackingVerify;
