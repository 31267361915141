import React from 'react';
import { useTranslation } from 'react-i18next';

import { GenericScreen } from '../../components';
import './style.scss';

const NotAvailable = () => {
  const { t } = useTranslation();

  return (
    <GenericScreen
      className="NotAvailable"
      headerLogoIcon
      profileButton
    >
      <h1>{t('navigation.not_available.title')}</h1>
      <p>{t('navigation.not_available.description')}</p>
      <button type="button" className="Button Button--primary Button--block" onClick={() => window.history.back()}>
        {t('navigation.not_available.back')}
      </button>
    </GenericScreen>
  );
};

export default NotAvailable;
