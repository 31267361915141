import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { confirm } from 'react-confirm-box';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Container,
} from '@uncinc/uncinc-react-kitchen-sink';

import {
  getClients,
  deleteClient,
  clientLoaded,
} from '../../../actions/managing';

import { buildUrl } from '../../../helpers';

import { Confirm, ManagingTable, Pager } from '../../../components';

import {
  ROUTE_CLIENT_OVERVIEW_ADD,
  ROUTE_CLIENT_OVERVIEW_EDIT,
} from '../../../config/routes';

import './style.scss';

const ClientOverview = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const lastPage = useSelector((state) => state.getIn(['managing', 'clients', 'last_page'], null));

  const clients = useSelector((state) => state.getIn(['managing', 'clients', 'data'], List()));

  useEffect(() => {
    dispatch(getClients(currentPage));
  }, [dispatch, currentPage]);

  const onDeleteClient = async (row) => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            description={t('admin.client-overview.confirm', { name: row.name })}
            confirm={t('button.yes')}
          />
        );
      },
    };
    const answer = await confirm('', options);

    if (answer === true) {
      dispatch(deleteClient(row.id));
    }
  };

  const redirectToAdd = (row) => {
    dispatch(clientLoaded({}));
    // possibly refactor to CoreLink
    history.push(buildUrl(
      ROUTE_CLIENT_OVERVIEW_ADD,
    ));
  };

  const redirectToEdit = (row) => {
    // possibly refactor to CoreLink
    history.push(buildUrl(
      ROUTE_CLIENT_OVERVIEW_EDIT,
      [row.id],
    ));
  };

  const rows = [];

  clients.map((client) => {
    rows.push({ id: client.getIn(['id']), name: client.getIn(['name']), projects: client.getIn(['projects'], List()).size });
  });

  const headings = [
    { key: 'id', value: 'ID' },
    { key: 'name', value: 'Naam' },
    { key: 'projects', value: 'Projecten' },
  ];

  return (
    <div className="ClientsOverview">
      <Container large>
        <div className="ClientsOverview__content">
          <h1>
            {t('admin.client-overview.title')}
          </h1>
          <Button type="button" className="Button Button--primary ProjectsOverview__add-project-button" onClick={() => redirectToAdd()}>
            {t('admin.client-overview.add_client')}
            <div className="icon icon-variant-1 icon-plus" />
          </Button>
          <ManagingTable
            headings={headings}
            rows={rows}
            onShow={(row) => {
              redirectToEdit(row);
            }}
            onEdit={(row) => {
              redirectToEdit(row);
            }}
            onDelete={(row) => {
              onDeleteClient(row);
            }}
          />
          {lastPage && (
            <Pager
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={lastPage}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default ClientOverview;
