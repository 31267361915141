import React from 'react';
import c from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@uncinc/uncinc-react-kitchen-sink';
import { PARTICIPATIE_PANEL_WEBSITE_URL } from '../../config/constants';
import { GenericScreen } from '../../components';
import { ROUTE_ACCOUNT_LOGIN } from '../../config/routes';
import './style.scss';

const Home = () => {
  const { t } = useTranslation();
  const token = useSelector((state) => state.getIn(['user', 'token'], false));
  const isLoggedin = token !== false;

  const classNames = c('ScreenHome');

  return (
    <GenericScreen
      className={classNames}
      headerLogoIcon
      profileButton
    >
      <h1>{t('home.title')}</h1>
      <p>
        <Trans i18nKey="home.body">
          <a href={PARTICIPATIE_PANEL_WEBSITE_URL} target="_blank" rel="noreferrer" aria-label="link" />
        </Trans>
      </p>
      {!isLoggedin && (
        <div className="Home__Buttons">
          <Link to={ROUTE_ACCOUNT_LOGIN}>
            <Button type="button" className="Button Button--primary Button--block">
              {t('home.login')}
            </Button>
          </Link>
        </div>
      )}
    </GenericScreen>
  );
};

export default Home;
