import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CookieCompliancePopup from '@uncinc/react-cookie-compliance';

import { cookieConsentGranted } from '../../actions/UI';

import './style.scss';

const Cookie = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const classNames = c('CookieCompliancePopup');

  return (
    <CookieCompliancePopup
      className={classNames}
      agreeText={t('button.yes')}
      disagreeText={t('button.no')}
      onAgree={() => {
        dispatch(cookieConsentGranted());
      }}
    >
      <p>
        {t('cookie.text')}
        <a href="#">
          {t('cookie.link')}
        </a>
      </p>
    </CookieCompliancePopup>
  );
};

export default Cookie;
