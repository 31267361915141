import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import c from 'classnames';
import Immutable, { List } from 'immutable';
import { Button, Container } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { confirm } from 'react-confirm-box';
import SelectStyled from 'react-select';

import { ROUND_TYPE_LABELS, ROUND_TYPES } from '../../../../config/constants';

import { buildUrl, useOutsideAlerter } from '../../../../helpers';

import {
  updateRoundWeights,
  deleteRound,
} from '../../../../actions/managing';

import {
  ROUTE_PROJECT_PREVIEW_FLOW,
  ROUTE_PROJECT_RESULTS,
} from '../../../../config/routes';

import { Confirm } from '../../../../components';

import InformationFlowEdit from './InformationFlowEdit';
import QuestionsV3Edit from './QuestionsV3Edit';
import SurveyFlowEdit from './SurveyFlowEdit';

const BASE_FLOW_BLOCKS = {
  information: {
    title: '',
    description: '',
    media: null,
    round_type_id: 1,
    round_type: {
      id: 1,
      name: 'information',
      description: 'Information screen.',
    },
    weight: 1,
  },
  question_v3: {
    title: '',
    description: '',
    round_type_id: 3,
    round_type: {
      id: 3,
      name: 'question_v3',
      description: 'Question screen. Type 3.',
    },
    stimuli: [],
    weight: 1,
  },
  survey: {
    title: '',
    description: '',
    round_type_id: 4,
    round_type: {
      id: 4,
      name: 'survey',
      description: 'Survey screen.',
    },
    stimuli: [],
    weight: 1,
  },
};

const FLOW_EDIT_COMPONENTS = {
  information: InformationFlowEdit,
  question_v3: QuestionsV3Edit,
  survey: SurveyFlowEdit,
};

import './style.scss';

const ProjectDetailFlow = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedRound, setSelectedRound] = useState(null);
  const [renderedRounds, setRenderedRounds] = useState(List());
  const [selectedNewBlock, setSelectedNewBlock] = useState(null);
  const [displaySelect, setDisplaySelect] = useState(false);

  const slug = useSelector((state) => state.getIn(['managing', 'project', 'slug'], null));
  const rounds = useSelector((state) => state.getIn(['managing', 'project', 'rounds'], List()));
  const selectedRoundIndex = (rounds.findIndex && selectedRound) ? rounds.findIndex((round) => round.get('id') === selectedRound.get('id')) : null;
  const stimuli = useSelector((state) => state.getIn(['managing', 'project', 'rounds', selectedRoundIndex, 'stimuli', 0], List()));
  const answersCount = useSelector((state) => state.getIn(['managing', 'project', 'rounds', selectedRoundIndex, 'answers_count'], null), null);

  let FlowEditComponent = null;
  if (selectedRound) {
    const roundTypeName = selectedRound.getIn(['round_type', 'name']);
    FlowEditComponent = FLOW_EDIT_COMPONENTS[roundTypeName];
  }

  const selectRef = useRef(null);
  useOutsideAlerter(selectRef, () => setDisplaySelect(false));

  // Add a new flow block.
  const addFlowBlock = () => {
    if (selectedNewBlock) {
      const hasDraftBlock = renderedRounds.filter((e) => e.get('title', '') === '');
      if (hasDraftBlock.size === 0) {
        const flowBlock = BASE_FLOW_BLOCKS[selectedNewBlock];
        const newRecord = Immutable.Map(flowBlock);
        const indexToInsert = renderedRounds.size - 1; // Get the index for the second last element
        const mergedRounds = renderedRounds.splice(indexToInsert, 0, newRecord);

        setSelectedNewBlock(null);
        setRenderedRounds(mergedRounds);
        setSelectedRound(newRecord);
      } else {
        alert('Er wordt al een block toegevoegd.');
      }
    }
  };

  // Select a Flowblock.
  const selectFlowBlock = (round) => {
    // Todo validate if you have changed something etc.
    setSelectedRound(round);
  };

  // Set the first Flowblock to active.
  const setFirstRoundActive = () => {
    // Set the first rounds active, if none is set.
    if (rounds.size > 0) {
      setSelectedRound(rounds.get(0));
    }
  };

  // Update the weights of the Rounds.
  const onDragEnd = (event) => {
    if (event.source && event.destination) {
      const sourceIndex = event.source.index;
      const destIndex = event.destination.index;
      const lastIndex = renderedRounds.size - 1;

      // Verify that the shuffle is allowed, otherwise cancel.
      if (
        (destIndex === 0 || destIndex === lastIndex) ||
        (sourceIndex === 0 || sourceIndex === lastIndex)
      ) {
        return false;
      }

      const sourceItem = renderedRounds.get(sourceIndex);
      const newItems = renderedRounds.delete(sourceIndex);
      const result = newItems.insert(destIndex, sourceItem);

      const data = result.map((entry, index) => {
        return { id: entry.get('id'), weight: index + 1 };
      });
      // Update the new weights in the BE.
      dispatch(updateRoundWeights(data.toJS(), slug));
      // Update our local rounds with the new result
      setRenderedRounds(result);
    }
  };

  const deleteProjectRound = async () => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            description={t('confirm')}
            confirm={t('button.yes')}
          />
        );
      },
    };
    const answer = await confirm('', options);

    if (answer === true) {
      dispatch(deleteRound(slug, selectedRound.get('id')));
      setFirstRoundActive();
    }
  };

  const openPreviewMode = () => {
    window.open(buildUrl(
      ROUTE_PROJECT_PREVIEW_FLOW,
      [slug, selectedRoundIndex],
    ), '_blank');
  };

  const redirectToDashboard = (roundId, stimuliId, flagged) => {
    let url = buildUrl(
      ROUTE_PROJECT_RESULTS,
      [slug, roundId, stimuliId || stimuli.get('id')],
    );

    if (flagged) {
      url += '?flagged=true';
    }

    history.push(url);
  };

  const redirectFromQuestion = (stimuliId) => {
    redirectToDashboard(selectedRound.get('id'), stimuliId);
  };

  useEffect(() => {
    // Set rendered rounds.
    setRenderedRounds(rounds);

    // Set the first rounds active, if none is set.
    if (!selectedRound && rounds.size > 0) {
      setSelectedRound(rounds.get(0));
    }
  }, [rounds]);

  const selectRoundTypeArray = Object.keys(ROUND_TYPE_LABELS).map((key) => ({
    label: ROUND_TYPE_LABELS[key],
    value: key,
  }));

  const renderedRoundWithNoTitle = renderedRounds.filter((e) => e.get('title', '') === '');

  return (
    <div className="AdminProjectDetailFlow">
      <Container>
        <div className="ProjectDetailLayout__intro">
          <p className="ProjectDetailLayout__intro__text">{t('admin.project-detail.flow.intro')}</p>
          <Button type="button" onClick={() => document.querySelector('.Button-send-form').click()}>
            {t('admin.project-overview.round_edit.save')}
            <i className="icon icon-save icon-variant-1" />
          </Button>
        </div>
        <div className="ProjectDetailLayout">

          <div className="ProjectDetailLayout__leftColumn">
            <h4>{t('admin.project-detail.flow.steps')}</h4>

            {/* Rounds */}
            <div className="ProjectClustering__cards__wrapper">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="answer-clustering">
                  {({ innerRef, droppableProps, placeholder }) => (
                    <div
                      ref={innerRef}
                      {...droppableProps}
                    >
                      {renderedRounds.map((round, index) => {
                        const id = round.get('id');
                        const title = round.get('title');
                        const name = round.getIn(['round_type', 'name']);
                        const isNonEditable = index === 0 || (renderedRounds.size - 1) === index;
                        const isActive = selectedRound ? selectedRound.get('id') === round.get('id') : false;
                        const classNames = c('FlowBlockTeaser', {
                          'disabled': isNonEditable,
                          'active': isActive,
                        });
                        const answersCount = round.get('answers_count', null);
                        const flagsCount = round.get('flagged_answers_count', null);
                        const stimuliId = round.getIn(['stimuli', 0, 'id'], null);
                        const roundTypeName = round.getIn(['round_type', 'name'], null);
                        const isFirstRound = round.get('is_first_round', false);
                        const isLastRound = round.get('is_last_round', false);

                        return title && (
                          <>
                            <Draggable
                              key={id}
                              draggableId={String(id)}
                              index={index}
                              isDragDisabled={isNonEditable}
                            >
                              {(provided, { isDragging }) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className={classNames} onClick={() => { selectFlowBlock(round); }}>
                                    <div className="FlowBlockTeaser__title">
                                      {isFirstRound ? (
                                        <>{t('admin.project-detail.flow.form_title_start')}</>
                                      ) : isLastRound ? (
                                        <>{t('admin.project-detail.flow.form_title_end')}</>
                                      ) : (
                                        <>{ROUND_TYPE_LABELS[name]}</>
                                      )}
                                    </div>
                                    <div className="FlowBlockTeaser__subtitle">
                                      {title}
                                    </div>
                                    <div className="FlowBlockTeaser__dragIcon">
                                      <i className="icon icon-drag" />
                                    </div>
                                    {(answersCount > 0 || flagsCount > 0) && roundTypeName !== ROUND_TYPES.SURVEY &&
                                      <div className="FlowBlockTeaser__buttons">
                                        {answersCount > 0 &&
                                          <Button type="button"
                                            className="Button Button--small FlowBlockTeaser__buttons__showResults"
                                            onClick={(e) => redirectToDashboard(id, stimuliId)}
                                          >
                                            {t('admin.project-overview.round_edit.questionV3.showResults')}
                                            <i className="icon icon-layers" />
                                            {answersCount}
                                          </Button>}
                                        {flagsCount > 0 &&
                                          <Button type="button"
                                            className="Button Button--small Button--flag"
                                            onClick={(e) => redirectToDashboard(id, stimuliId, true)}
                                          >
                                            <i className="icon icon-flag" />
                                            {flagsCount}
                                          </Button>}
                                      </div>}
                                  </div>
                                </div>
                              )}
                            </Draggable>

                            {
                              rounds.size - 2 === index &&
                              <></>
                            }
                          </>
                        );
                      })}
                      {placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {renderedRoundWithNoTitle && renderedRounds.map((round) => {
                const title = round.get('title', '');
                const name = round.getIn(['round_type', 'name']);
                const label = ROUND_TYPE_LABELS[name];

                return title === '' && (
                  <div className="FlowBlockTeaser active" onClick={() => { selectFlowBlock(round); }}>
                    <div className="FlowBlockTeaser__title">
                      Voer gegevens in
                    </div>
                    {label &&
                      <div className="FlowBlockTeaser__subtitle">
                        {label}
                      </div>}
                  </div>
                );
              })}

              {!renderedRoundWithNoTitle.size &&
                <div
                  className="FlowBlockTeaser__addField"
                >
                  {/* Add */}

                  <div className="FlowBlockTeaser__addField__select"
                    onClick={displaySelect ? null : () => setDisplaySelect(true)}
                    ref={selectRef}
                  >
                    {displaySelect ?
                      <SelectStyled
                        id="flow"
                        key="flow"
                        inputId="value"
                        className="SelectInput__select"
                        classNamePrefix="SelectInput__inner"
                        isSearchable={false}
                        defaultValue={null}
                        onChange={(e) => setSelectedNewBlock(e.value)}
                        options={selectRoundTypeArray}
                        placeholder={t('admin.project-detail.flow.add_step_placeholder')}
                      /> :
                      <p className="FlowBlockTeaser__addField__label">{t('admin.project-detail.flow.add_step')}</p>}
                    <Button className="Button Button--round" onClick={displaySelect ? addFlowBlock : () => setDisplaySelect(true)}>
                      <i className="icon icon-plus icon-variant-1" />
                    </Button>
                  </div>
                </div>}
            </div>

          </div>

          <div className="ProjectDetailLayout__rightColumn">
            {/* Delete */}
            <div>
              {(selectedRound && FlowEditComponent) && (
                <div>
                  <FlowEditComponent
                    round={selectedRound}
                    setFirstRoundActive={setFirstRoundActive}
                    redirectToPreviewRound={openPreviewMode}
                    redirectToDashboard={selectedRound.getIn(['round_type', 'name']) !== 'information' && redirectFromQuestion}
                    deleteProjectRound={(selectedRoundIndex !== 0 && (rounds.size - 1) !== selectedRoundIndex) && deleteProjectRound}
                    answersCount={answersCount}
                  />
                </div>
              )}
            </div>
          </div>

        </div>
      </Container>
    </div>
  );
};

export default ProjectDetailFlow;
