import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Container, NavigationScroll,
} from '@uncinc/uncinc-react-kitchen-sink';
import { useSelector } from 'react-redux';

import PanelLogo from '../../assets/images/panel-logo-dark.svg';
import ProgressBar from '../ProgressBar';

import './style.scss';

const HeaderFlow = ({
  onInfo = () => window.open('https://help.panel.nl/', '_blank'),
  closeButton = false,
  onClose,
  title,
  isPreview,
  hideProgress = false,
}) => {
  const { t } = useTranslation();
  const progress = useSelector((state) => state.getIn(['project', 'progress'], 0));

  return (
    <NavigationScroll>
      <div className="HeaderContainer">
        <div className="HeaderFlow__background">
          <Container>
            <div className="HeaderFlow">
              <div className="HeaderFlow__segment">
                {closeButton && (
                  <div className="HeaderFlow__Button" onClick={onClose}>
                    <Button className="Button Button--icon">
                      <div className="icon icon-cross" />
                    </Button>
                    <span className="HeaderFlow__ButtonLabel">{t('navigation.closeCluster')}</span>
                  </div>
                )}

                {!closeButton && (
                  <img src={PanelLogo} className="HeaderLogoIcon__logo" alt="" />
                )}
              </div>
              {!closeButton &&
                <h4 className="HeaderFlow__title">
                  {isPreview ? '(Preview) - ' : ''}{title}
                </h4>}
              <div className="HeaderFlow__segment">
                {!closeButton && (
                  <div className="HeaderFlow__Button" onClick={onInfo}>
                    <Button className="Button Button--icon">
                      <div className="icon icon-info" />
                    </Button>
                    <span className="HeaderFlow__ButtonLabel">{t('navigation.help')}</span>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
        {!closeButton && <ProgressBar progress={progress} />}
      </div>
    </NavigationScroll>
  );

};
export default HeaderFlow;
