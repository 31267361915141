import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ROUND_TYPES } from '../../../config/constants';
import { postMarkRoundComplete } from '../../../actions/project';

import ProjectFlowRoundTypeInformation from './Type/Information';
import ProjectFlowRoundTypeQuestionV3 from './Type/QuestionV3';
import ProjectFlowRoundTypeSurvey from './Type/Survey';

const ROUND_TYPE_COMPONENTS = {};
ROUND_TYPE_COMPONENTS[ROUND_TYPES.INFORMATION] = ProjectFlowRoundTypeInformation;
ROUND_TYPE_COMPONENTS[ROUND_TYPES.SURVEY] = ProjectFlowRoundTypeSurvey;
ROUND_TYPE_COMPONENTS[ROUND_TYPES.QUESTIONV3] = ProjectFlowRoundTypeQuestionV3;

import './style.scss';


const ProjectFlowRound = ({ round, roundIndex, totalRoundCount, roundMarkedComplete, previewRoundComplete, isPreview }) => {
  const dispatch = useDispatch();

  const roundType = round.getIn(['round_type', 'name'], null);
  const projectSlug = useSelector((state) => state.getIn(['project', 'slug'], null));
  const projectName = useSelector((state) => state.getIn(['project', 'name'], null));

  /**
   * After a round has been succesfully submitted, if it is not the last round, mark it as complete.
   * @returns {void}
   */
  const onRoundSubmitSuccess = () => {
    if (!isPreview && roundIndex < totalRoundCount - 1) {
      dispatch(postMarkRoundComplete(projectSlug, round.get('id'), (data) => {
        roundMarkedComplete(data['next_round_id']);
      }));
    } else if (isPreview && previewRoundComplete && roundIndex < totalRoundCount - 1) {
      previewRoundComplete();
    }
  };

  /**
   * Based on the current round type, render the corresponding component.
   * @returns {JSX.Element} The rendered component.
   */
  const renderRoundType = () => {
    const availableRoundTypes = Object.keys(ROUND_TYPE_COMPONENTS);
    const RenderableComponent = availableRoundTypes[roundType] !== null ? ROUND_TYPE_COMPONENTS[roundType] : null;

    if (RenderableComponent) {
      return <RenderableComponent projectName={projectName} round={round} roundIndex={roundIndex} roundSubmitSuccess={onRoundSubmitSuccess} isPreview={isPreview} />;
    } else {
      return (
        <span>Unknown Round Type</span>
      );
    }
  };

  return (
    <div className="projectFlowRound">
      {renderRoundType()}
    </div>
  );
};

export default ProjectFlowRound;
