import React, { useEffect } from 'react';
import { List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { __DEBUG__, INDEX_NOT_FOUND, USER_ROLES } from '../../config/constants';

import { clearGlobalError, clearGlobalMessage } from '../../actions/messages';
import { hideModal as hideModalAction } from '../../actions/UI';
import { getUserProfile } from '../../actions/user';
import i18next from '../../config/i18next';

import {
  Cookie,
  Debug,
  I18nProvider,
  ManagingNavigation,
} from '../../components';

import {
  AccountLogin,
  NotFound,
  NotAllowed,
} from '..';

import {
  routes,
  protectedRoutes,
  facilitatorRoutes,
  managingRoutes,
  adminRoutes,
} from '../../routes';

import '../../styles/main.scss';
import './style.scss';

const App = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.getIn(['ui', 'language']));
  const userRoles = useSelector((state) => state.getIn(['user', 'roles'], List()));
  const token = useSelector((state) => state.getIn(['user', 'token'], false));
  const email = useSelector((state) => state.getIn(['user', 'email']));
  const globalMessage = useSelector((state) => state.getIn(['messages', 'globalMessage']));
  const globalError = useSelector((state) => state.getIn(['messages', 'globalError']));

  const isFacilitator = userRoles.findIndex((i) => i.get('name') === USER_ROLES.FACILITATOR) !== INDEX_NOT_FOUND;
  const isManagedUser = userRoles.findIndex((i) => i.get('name') === USER_ROLES.PROJECT_MANAGER) !== INDEX_NOT_FOUND;
  const isAdmin = userRoles.findIndex((i) => i.get('name') === USER_ROLES.ADMIN) !== INDEX_NOT_FOUND;

  const showModal = useSelector((state) => state.getIn(['ui', 'modal', 'visible'], false));
  useEffect(() => {
    if (showModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [showModal]);

  if (token && !email) {
    dispatch(getUserProfile());
  }

  if (globalMessage) {
    window.setTimeout(() => {
      dispatch(clearGlobalMessage());
    }, 5000);
  }

  if (globalError) {
    window.setTimeout(() => {
      dispatch(clearGlobalError());
    }, 5000);
  }

  const renderRoute = (route) => {
    return (<Route key={route.route} exact path={route.route} component={route.component} />);
  };

  const renderLoginRoute = (route) => {
    return (<Route key={route.route} exact path={route.route} component={AccountLogin} />);
  };

  const renderNotAllowed = (route) => {
    return (<Route key={route.route} exact path={route.route} component={NotAllowed} />);
  };

  // on pressing back button always close modal in the store
  useEffect(() => {
    window.onpopstate = () => {
      dispatch(hideModalAction());
    };
  }, []);

  return (
    <I18nProvider locale={currentLanguage} i18next={i18next}>
      <div className="App">
        {/* Navigation */}
        {(isManagedUser || isFacilitator || isAdmin) && (<ManagingNavigation />)}
        {/* Render routes */}
        <main>
          {globalMessage && (
            <div className="GenericMessage">
              {globalMessage}
            </div>
          )}
          {globalError && (
            <div className="GenericError">
              {globalError}
            </div>
          )}
          <Switch>
            {/* Public routes */}
            {routes.map(renderRoute)}
            {/* Protected routes */}
            {token !== false ? (protectedRoutes.map(renderRoute)) : (protectedRoutes.map(token ? renderNotAllowed : renderLoginRoute))}
            {/* Facilitator routes */}
            {isFacilitator || isManagedUser || isAdmin ? (facilitatorRoutes.map(renderRoute)) : (facilitatorRoutes.map(token ? renderNotAllowed : renderLoginRoute))}
            {/* Managed routes */}
            {isManagedUser || isAdmin ? (managingRoutes.map(renderRoute)) : (managingRoutes.map(token ? renderNotAllowed : renderLoginRoute))}
            {/* Admin routes */}
            {isFacilitator || isAdmin ? (adminRoutes.map(renderRoute)) : (adminRoutes.map(token ? renderNotAllowed : renderLoginRoute))}
            {/* Fallback 404 */}
            <Route component={NotFound} />
          </Switch>
        </main>

        {__DEBUG__ && <Debug />}
        <Cookie />
      </div>
    </I18nProvider>
  );
};

export default App;
