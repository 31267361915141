import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';

const I18nProvider = ({ locale, children, i18next }) => {
  useEffect(() => {
    i18next.changeLanguage(locale);
  }, [locale, i18next]);

  return (
    <I18nextProvider i18n={i18next}>
      {children}
    </I18nextProvider>
  );
};

export default I18nProvider;
