/**
 * A Method that returns isPreview true if the user is on the preview route
 * @param {String} pathname - The current path name
 * @returns
 */

export const isPreview = (pathname = '') => {
  const urlParts = pathname.split('/');

  if (urlParts.length > 1 && urlParts[1] === 'preview') {
    return true;
  }

  return false;
};

export default {
  isPreview,
};
