export const ROUTE_HOME = '/';

// Client Facing - Account routes
export const ROUTE_ACCOUNT_EDIT = '/accounts/edit';
export const ROUTE_ACCOUNT_LOGIN = '/accounts/login';
export const ROUTE_ACCOUNT_VERIFY = '/accounts/verify/:token';

// Client Facing - Project Flow
export const ROUTE_PROJECT_ENTRY = '/projects/:projectSlug';
export const ROUTE_PROJECT_FLOW = '/projects/:projectSlug/flow/:roundIndex';
export const ROUTE_PROJECT_FLOW_ROUND_CLUSTER = '/projects/:projectSlug/round/flow/:roundIndex/clusters/:clusterId';

export const ROUTE_SURVEY = '/survey';

// Backoffice - Facilitator
export const ROUTE_PROJECT_OVERVIEW = '/admin/projects';
export const ROUTE_PROJECT_OVERVIEW_EDIT = '/admin/projects/edit/:projectSlug';
export const ROUTE_PROJECT_OVERVIEW_ROUND_EDIT = '/admin/projects/edit/:projectSlug/round/:projectRound';
export const ROUTE_PROJECT_OVERVIEW_QUESTION_EDIT = '/admin/projects/edit/:projectSlug/round/:projectRound/stimuli/:stimuliId';
export const ROUTE_PROJECT_RESULTS = '/projects/:projectSlug/round/:projectRound/stimuli/:stimuliId/results';
export const ROUTE_PROJECT_RESULTS_CLUSTER = '/projects/:projectSlug/round/:projectRound/stimuli/:stimuliId/results/:clusterId';

// Backoffice - Project Manager
export const ROUTE_PROJECT_OVERVIEW_ADD = '/managing/projects/add';

// Preview mode - Project Manager
export const ROUTE_PROJECT_PREVIEW_FLOW = '/preview/projects/:projectSlug/flow/:roundIndex';

// Backoffice - Admin
export const ROUTE_ACCOUNT_OVERVIEW = '/admin/accounts';
export const ROUTE_ACCOUNT_OVERVIEW_EDIT = '/admin/accounts/edit/:accountId';
export const ROUTE_ACCOUNT_OVERVIEW_ADD = '/admin/accounts/add';

export const ROUTE_CLIENT_OVERVIEW = '/admin/clients';
export const ROUTE_CLIENT_OVERVIEW_ADD = '/clients/add';
export const ROUTE_CLIENT_OVERVIEW_EDIT = '/clients/edit/:clientId';

export const ROUTE_ADMIN_PROJECT_DETAIL = '/admin/projects/:projectSlug';
export const ROUTE_ADMIN_PROJECT_DETAIL_SECTION = '/admin/projects/:projectSlug/:section';

export const ROUTE_UNAUTHORIZED = '/forbidden';
