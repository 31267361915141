import React, { useEffect } from 'react';
import { List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';

import { buildUrl } from '../../../helpers';
import { getProject, setCurrentRound, setCurrentRoundIndex, setLocalRoundComplete, setProjectFlowProgress } from '../../../actions/project';
import ProjectFlowRound from '../ProjectFlowRound';
import { getCurrentRound, getRoundIndexWithId } from '../../../helpers/rounds';
import { ROUTE_PROJECT_FLOW, ROUTE_PROJECT_PREVIEW_FLOW } from '../../../config/routes';
import { isPreview } from '../../../helpers/preview';

import './style.scss';


const ProjectFlow = (props) => {
  const { match: { params: { projectSlug, roundIndex } }, history, location: { pathname } } = props;

  const dispatch = useDispatch();
  const previewMode = isPreview(pathname);

  const roundIndexFromURL = Number(roundIndex);
  const projectNameFromURL = projectSlug;
  const rounds = useSelector((state) => state.getIn(['project', 'rounds'], List()));
  const currentRoundData = useSelector((state) => state.getIn(['project', 'currentRound'], null));
  const currentRoundIndexFromState = useSelector((state) => state.getIn(['project', 'currentRoundIndex'], null));

  /**
   * If a round has been marked as complete, check if it emits a next round id
   * If that is passed, navigate to that round
   * @param {String} nextRoundId
   */
  const onRoundMarkedComplete = (nextRoundId) => {
    if (nextRoundId) {
      const nextRoundIndex = getRoundIndexWithId(rounds, nextRoundId);
      dispatch(setLocalRoundComplete(currentRoundIndexFromState));
      history.push(buildUrl(ROUTE_PROJECT_FLOW, [projectSlug, nextRoundIndex]));
    }
  };

  const onPreviewRoundComplete = () => {
    if (isPreview) {
      history.push(buildUrl(ROUTE_PROJECT_PREVIEW_FLOW, [projectSlug, currentRoundIndexFromState + 1]));
    }
  };

  // If there is no project name in the state, get the project from the projectslug from the url
  useEffect(() => {
    dispatch(getProject(projectNameFromURL));
  }, [projectNameFromURL, dispatch]);

  /**
   * Based on the current roundIndex in the URL and the current round in the state,
   * Check these two match and set it as "currentRound" if not, navigate to the round from the state
   */
  useEffect(() => {
    if (rounds.size === 0) return;

    const currentRoundBasedOnCompleted = getCurrentRound(rounds);
    const currentRoundBasedOnURL = rounds.get(roundIndexFromURL);

    if (!currentRoundBasedOnCompleted) return;

    const targetRoundIndex = getRoundIndexWithId(rounds, currentRoundBasedOnCompleted.get('id'));
    if (!previewMode &&
      (Number.isNaN(Number(roundIndexFromURL)) ||
        currentRoundBasedOnURL.get('id') !== currentRoundBasedOnCompleted.get('id'))) {
      history.push(buildUrl(ROUTE_PROJECT_FLOW, [projectSlug, targetRoundIndex]));
    } else {
      dispatch(setCurrentRound(currentRoundBasedOnURL));
      dispatch(setCurrentRoundIndex(roundIndexFromURL));
      dispatch(setProjectFlowProgress(roundIndex / (rounds.size - 1) * 100));
    }
  }, [rounds, roundIndexFromURL, dispatch]);

  return (
    <div className="ProjectFlow">
      {currentRoundData && (
        <ProjectFlowRound
          isPreview={previewMode}
          round={currentRoundData}
          roundIndex={currentRoundIndexFromState}
          totalRoundCount={rounds.size}
          previewRoundComplete={onPreviewRoundComplete}
          roundMarkedComplete={onRoundMarkedComplete} />
      )}
    </div>
  );
};

export default ProjectFlow;
