/**
 * Check if passed round Id exists in a collection of rounds
 * @param {List} rounds - The collection of rounds to search in.
 * @param {string} roundIndex - The round Id to check for.
 * @returns {Boolean} Round exists true or false.
 */
export const roundIndexExistsInList = (rounds, roundIndex = 0) => {
  if (!rounds || (rounds && rounds.size === 0)) return false;
  return Boolean(rounds.get(roundIndex));
};

/**
 * Get the index of a round based on a passed roundId.
 *
 * @param {List} rounds - The collection of rounds to search in.
 * @param {string} roundId - The ID of the round to find.
 * @returns {number} The index of the round with the specified ID.
 */
export const getRoundIndexWithId = (rounds, roundId) => {
  if (!rounds || !roundId || (rounds && rounds.size === 0)) return null;
  return rounds.findIndex((round) => {
    return round.get('id').toString() === roundId.toString();
  });
};

/**
 * Get the current round based on completed steps.
 *
 * @param {List} rounds - The collection of rounds to search in.
 * @returns {Map|null} The current round or null if not found.
 */
export const getCurrentRound = (rounds) => {
  if ((rounds && rounds.size === 0)) return null;
  return rounds.find((round) => {
    return round.get('completed') === false;
  });
};

