import React from 'react';
import c from 'classnames';

import { Container } from '@uncinc/uncinc-react-kitchen-sink';

import HeaderLogoIcon from '../HeaderLogoIcon';
import HeaderFlow from '../HeaderFlow';

import './style.scss';

const GenericScreen = ({
  className,
  children,
  headerLogoIcon = false,
  isPreview,
  ...props
}) => {
  const classNames = c('Screen', className);

  return (
    <>
      {headerLogoIcon ?
        <HeaderLogoIcon {...props} /> :
        <HeaderFlow
          isPreview={isPreview}
          {...props}
        />}
      <Container>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className={classNames}>
              {children}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default GenericScreen;
