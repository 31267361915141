import React, { useCallback } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';

import './style.scss';
import { ROUTE_PROJECT_FLOW } from '../../config/routes';
import { buildUrl } from '../../helpers';
import { ROUND_STATUS_INACTIVE } from '../../config/constants';

const RoundCard = ({ round, index, projectName, history }) => {
  const { t } = useTranslation();

  const id = round.get('id');
  const title = round.get('title');
  const roundInactive = round.get('status') === ROUND_STATUS_INACTIVE;

  const classNames = c('RoundCard', { inverted: roundInactive, disabled: roundInactive });
  const iconClassNames = c('icon', {
    'icon-check': roundInactive,
    'icon-chevron-down': !roundInactive,
  });

  const redirectToRound = useCallback((roundId) => {
    // Redirect to selected round
    history.push(buildUrl(
      ROUTE_PROJECT_FLOW,
      [projectName, roundId],
    ));
  }, [history, projectName]);

  return (
    <div
      className={classNames}
      onClick={!roundInactive ? () => {
        redirectToRound(id);
      } : null}
    >
      <div className="RoundCard__heading">
        {t('component.round-card.heading', { round: index + 1 })}
      </div>
      <div className="RoundCard__title">
        {title}
      </div>
      <div className={iconClassNames} />
    </div>
  );
};

export default RoundCard;
