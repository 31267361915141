import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Immutable from 'immutable';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import config from 'react-global-configuration';
import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';
import { clearErrors } from '../../../actions/errors';
import { setGlobalMessage } from '../../../actions/messages';
import { STACK_LIMIT_MIN, STACK_LIMIT_MAX, TEXT_INPUT_MAX_LENGTH } from '../../../config/constants';
import { buildUrl } from '../../../helpers';
import { ROUTE_PROJECT_OVERVIEW } from '../../../config/routes';
import {
  createProject,
  getProject,
  updateProject,
  uploadProjectTranslations,
} from '../../../actions/managing';

import {
  ErrorMessage,
  FieldFileUpload,
  MarkdownEditor,
  HeaderAdmin,
} from '../../../components';

import './style.scss';

const ProjectOverviewEdit = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);

  const name = useSelector((state) => state.getIn(['managing', 'project', 'name'], ''));
  const description = useSelector((state) => state.getIn(['managing', 'project', 'description'], ''));
  const accessCode = useSelector((state) => state.getIn(['managing', 'project', 'access_code'], ''));
  const stackLimit = useSelector((state) => state.getIn(['managing', 'project', 'stack_limit'], STACK_LIMIT_MAX));
  let clientId = useSelector((state) => state.getIn(['managing', 'project', 'client_id'], ''));
  const publicationState = useSelector((state) => state.getIn(['managing', 'project', 'publication_state'], ''));
  const anonymousMode = useSelector((state) => state.getIn(['managing', 'project', 'anonymous_mode'], false));
  const translations = useSelector((state) => state.getIn(['managing', 'project', 'translations'], Immutable.List()));
  const projectId = useSelector((state) => state.getIn(['managing', 'project', 'id'], ''));
  const basePath = config.get('API_URL');
  const downloadableUrl = `${basePath}/projects/download-translation-file/${projectId}`;

  const { match: { params: { projectSlug } }, history } = props;

  const editMode = name !== '';
  if (!editMode) {
    clientId = urlParams.get('client_id');
  }

  const redirectToOverview = () => {
    history.push(buildUrl(
      ROUTE_PROJECT_OVERVIEW,
    ));
  };

  const successCallback = () => {
    const message = editMode ? `Project '${name}' aangepast.` : 'Project aangemaakt.';
    dispatch(setGlobalMessage(message));
    redirectToOverview();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name,
      description: description,
      access_code: accessCode,
      stack_limit: stackLimit,
      translations_file_data: '',
      translations_file_name: '',
      publication_state: publicationState,
      client_id: clientId,
      anonymous_mode: anonymousMode,
    },
    validationSchema: Yup.object().shape({
      name: Yup
        .string()
        .required('Titel verplicht'),
      description: Yup
        .string()
        .required('Beschrijving verplicht'),
      publication_state: Yup
        .string(),
    }),

    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      const projectValuesFiltered = Object
        .entries(values)
        .filter(([key, value]) => !key.startsWith('translations_file'));

      const projectValues = Object.fromEntries(projectValuesFiltered);

      const updateProjectCallback = ({ id }) => {
        // Upload the translation file if it was set.
        if (values.translations_file_data) {
          dispatch(uploadProjectTranslations({
            id,
            data: values.translations_file_data,
          }));
        }
        successCallback();
      };

      dispatch(clearErrors());

      if (editMode) {
        dispatch(updateProject(projectValues, projectSlug, updateProjectCallback));
      } else {
        dispatch(createProject(projectValues, updateProjectCallback));
      }
    },
  });

  useEffect(() => {
    if (projectSlug) {
      dispatch(getProject(projectSlug));
    }
  }, [dispatch, projectSlug]);

  return (
    <div className="ProjectOverviewEdit">
      <Container>
        <div className="ProjectOverviewEdit__content">
          <HeaderAdmin
            onBack={redirectToOverview}
          />
          <h1 className="ProjectOverviewEdit__title">{editMode ? t('project.overview.form.edit') : t('project.overview.form.create')}</h1>
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="name">
              <div className="ProjectOverviewEdit__label">{t('project.overview.form.title')}</div>
              <input
                id="name"
                name="name"
                type="text"
                placeholder={t('form.name.placeholder')}
                onChange={formik.handleChange}
                value={formik.values.name}
                maxLength={TEXT_INPUT_MAX_LENGTH}
              />
            </label>
            {formik.errors.name ? (
              <ErrorMessage message={formik.errors.name} />
            ) : null}

            <label htmlFor="description">
              <div className="ProjectOverviewEdit__label">{t('project.overview.form.description')}</div>
              <MarkdownEditor
                value={formik.values.description}
                id="description_markdown"
                name="description"
                onChange={(data) => formik.setFieldValue('description', data)}
              />
            </label>
            {formik.errors.description ? (
              <ErrorMessage message={formik.errors.description} />
            ) : null}

            <label htmlFor="anonymous_mode">
              <div className="ProjectOverviewEdit__label">{t('project.overview.form.anonymous_mode')}</div>

              <input
                id="anonymous_mode"
                name="anonymous_mode"
                type="checkbox"
                onChange={() => (
                  formik.setFieldValue(
                    'anonymous_mode',
                    !formik.values.anonymous_mode,
                  )
                )}
                checked={formik.values.anonymous_mode}
                className="checkbox"
                value="1"
              />
            </label>
            {formik.errors.anonymous_mode ? (
              <ErrorMessage message={formik.errors.anonymous_mode} />
            ) : null}

            <label htmlFor="access_code">
              <div className="ProjectOverviewEdit__label">{t('project.overview.form.access_code')}</div>
              <input
                id="access_code"
                name="access_code"
                type="number"
                placeholder="123456"
                onChange={formik.handleChange}
                value={formik.values.access_code}
              />
            </label>
            {formik.errors.access_code ? (
              <ErrorMessage message={formik.errors.access_code} />
            ) : null}

            <label htmlFor="stack_limit">
              <div className="ProjectOverviewEdit__label">{t('project.overview.form.stack_limit')}</div>
              <input
                id="stack_limit"
                name="stack_limit"
                type="number"
                placeholder={STACK_LIMIT_MAX}
                min={STACK_LIMIT_MIN}
                max={STACK_LIMIT_MAX}
                onChange={formik.handleChange}
                value={formik.values.stack_limit}
              />
            </label>
            {formik.errors.stack_limit ? (
              <ErrorMessage message={formik.errors.stack_limit} />
            ) : null}

            <FieldFileUpload
              name="translations_file"
              label={t('project.overview.form.translations_file')}
              types=".po"
              fileName={formik.values.translations_file_name}
              onSuccess={(fileName, fileData) => {
                formik.setFieldValue('translations_file_data', fileData);
                formik.setFieldValue('translations_file_name', fileName);
              }}
            />

            {translations.size > 0 && (
              <a
                target="_blank"
                rel="noreferrer"
                href={downloadableUrl}
                className="ProjectOverviewEdit__po-download-link"
              >
                {t('project.overview.po_file_download_link')}
              </a>
            )}

            <label htmlFor="publication_state">
              <div className="ProjectOverviewEdit__label">{t('project.overview.form.publication_state')}</div>
              <select
                id="publication_state"
                name="publication_state"
                onChange={formik.handleChange}
                value={formik.values.publication_state}
              >
                <option value={0} label="Concept">Concept</option>
                <option value={1} label="Gepubliceerd">Gepubliceerd</option>
              </select>
            </label>
            {formik.errors.publication_state ? (
              <ErrorMessage message={formik.errors.publication_state} />
            ) : null}

            <Button type="submit" className="Button Button--primary">{t('project.overview.form.save')}</Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default ProjectOverviewEdit;
