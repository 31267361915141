import React, { useState } from 'react';
import c from 'classnames';

import './style.scss';

const FieldRadioList = ({
  className,
  fieldName = 'FieldRadioList',
  options = [],
  onChange,
}) => {
  const classNames = c('FieldRadioList', className);

  const onInputClick = (event) => {
    const { value } = event.target;
    if (value) {
      onChange(value);
    }
  };

  return (
    <article className={classNames}>
      {options.map((value, index) => {
        const uniqueIdentifier = `${fieldName}_${index}`;
        return (
          <div key={uniqueIdentifier} className="FieldRadioList__choice">
            <input
              id={uniqueIdentifier}
              name={fieldName}
              type="radio"
              value={value}
              onClick={onInputClick}
            />
            <label className="icon-before icon-input-radio" htmlFor={uniqueIdentifier}>
              {value}
              <i className="icon-before icon-check" />
            </label>

          </div>
        );
      })}
    </article>
  );
};

export default FieldRadioList;
