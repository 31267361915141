import React from 'react';
import Immutable from 'immutable';
import { useSelector } from 'react-redux';
import { Button, Image } from '@uncinc/uncinc-react-kitchen-sink';
import { useTranslation } from 'react-i18next';

import { Markdown, GenericScreen } from '../../../../../components';

import './style.scss';

const ProjectFlowRoundTypeInformation = ({ projectName, round, isPreview = false, roundSubmitSuccess }) => {
  const { t } = useTranslation();

  const title = round.get('title');
  const body = round.get('description');
  const mediaUrl = round.get('media_url', null);
  const link = round.get('link', null);
  const mediaSubtitle = round.get('media_subtitle', null);
  const userId = useSelector((state) => state.get('user_id'));
  const isLastRound = round.get('is_last_round', false);
  const nextButtonText = link ? t('project.round.button-information-screen-exit') : t('project.round.button-information-screen');

  // If we have a link then replace and go to it.
  // If not then submit the round.
  const onNextButtonClick = () => {
    if (link) {
      let destLink = link;
      if (link.includes('=User_ID')) {
        destLink = link.replace('User_ID', userId);
      }
      window.open(destLink, '_self');

    } else {
      roundSubmitSuccess();
    }
  };

  return (
    <GenericScreen title={projectName} isPreview={isPreview}>
      <div className="ProjectFlowRoundTypeInformation">

        <h1 className="ProjectFlowRoundTypeInformation__title">{title}</h1>

        {mediaUrl &&
          <>
            <Image
              src={mediaUrl}
              className="ProjectFlowRoundTypeInformation__image"
            />
            {mediaSubtitle && (
              <p className="ProjectFlowRoundTypeInformation__imageCaption small">
                {mediaSubtitle}
              </p>
            )}
          </>}

        <Markdown className="ProjectFlowRoundTypeInformation__body">
          {body}
        </Markdown>

        {(!isLastRound || (isLastRound && link)) &&
          <Button
            className="ProjectFlowRoundTypeInformation__button"
            onClick={onNextButtonClick}
          >
            {nextButtonText}
          </Button>}
      </div>
    </GenericScreen>
  );
};

export default ProjectFlowRoundTypeInformation;
