import React, {useEffect, useState } from 'react';
import * as Yup from 'yup';
import c from 'classnames';
import Immutable from 'immutable';

import { Button, FormTextField } from '@uncinc/uncinc-react-kitchen-sink';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import './style.scss';

const FieldEditableList = ({
  className,
  fieldName = 'FieldEditableList',
  value = Immutable.List(),
  onChange,
}) => {
  const { t } = useTranslation();
  const classNames = c('FieldEditableList', className);
  const [innerValue, setInnerValue] = useState(value || Immutable.List());

  const validationSchema = Yup.object().shape({
    item: Yup.string()
      .required(t('form.answer.required')),
  });

  const addValueSubmit = ({ item }, { resetForm }) => {
    const newInnerValue = innerValue.merge(new Immutable.List([item]));
    setInnerValue(newInnerValue);
    resetForm();

    if (onChange) {
      onChange(newInnerValue);
    }
  };

  const onRemoveItemClicked = (index) => {
    const localInnerValue = new Immutable.List(innerValue).remove(index);

    setInnerValue(localInnerValue);

    if (onChange) {
      onChange(localInnerValue);
    }
  };

  // Update the state when our incoming value changes.
  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  return (
    <article className={classNames}>
      <div className="FieldEditableList__items">
        {innerValue.map((item, index) => {
          const uniqueIdentifier = `${fieldName}_${index}`;
          return (
            <div key={uniqueIdentifier} className="radio-block FieldEditableList__item">
              <label htmlFor={uniqueIdentifier} className="FieldEditableList__item__label">{item}</label>
              <Button className="Button Button--text FieldEditableList__item__button" onClick={() => onRemoveItemClicked(index)}><i className="icon icon-cross" /></Button>
            </div>
          );
        })}
      </div>
      <div className="FieldEditableList__add">
        <Formik
          onSubmit={addValueSubmit}
          validationSchema={validationSchema}
          initialValues={{ item: '' }}
        >
          {({ values, setFieldValue, submitForm }) => (
            <>
              <FormTextField
                wrapperClassName="FieldEditableList__add__input"
                fieldName="item"
                onBlur={submitForm}
                placeholder={t('form.answer.placeholder')}
                value={values.item}
              />
              <Button className="Button Button--round FieldEditableList__add__button" onClick={submitForm}>
                <i className="icon icon-plus icon-variant-1" />
              </Button>
            </>
          )}
        </Formik>
      </div>
    </article>
  );
};

export default FieldEditableList;
