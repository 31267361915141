import React from 'react';
import { useDispatch } from 'react-redux';

import { Container } from '@uncinc/uncinc-react-kitchen-sink';

import {
  clearAccessToken,
  setAccessTokenExpired,
  userPasswordlessLogin,
} from '../../../actions/user';

import { FormLogin } from '../../../components';

import './style.scss';

const AccountLogin = () => {
  const dispatch = useDispatch();

  const handleSubmit = ({ email }) => {
    clearAccessToken();
    dispatch(setAccessTokenExpired());
    dispatch(userPasswordlessLogin(email));
  };

  return (
    <div className="Login">
      <Container>
        <div className="Login__content">
          <h1 className="Login__title">Login</h1>
          <FormLogin onSubmit={handleSubmit} />
        </div>
      </Container>
    </div>
  );
};

export default AccountLogin;
