import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { List } from 'immutable';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import { ErrorMessage, HeaderAdmin } from '../../../components';
import {
  clearErrors,
} from '../../../actions/errors';

import {
  addAccount,
  clearAccount,
  getRoles,
} from '../../../actions/managing';

import './style.scss';
import { ROUTE_ACCOUNT_OVERVIEW } from '../../../config/routes';
import { setGlobalMessage } from '../../../actions/messages';


const AccountOverviewAdd = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history } = props;
  const [submitted, setSubmitted] = useState(false);

  const email = useSelector((state) => state.getIn(['managing', 'account', 'email'], ''));
  const error = useSelector((state) => state.getIn(['errors', 'managing', 'errors', 'errors', 'email', 0]));
  const roles = useSelector((state) => state.getIn(['managing', 'roles'], List()));

  const roleRef = useRef(null);

  const goBack = useCallback(() => {
    history.push(ROUTE_ACCOUNT_OVERVIEW);
  }, [history]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup
        .string()
        .required('Naam is verplicht.'),
      email: Yup
        .string()
        .email('Invalid email')
        .required('Email is verplicht'),
      password: Yup
        .string()
        .min(8, 'Wachtwoord moet langer zijn dan 8 karakters.')
        .required('Wachtwoord is verplicht'),
    }),

    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      const id = parseInt(roleRef.current.value);
      if (id !== 'norole') values.role_id = id;
      setSubmitted(true);
      dispatch(clearErrors());
      dispatch(clearAccount());
      dispatch(addAccount(values));
    },
  });

  useEffect(() => {
    if (submitted && email === formik.values.email) {
      dispatch(setGlobalMessage('Account aangemaakt.'));
      goBack();
    }
  }, [email, submitted, formik, dispatch, goBack]);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  return (
    <div className="AccountOverviewAdd">
      <Container>
        <div className="AccountOverviewAdd__content">
          <HeaderAdmin
            onBack={goBack}
          />
          <h1 className="AccountOverviewAdd__title">
            {t('admin.account-add.title')}
          </h1>
          {error && (
            <div className="alert alert-danger">
              {error}
            </div>
          )}
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="name">
              <div className="AccountOverviewAdd__label">
                {t('admin.account-add.full_name')}
              </div>
              <input
                id="name"
                name="name"
                type="text"
                placeholder={t('form.name.placeholder')}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </label>
            {formik.errors.name ? (
              <ErrorMessage message={formik.errors.name} />
            ) : null}

            <label htmlFor="email">
              <div className="AccountOverviewAdd__label">
                {t('admin.account-add.email')}
              </div>
              <input
                id="email"
                name="email"
                type="email"
                placeholder={t('form.email.placeholder')}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </label>
            {formik.errors.email ? (
              <ErrorMessage message={formik.errors.email} />
            ) : null}

            <label htmlFor="password">
              <div className="AccountOverviewAdd__label">
                {t('admin.account-add.password')}
              </div>
              <input
                id="password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
            </label>
            {formik.errors.password ? (
              <ErrorMessage message={formik.errors.password} />
            ) : null}

            <div className="AccountOverviewAdd__label">
              {t('admin.account-add.role')}
            </div>
            <select name="roles" id="roles" className="AccountOverviewAdd__select" ref={roleRef}>
              <option key="norole" value="norole">
                {t('admin.account-add.no_role')}
              </option>
              {roles.map((role) => {
                return (
                  <option key={role.get('name')} value={role.get('id')}>
                    {role.get('name')}
                  </option>
                );
              })};
            </select>

            <Button type="submit" className="Button Button--primary Button--block">
              {t('admin.account-add.submit')}
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default AccountOverviewAdd;
