import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'immutable';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  ROUTE_ACCOUNT_EDIT,
  ROUTE_ACCOUNT_LOGIN,
} from '../../config/routes';

import {
  setAccessTokenExpired,
  clearAccessToken,
} from '../../actions/user';

import './style.scss';

const UserMenu = ({ isParticipantMenu, isManagementMenu, className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);

  const token = useSelector((state) => state.getIn(['user', 'token'], false));
  const isLoggedin = token !== false;
  const userRoles = useSelector((state) => state.getIn(['user', 'roles'], List()));
  const isParticipant = userRoles.size === 0;

  const shouldShow = isManagementMenu || (isParticipant && isParticipantMenu);

  const logout = () => {
    clearAccessToken();
    dispatch(setAccessTokenExpired());
  };

  let linkClassName = 'UserMenu__link';
  let iconClassName = 'icon icon-account ';
  if (isManagementMenu) {
    linkClassName += ' UserMenu__link__managing';
    iconClassName += ' icon-variant-1';
  }

  if (shouldShow) {
    if (isLoggedin) {
      return (
        <div className={className}>
          <div onClick={() => setDropdown(!dropdown)} className={iconClassName} />
          {dropdown && <div className="UserMenu__overlay" onClick={() => setDropdown(!dropdown)} />}
          {dropdown && (
            <div className="UserMenu__dropdown">

              <div className="icon icon-cross" alt="Cross" onClick={() => setDropdown(!dropdown)} />
              <Link to={ROUTE_ACCOUNT_EDIT}>
                <div className={linkClassName}>
                  {t('navigation.settings')}
                </div>
              </Link>
              <div className={linkClassName} onClick={logout}>
                {t('navigation.logout')}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className={className}>
          <div onClick={() => setDropdown(!dropdown)} className={iconClassName} />
          {dropdown && <div className="UserMenu__overlay" onClick={() => setDropdown(!dropdown)} />}
          {dropdown && (
            <div className="UserMenu__dropdown">
              <div className="icon icon-cross" alt="Cross" onClick={() => setDropdown(!dropdown)} />
              <Link to={ROUTE_ACCOUNT_LOGIN}>
                <div className={linkClassName}>
                  {t('navigation.login')}
                </div>
              </Link>
            </div>
          )}
        </div>
      );
    }
  }

  return null;
};
export default UserMenu;
