import React from 'react';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Button } from '@uncinc/uncinc-react-kitchen-sink';

import { userPasswordlessLogin } from '../../../actions/user';
import Alert from '../../Alert';
import FieldEmail from '../../Field/Email';

const FormLogin = ({ accessCode, onSubmit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const passwordLessLinkSent = useSelector((state) => state.getIn(['user', 'passwordless_link_sent'], false));
  const slug = useSelector((state) => state.getIn(['project', 'slug'], ''));

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form.login.email.errors.invalid'))
      .required(t('form.login.email.errors.required')),
  });

  const handleSubmit = ({ email }) => {
    dispatch(userPasswordlessLogin(email, slug, accessCode || null));

    if (onSubmit) {
      onSubmit(email);
    }
  };

  return (
    <div>
      <h4>{t('form.login.email.title')}</h4>
      <p>{t('form.login.email.description')}</p>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, submitForm }) => (
          <>
            {passwordLessLinkSent && values.email ? (
              <Alert
                type="success"
                title={t('alert.email_sent_success.title')}
                body={(
                  <Trans i18nKey="alert.email_sent_success.body" values={{ email: values.email }}>
                    <strong />
                  </Trans>
                )}
              />
            ) : (
              <>
                <FieldEmail
                  name="email"
                  label={t('form.login.email.label')}
                  placeholder={t('form.login.email.placeholder')}
                />
                <Button
                  className="Button Button--primary Button--block"
                  htmlType="submit"
                  disabled={values.email === 0 || errors.email}
                  onClick={() => submitForm()}
                >
                  {t('button.login')}
                </Button>
              </>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default FormLogin;
