import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Immutable from 'immutable';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import { DEFAULT_LANGUAGE, INDEX_NOT_FOUND, SITE_NAME, USER_ROLES } from '../config/constants';

export function getLanguageByPath(path, fallback = DEFAULT_LANGUAGE) {
  return /^\/[a-z]{2}(\/.*)?$/g.test(path)
    ? path.replace(/^\/([a-z]{2})(\/.*)?/, '$1')
    : fallback;
}

export function getDocumentTitle(title) {
  if (!title) {
    return SITE_NAME;
  }

  return `${title} | ${SITE_NAME}`;
}

/*
* Builds a url with the given parameters
*/
export function buildUrl(route, paramsArray) {
  const segments = route.split('/');
  let currentParamIndex = 0;
  let result = route;
  // Loop over the segments
  for (let index = 0; index < segments.length; index++) {
    const segment = segments[index];
    // Check if segment is variable and a value was given to us
    if (segment.charAt(0) === ':' && String(paramsArray[currentParamIndex])) {
      result = result.replace(segment, paramsArray[currentParamIndex]);
      currentParamIndex = currentParamIndex + 1;
    }
  }
  return result;
}

/**
 * Returns the highest role the current user has.
 *
 * @returns {string} Current users highest role.
 */
export const useUserRole = () => {
  const userRoles = useSelector((state) => state.getIn(['user', 'roles'], Immutable.List()));

  return useMemo(() => (
    [USER_ROLES.ADMIN, USER_ROLES.PROJECT_MANAGER, USER_ROLES.FACILITATOR].find((role) => (
      userRoles.findIndex((i) => i.get('name') === role) !== -1
    ))
  ), [userRoles]);
};

export const formatDate = (date, formatStr = 'PP') => {
  return format(date, formatStr, {
    locale: nl,
  });
};

/**
 * Returns a CSV export data string.
 *
 * @param {List} data - The data to export as CSV.
 * @returns {string} CSV string data.
 */
export const createExportCSV = (data) => {

  // Since the survey field names are dynamic the BE added a prefix before them so we can recognize them.
  // Here we collect all the survey field names based on the prefix.
  let allSurveyAnswerKeys = [];
  data.map((entry) => {
    const [...keys] = entry.keys();
    const surveyAnswerKeys = keys.filter((key) => key.startsWith('SQ:'));
    if (surveyAnswerKeys.length > allSurveyAnswerKeys.length) {
      allSurveyAnswerKeys = surveyAnswerKeys;
    }
  });
  // Define the base fields that are present for the export.
  let result = 'id,userId,stimulusId,clusterId,parentId,label,answer,createdAt,updatedAt,question,roundTitle,projectName';
  // Add the dynamic survey field names to the export and remove the prefix from them that was added by the back-end.
  for (let index = 0; index < allSurveyAnswerKeys.length; index++) {
    const surveyAnswerKey = allSurveyAnswerKeys[index];
    //
    result += ',' + surveyAnswerKey.replace('SQ:', '');
  }
  result += '\r\n';

  // Add entries to the export csv string.
  data.map((entry) => {
    const data = [
      entry.get('id'),
      entry.get('user_id', null),
      entry.get('stimulus_id', null),
      entry.get('cluster_id', null),
      entry.get('parent_id', null),
      entry.getIn(['cluster', 'label', 'title'], null),
      entry.get('answer', null),
      formatDate((new Date(entry.get('created_at'))), 'yyyy-MM-dd HH:mm'),
      formatDate((new Date(entry.get('updated_at'))), 'yyyy-MM-dd HH:mm'),
      entry.getIn(['stimulus', 'question'], null),
      entry.getIn(['stimulus', 'round', 'title'], null),
      entry.getIn(['stimulus', 'round', 'project', 'name'], null),
    ];
    // Add the survey fields data.
    for (let index = 0; index < allSurveyAnswerKeys.length; index++) {
      const surveyAnswerKey = allSurveyAnswerKeys[index];
      data.push(entry.get(surveyAnswerKey, null));
    }

    result += `"${data.join('","')}"\r\n`;
  });

  return result;
};


/**
 * Checks if the user has any of the specified roles indicating that they are a managing user.
 *
 * @param {Immutable.List} userRoles - The list of user roles to check.
 * @returns {boolean} - Returns true if the user has any of the managing roles (facilitator, project-manager, or admin), otherwise returns false.
 */
export const checkIfManagingUser = (userRoles) => {
  const isFacilitator = userRoles.findIndex((i) => i.get('name') === USER_ROLES.FACILITATOR) !== INDEX_NOT_FOUND;
  const isManagedUser = userRoles.findIndex((i) => i.get('name') === USER_ROLES.PROJECT_MANAGER) !== INDEX_NOT_FOUND;
  const isAdmin = userRoles.findIndex((i) => i.get('name') === USER_ROLES.ADMIN) !== INDEX_NOT_FOUND;

  return isFacilitator || isManagedUser || isAdmin;
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
