import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Immutable from 'immutable';
import { confirm } from 'react-confirm-box';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import {
  Container,
  Button,
} from '@uncinc/uncinc-react-kitchen-sink';

import { showModal as showModalAction } from '../../../actions/UI';
import { INDEX_NOT_FOUND, TEXT_INPUT_MAX_LENGTH, USER_ROLES } from '../../../config/constants';
import { buildUrl } from '../../../helpers';
import { ROUTE_ADMIN_PROJECT_DETAIL, ROUTE_PROJECT_OVERVIEW_QUESTION_EDIT } from '../../../config/routes';
import FormAddQuestion from './FormAddQuestion';

import {
  Confirm,
  ErrorMessage,
  ManagingTable,
  Modal,
  FieldFileUpload,
  MarkdownEditor,
  HeaderAdmin,
} from '../../../components';

import {
  getRounds,
  updateRound,
  deleteRound,
  uploadImage,
  roundImageDeleted,
  deleteQuestion,
  roundIndexActive,
  deleteImage,
} from '../../../actions/managing';

import './style.scss';

const ProjectOverviewRoundEdit = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { match: { params: { projectSlug, projectRound } }, history } = props;

  const userRoles = useSelector((state) => state.getIn(['user', 'roles'], Immutable.List()));
  const isManagedUser = userRoles.findIndex((i) => i.get('name') === 'project-manager') !== INDEX_NOT_FOUND;
  const isAdmin = userRoles.findIndex((i) => i.get('name') === USER_ROLES.ADMIN) !== INDEX_NOT_FOUND;

  const showModal = useSelector((state) => state.getIn(['ui', 'modal', 'visible'], false));
  const rounds = useSelector((state) => state.getIn(['managing', 'project', 'rounds'], Immutable.List()));
  const roundIndex = rounds.findIndex ? rounds.findIndex((obj) => { return obj.get('id') === parseInt(projectRound); }) : 0;
  const title = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'title']), '');
  const description = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'description']), '');
  const mediaSubtitle = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'media_subtitle']), '');
  const status = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'status']), '');
  const questions = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'stimuli'], Immutable.List()));
  const image = useSelector((state) => state.getIn(['managing', 'project', 'rounds', roundIndex, 'media', 0], Immutable.List()));

  const redirectToProjectDetail = () => {
    history.push(buildUrl(
      ROUTE_ADMIN_PROJECT_DETAIL,
      [projectSlug],
    ));
  };

  const redirectToEdit = (row) => {
    history.push(buildUrl(
      ROUTE_PROJECT_OVERVIEW_QUESTION_EDIT,
      [projectSlug, projectRound, row.id],
    ));
  };

  const onDeleteRound = async () => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            description={t('project.overview.round-edit.confirm_delete_round')}
            confirm={t('button.yes')}
          />
        );
      },
    };
    const answer = await confirm('', options);

    if (answer === true) {
      dispatch(deleteRound(projectSlug, projectRound));
      redirectToProjectDetail();
    }
  };

  const onDeleteQuestion = async (row) => {
    const options = {
      render: (message, onConfirm, onCancel) => {
        return (
          <Confirm
            onConfirm={onConfirm}
            onCancel={onCancel}
            description={t('project.overview.round-edit.confirm')}
            confirm={t('button.yes')}
          />
        );
      },
    };
    const answer = await confirm('', options);
    if (answer === true) {
      dispatch(roundIndexActive(roundIndex));
      dispatch(deleteQuestion(projectSlug, projectRound, row.id));
    }
  };

  const imageDeleteSuccessCallback = () => {
    dispatch(roundImageDeleted(roundIndex));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: title,
      description: description,
      status: status,
      image_data: '',
      image_name: image.get('file_name', ''),
      media_subtitle: mediaSubtitle,
    },
    validationSchema: Yup.object().shape({
      title: Yup
        .string()
        .required(t('admin.project-overview.round_edit.title_required')),
    }),

    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      const projectValuesFiltered = Object
        .entries(values)
        .filter(([key, value]) => !key.startsWith('image'));

      const projectValues = Object.fromEntries(projectValuesFiltered);

      const uploadImageCallback = ({ id }) => {
        if (values.image_data) {
          dispatch(uploadImage({
            image: values.image_data,
            image_name: values.image_name,
            model: 'Round',
            id,
          }));
        }
      };

      dispatch(updateRound(projectValues, projectSlug, projectRound, uploadImageCallback));
    },
  });

  useEffect(() => {
    dispatch(getRounds(projectSlug));
  }, [dispatch, projectSlug]);

  const rows = [];

  questions.map((question) => {
    rows.push({ id: question.getIn(['id']), question: question.getIn(['question']), description: question.getIn(['description']) });
  });

  const headings = [
    { key: 'question', value: t('admin.project-overview.round_edit.question') },
  ];

  return (
    <div className="ProjectOverviewRoundEdit">
      {showModal && (
        <div>
          <Modal
            popup
            title={t('admin.project-overview.round_edit.modal_title')}
            content={FormAddQuestion}
            className="type-modal"
            contentData={{ projectSlug: projectSlug, projectRound: projectRound }}
          />
        </div>
      )}
      <Container>
        <div className="ProjectOverviewRoundEdit__content">
          <HeaderAdmin
            onBack={redirectToProjectDetail}
          />
          <h1 className="ProjectOverviewRoundEdit__title">
            {t('admin.project-overview.round_edit.title')}
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="title">
              <div className="ProjectOverviewRoundEdit__label">
                {t('admin.project-overview.round_edit.round_title')}
              </div>
              <input
                id="title"
                name="title"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.title}
                maxLength={TEXT_INPUT_MAX_LENGTH}
              />
            </label>
            {formik.errors.title ? (
              <ErrorMessage message={formik.errors.title} />
            ) : null}
            <FieldFileUpload
              name="image-upload"
              label={t('screens.project-overview.form.image_upload')}
              types=".jpg,.jpeg,.png,capture=camera"
              fileName={formik.values.image_name}
              preview={<img src={image.get('original_url', '')} alt={image.get('file_name', '')} />}
              onSuccess={(fileName, fileData) => {
                formik.setFieldValue('image_data', fileData);
                formik.setFieldValue('image_name', fileName);
              }}
              onDelete={() => {
                formik.setFieldValue('image_data', '');
                formik.setFieldValue('image_name', '');

                // Only delete the image from the backend when we already have one in the state.
                if (image.size > 0) {
                  dispatch(deleteImage(image.get('id', null), imageDeleteSuccessCallback()));
                }
              }}
            />

            {image.get('file_name', null) && (
              <>
                <label htmlFor="media_subtitle">
                  <div className="ProjectOverviewRoundEdit__label">
                    {t('admin.project-overview.round_edit.media_subtitle')}
                  </div>
                  <input
                    id="media_subtitle"
                    name="media_subtitle"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.media_subtitle}
                    maxLength={TEXT_INPUT_MAX_LENGTH}
                  />
                </label>
                {formik.errors.title ? (
                  <ErrorMessage message={formik.errors.title} />
                ) : null}
              </>
            )}

            <label htmlFor="description">
              <div className="ProjectOverviewRoundEdit__label">{t('admin.project-overview.round_edit.round_description')}</div>
              <MarkdownEditor
                value={formik.values.description}
                id="description_markdown"
                name="description"
                onChange={(data) => formik.setFieldValue('description', data)}
              />
            </label>

            <label htmlFor="status">
              <div className="ProjectOverviewEdit__label">
                {t('admin.project-overview.round_edit.round_status')}
              </div>
              <select
                id="status"
                name="status"
                onChange={formik.handleChange}
                value={formik.values.status}
              >
                <option value={1} label="Actief">
                  {t('admin.project-overview.round_edit.round_status_active')}
                </option>
                <option value={2} label="Afgerond">
                  {t('admin.project-overview.round_edit.round_status_finished')}
                </option>
              </select>
            </label>

            <Button type="submit" className="Button Button--primary ProjectOverviewRoundEdit__Button">
              {t('admin.project-overview.round_edit.save')}
            </Button>
          </form>
          <div className="ProjectOverviewRoundEdit__addquestion">
            <Button onClick={() => dispatch(showModalAction())} className="Button Button--small">
              {t('admin.project-overview.round_edit.add_question')}
              <div className="icon icon-plus icon-variant-1" />
            </Button>
          </div>
          <h4 className="ProjectOverviewRoundEdit__stimuli">
            {t('admin.project-overview.round_edit.stimuli')}
          </h4>
          <ManagingTable
            headings={headings}
            rows={rows}
            onShow={(row) => {
              redirectToEdit(row);
            }}
            onEdit={(row) => {
              redirectToEdit(row);
            }}
            onDelete={isManagedUser || isAdmin ? (row) => {
              onDeleteQuestion(row);
            } : ''}
          />
          <Button type="button" onClick={() => onDeleteRound()} className="Button Button--inverted ProjectOverviewRoundEdit__Button">
            {t('admin.project-overview.round_edit.delete_round')}
            <div className="ProjectOverviewRoundEdit__deleteround icon icon-trash icon-variant-0" />
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ProjectOverviewRoundEdit;
