export const API_REQUEST = 'API_REQUEST';

export const apiRequest = (method, path, data = null, successHandler = null, errorHandler = null, bearerToken = null, successCallback = null) => {
  return {
    type: API_REQUEST,
    path: path,
    method: method,
    data: data,
    bearerToken: bearerToken,
    successHandler: successHandler,
    errorHandler: errorHandler,
    successCallback: successCallback,
  };
};
