import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'immutable';

import { getAnswers } from '../../../../../../actions/project';
import { ANSWER_STATUS } from '../../../../../../config/constants';

import ProjectFlowRoundTypeQuestionV3StackingPerform from './Perform';
import ProjectFlowRoundTypeQuestionV3StackingVerify from './Verify';


import './style.scss';

const ProjectFlowRoundTypeQuestionV3Stacking = ({ isPreview, stackingComplete }) => {
  const dispatch = useDispatch();

  // Global state
  const projectSlug = useSelector((state) => state.getIn(['project', 'slug'], null));
  const ownAnswers = useSelector((state) => state.getIn(['project', 'currentRound', 'stimuli', 0, 'answers', 'user_answers'], List()));
  const currentRoundId = useSelector((state) => state.getIn(['project', 'currentRound', 'id'], null));
  const stimulusId = useSelector((state) => state.getIn(['project', 'currentRound', 'stimuli', 0, 'id'], null));

  // Local state
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0);

  /**
   * Go through all your own answers and find first one that's not completed yet
   */
  const determineAndSetCurrentAnswer = () => {
    if (ownAnswers.size > 0) {
      const ownAnswerArray = ownAnswers.toArray();

      let foundUncompletedAnswer = false;
      for (let i = 0; i < ownAnswerArray.length; i++) {
        const answer = ownAnswerArray[i];
        const answerStatus = answer.get('status');

        // If verified, the question is done and can be ignored
        if (answerStatus === ANSWER_STATUS.VERIFIED) {
          continue;
        }

        // If it is either new or stacked, it means it still has work to be done and becomes the current answer
        if (answerStatus === ANSWER_STATUS.NEW || answerStatus === ANSWER_STATUS.STACKED) {
          setCurrentAnswer(answer);
          setCurrentAnswerIndex(i);
          foundUncompletedAnswer = true;
          break;
        }
      }

      if (!foundUncompletedAnswer) {
        // Set round completed and move on
        stackingComplete();
      }
    }
  };

  /**
   * Fetch the answers from the API
   */
  const fetchAnswers = () => {
    if (projectSlug && currentRoundId && stimulusId) {
      dispatch(getAnswers(projectSlug, currentRoundId, stimulusId));
    }
  };

  /**
   * When the stacking process has been successfully finished, fetch the updated answers
   */
  const onStackingSuccess = () => {
    fetchAnswers();
  };

  // Get the answers
  useEffect(() => {
    // Only fetch answers after our project is loaded
    fetchAnswers();
  }, [dispatch, projectSlug, currentRoundId, stimulusId]);

  // Determine current answer
  useEffect(() => {
    determineAndSetCurrentAnswer();
  }, [ownAnswers]);

  return (
    <div className="ProjectFlowRoundStacking">
      {currentAnswer && currentAnswer.get('status') === ANSWER_STATUS.NEW && (
        <ProjectFlowRoundTypeQuestionV3StackingPerform
          ownAnswerCount={ownAnswers.size}
          currentAnswerIndex={currentAnswerIndex}
          currentAnswer={currentAnswer}
          stackingSuccessCallback={onStackingSuccess} />
      )}

      {currentAnswer && currentAnswer.get('status') === ANSWER_STATUS.STACKED && (
        <ProjectFlowRoundTypeQuestionV3StackingVerify
          answerId={currentAnswer.get('id')}
          stimulusId={stimulusId}
          cluster={currentAnswer.get('cluster')}
          requestRefresh={fetchAnswers} />
      )}
    </div>
  );
};

export default ProjectFlowRoundTypeQuestionV3Stacking;
