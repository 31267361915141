import React from 'react';

import './style.scss';

const ProgressBar = ({ progress = 0 }) => (
  <div className="ProgressBar">
    <div className="ProgressBar__bar" style={{ width: `${progress}%` }} />
  </div>
);

export default ProgressBar;
