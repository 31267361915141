import React from 'react';
import { Formik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { Button } from '@uncinc/uncinc-react-kitchen-sink';

import { userAnonymousLogin } from '../../../actions/user';
import { RECAPTCHA_SITE_KEY } from '../../../config/constants';

const FormAnonymousLogin = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    recaptcha: Yup.string()
      .required(t('form.login.recaptcha.errors.required')),
  });

  const handleSubmit = ({ recaptcha }) => {
    if (recaptcha) {
      dispatch(userAnonymousLogin());
    }

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <div>
      <h4>{t('form.login.recaptcha.title')}</h4>
      <p>{t('form.login.recaptcha.description')}</p>
      <Formik
        initialValues={{ recaptcha: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, setFieldValue, submitForm }) => (
          <>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={(value) => {
                setFieldValue('recaptcha', value);
              }}
            />
            <Button
              className="Button Button--primary Button--block"
              htmlType="submit"
              disabled={!values.recaptcha || errors.recaptcha}
              onClick={() => submitForm()}
            >
              {t('button.login')}
            </Button>
          </>
        )}
      </Formik>
    </div>
  );
};

export default FormAnonymousLogin;
