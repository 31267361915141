import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@uncinc/uncinc-react-kitchen-sink';

import { checkAccessCode, getProjectByCode, cleanProjectAccessValidationStatus } from '../../../actions/project';
import FieldAccessCode from '../../Field/AccessCode';

import {
  MAX_ACCESS_CODE_LENGTH,
  PROJECT_ACCESS_CODE_STATUS_ERROR,
  PROJECT_ACCESS_CODE_STATUS_SUCCESS,
} from '../../../config/constants';

const FormAccessCode = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isSignedIn = useSelector((state) => state.getIn(['user', 'token'], false));

  const slug = useSelector((state) => state.getIn(['project', 'slug'], ''));
  const accessCodeValidationStatus = useSelector((state) => state.getIn(['project', 'access_code_validation_status'], ''));
  const errorState = accessCodeValidationStatus === PROJECT_ACCESS_CODE_STATUS_ERROR;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (errorState) {
      setError(true);
    } else {
      setError(false);
    }
  }, [accessCodeValidationStatus]);

  const handleSubmit = ({ access_code }) => {
    if (onSubmit) {
      onSubmit(access_code);
    }

    // Get the project using the access code if user is already signed in.
    if (isSignedIn) {
      dispatch(getProjectByCode(access_code));
      return;
    }

    // Check access code if user is not signed in yet and code is not yet successful.
    if (accessCodeValidationStatus !== PROJECT_ACCESS_CODE_STATUS_SUCCESS) {
      dispatch(checkAccessCode(slug, access_code));
    }

    setError(accessCodeValidationStatus === PROJECT_ACCESS_CODE_STATUS_ERROR);
  };

  return (
    <div>
      <h4>{t('form.login.access_code.title')}</h4>
      <p>{t('form.login.access_code.description')}</p>
      <Formik
        initialValues={{ access_code: '' }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, submitForm }) => (
          <>
            <FieldAccessCode
              name="access_code"
              label={t('form.login.access_code.label')}
              errorLabel={t('form.login.access_code.errors.invalid')}
              error={error}
              onChange={(code) => {
                setFieldValue('access_code', code);
                setError(false);

                // #TODO refactor into a yup validation
                if (code.length === MAX_ACCESS_CODE_LENGTH - 1) {
                  dispatch(cleanProjectAccessValidationStatus());
                }
                if (code.length === MAX_ACCESS_CODE_LENGTH) {
                  submitForm();
                }
              }}
            />
            <Button
              className="Button Button--primary Button--block"
              htmlType="submit"
              disabled={values.access_code.length < MAX_ACCESS_CODE_LENGTH || error}
              onClick={() => submitForm()}
            >
              {t('button.login')}
            </Button>
          </>
        )}
      </Formik>
    </div>
  );
};

export default FormAccessCode;
